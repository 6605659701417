import { Component, OnInit } from '@angular/core';
import { EnvironmentService } from './services/environment/environment.service';
import { setTheme } from 'ngx-bootstrap/utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: []
})
export class AppComponent implements OnInit {

  isCarregamentoCompleto: boolean = false;

  constructor(private environmentService: EnvironmentService) {
    this.environmentService.carregarEnvironments().then(() => {
      this.isCarregamentoCompleto = true;
    });
    setTheme('bs5');
  }


  public ngOnInit(): void {}
}
