<div style="display: inline">
  <button type="button" class="btn btn-link colorDiv" [disabled]="firtPage()" (click)="paginar(1, $event)">
    <i class="bi bi-skip-backward-fill"></i> 
  </button>
</div>

<div style="display: inline">
  <button type="button" class="btn btn-link colorDiv" [disabled]="firtPage()" (click)="paginar(pagina-1, $event)">
    <i class="bi bi-chevron-left"></i> 
  </button>
</div>

<div style="display: inline" *ngFor="let i of paginas" [ngClass]="{'active': i===pagina}">
  <button [ngClass]="{'super': i===pagina}" type="button" class="btn btn-link colorDiv" (click)="paginar(i, $event)">
    <span>{{ i }}</span>
  </button>
</div>

<div style="display: inline">
  <button type="button" class="btn btn-link colorDiv" [disabled]="lastPage()" (click)="paginar(pagina+1, $event)">
    <i class="bi bi-chevron-right"></i> 
  </button>
</div>

<div style="display: inline">
  <button type="button" class="btn btn-link colorDiv" [disabled]="lastPage()" (click)="paginar(qtdPaginas, $event)">
    <i class="bi bi-skip-forward-fill"></i> 
  </button>
</div>

<div class="colorDiv">
  <span>{{ rangeRegistrosInicial() }} - {{ rangeRegistrosFinal() }} de {{ totalRegistros }} Registros</span>
</div>
