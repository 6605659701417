import { throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AlertService } from '../../componentes/alert/alert.service';
import { ModalService } from '../../componentes/modal/modal.service';
import { AbstractService } from '../abstract.service';
import { AuthService } from '../auth-service';
import { ErrorVO } from '../../models/vo/error-vo';
import { mensagensPadrao } from './../../util/mensagens-padrao';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

const ERROR_MESSAGE = 'Ocorreu um erro! Por favor, entre em contato com o administrador sistema.';
@Injectable()
export class ErroService extends AbstractService {
  constructor(
    public authService: AuthService,
    public router: Router,
    public alertService: AlertService,
    public modalService: ModalService,
    private snackBar: MatSnackBar,
  ) {
    super();
  }

  exibirMensagemErro(
    mensagem: string,
    horizontalPosition: MatSnackBarHorizontalPosition = 'right',
    verticalPosition: MatSnackBarVerticalPosition = 'bottom'
  ): void {
    this.snackBar.open(mensagem, 'Fechar', {
      duration: 10000,
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      panelClass: ['mat-toolbar', 'mat-warn', 'mat-snackbar-z-index']
    });
  }

  public handleError = (response: Response) => {

    const error = this.getError(response);
    console.log(error.httpStatus);
    console.log(error.description);
    console.log(error.message);
    if (error.httpStatus == 400) {
      console.log(error.message);
      if (error.message === "O e-mail secundário deve ser diferente do e-mail principal.") {
        this.alertService.warn(error.message);
      }
    }

    if (error.httpStatus == 400) {
      if (error.message === "O valor digitado no campo Nova senha já foi utilizado por este usuário. Favor escolher outra senha.") {
        this.alertService.warn(mensagensPadrao.MSG32);
      }
    }
    if (error.httpStatus == 0 || error.httpStatus == 404 || error.httpStatus == 503) {//Not Found
      this.authService.doLogout();
      this.direcionarServicoIndisponivel();
    } else if (error.httpStatus == 401 || error.httpStatus == 403) {//Unauthorized ou Forbidden
      if (error.description === "CPF informado n&atilde;o &eacute; v&aacute;lido. Favor verificar.") {
        this.alertService.error(mensagensPadrao.MSG04);
      } else if (error.description === "N&atilde;o existe usu&aacute;rio cadastrado para o CPF informado. Favor verificar.") {
        this.alertService.error(mensagensPadrao.MSG27);
      } else if (error.description === "N&atilde;o ser&aacute; poss&iacute;vel efetuar o logon porque a sua conta ainda n&atilde;o foi ativada. Favor ativar a sua conta atrav&eacute;s do e-mail recebido.") {
        this.alertService.error(mensagensPadrao.MSG28);
      } else if (error.description === "Usu&aacute;rio Inativo. Entre em contato com o administrador do sistema.") {
        this.alertService.error(mensagensPadrao.MSG29);
      } else if (error.description === "O usu&aacute;rio utilizado est&aacute; bloqueado. Favor utilizar a op&ccedil;&atilde;o &#39;Esqueceu a senha?&#39; para solicitar nova senha.") {
        this.alertService.error(mensagensPadrao.MSG30);
      } else if (error.description === "Usu&aacute;rio fora do per&iacute;odo tempor&aacute;rio.") {
        this.alertService.error(mensagensPadrao.MSG31);
      } else if (response.statusText==="Unauthorized" && error.description.includes("Access token expired") ){
        this.authService.doLogout();
        this.direcionarTimeoutSessao();
      } else if (error.description === "Usu&aacute;rio fora do per&iacute;odo tempor&aacute;rio.") {
        this.alertService.error(mensagensPadrao.MSG31);
      } else {
        this.alertService.error(error.description);
      }
      //this.authService.doLogout();
      //this.direcionarAcessoInvalido();
    } else if (error.httpStatus == 408) {//Request Timeout
      this.authService.doLogout();
      this.direcionarTimeoutSessao();
    } else if (error.httpStatus == 500) {//Internal Server Error
      this.direcionarErrroSistema();
    } else {
      this.tratarErroMessage(error);
    }

    return observableThrowError(error);
  }

  private tratarErroMessage(error: ErrorVO) {
    let modalsCount: number = this.modalService.bsModalService.getModalsCount();
    if (modalsCount > 0) {
      switch (error.alertType) {
        case 'WARNING':
          this.alertService.warnModal(error.error);
          break;
        default:
          this.alertService.errorModal(error.error);
          break;
      }
    } else {
      switch (error.alertType) {
        case 'WARNING':
            this.alertService.warn(error.error);
          break;
        /*default:
          this.alertService.error(error.error);
          break;*/
      }

      switch (error.description) {
        case 'Bad credentials':
            this.alertService.error(mensagensPadrao['MSG26']);
          break;
        default:
          this.alertService.error(error.error);
          break;
      }

    }
  }

  private getError(response: Response) {
    const responseError = response['error'];

    if (!responseError)
      return this.getErroByResponse(response, responseError);

    let error: ErrorVO;

    try {
      error = JSON.parse(responseError);
      error.description = responseError['error_description'];
      if (!this.isValidError(error))
        error = this.getErroWithMessage(response, error.message);
    } catch(e) {
      error = responseError;
      if (!this.isValidError(error))
        error = this.getErroWithMessage(response, error.message);
    }
    return error;
  }

  private isValidError(error: ErrorVO) {
    return error.httpStatus && error.status && error.error && error.exception && error.alertType ;
  }

  private getErroWithMessage(response: Response, message: string) {
    const responseError = response['error'];
    const error = this.getErroByResponse(response, responseError);
    error.message = message;
    return error;
  }

  private getErroByResponse(response: Response, responseError: any) {
    const error = <ErrorVO>{};
    error.httpStatus = response.status;
    error.alertType = 'DANGER';
    error.error = ERROR_MESSAGE;
    error.description = responseError['error_description'];
    return error;
  }

  private direcionarTelaLogin(): void {
    this.router.navigate(['/authorized']);
  }

  private direcionarErrroSistema(): void {
    this.router.navigate(['/erro-sistema']);
  }

  private direcionarTimeoutSessao(): void {
    this.router.navigate(['/timeout-usuario']);
  }

  private direcionarServicoIndisponivel(): void {
    this.router.navigate(['/servico-indisponivel']);
  }

  private direcionarAcessoInvalido(): void {
    this.router.navigate(['/acesso-invalido']);
  }
}
