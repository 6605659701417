<alert></alert>

<div class="container" style="margin-top:50px;">
  <div id="loginbox" style="margin-top:50px;" style="width: 40%" class="mainbox col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
    <div class="panel panel-info">

      <div style="margin-left: 10px;" class="panel-body">

        <div style="text-align: center;" class="form-group">
          <h3>Clique para ativar sua conta.</h3>
        </div>

        <form #f="ngForm" (ngSubmit)="validarConta()" id="loginform" class="form-horizontal" role="form">
          <div style="margin-top:30px" class="form-group">
            <div class="col-sm-12 controls">
              <button class="btn btn-primary" type="submit" style="width: 100%">
                Ativar conta
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
