<i class="bi bi-eye-fill" style="font-size: 20px; margin-right: 20px;" (click)="showDialog()"></i>

<ng-container #vc></ng-container>
<ng-template #modal>
  <div class="modal fade" id="id_notificacao" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog modal-lg modal-custom" role="document">
      <div class="modal-content">
        <!-- <button type="button" class="close-btn" (click)="closeDialog()">×</button> -->
        <alert></alert>
        <div class="modal-header">
          <div class="modal-title">
            <span style="color: gray; font-weight: bold;">Visualizar Alerta</span>
          </div>
        </div>

        <div class="notificacao-item">
          <div class="modal-body" style="margin-top: -10px;">
            <div class="modulo-container">
              <p [ngClass]="{
                'modulo-semad': !notificacao.orgao || notificacao.orgao === 'SEMAD',
                'modulo-igam': notificacao.orgao === 'IGAM',
                'modulo-sisema': notificacao.orgao === 'SISEMA',
                'modulo-ief': notificacao.orgao === 'IEF',
                'modulo-feam': notificacao.orgao === 'FEAM'
              }">
              {{ notificacao.sistema }}
            </p>
            </div>

            <div class="modulo-container">
              <p class="modulo-titulo">{{ notificacao.titulo }}</p>
            </div>

            <div class="modulo-container">
              <p class="modulo-mensagem">{{ notificacao.mensagem }}</p>
            </div>

            <div class="modulo-container">
              <p style="margin-top: 20px; font-size: 16px; color: #707070">Alerta gerado em {{ notificacao.created_at | date:'dd/MM/yyyy HH:mm' }}</p>
            </div>
          </div>
        </div>

        <!-- Botões de ação: alinhados à esquerda e direita -->
        <div class="d-flex justify-content-between" style="margin: 20px 18px;">
          <!-- Botão Fechar Alerta (esquerda) -->
          <button class="btn" style="background-color: white; border-radius: 3px; border-color: #DC2121; width: 150px;" (click)="closeDialog()">
            <span style="color: #DC2121; ">
              <i class="bi bi-x-square" style="margin-right: 5px;"></i> Fechar alerta
            </span>
          </button>

          <!-- Botão Ir ao Sistema  (direita) -->
          <button type="button" class="btn" style="background-color: white; border-color: #085DA7; width: 150px;" (click)="abrirSistema()">
            <span style="color: #085DA7; ">Ir ao Sistema</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

