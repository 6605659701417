import { HttpHeaders, HttpParams,HttpRequest} from '@angular/common/http';
// import { RequestOptions } from '@angular/http';

export class AbstractService {
  protected headers: HttpHeaders;
  options: HttpRequest<any>
  ;

  constructor() {
    this.headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset-UTF-8'
    );
    // this.options = new RequestOptions({ headers: this.headers2 });
  }

  protected configOptions(options?: {
    headers?: HttpHeaders;
    observe?: 'body';
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }) {
    // this.options.merge(opts);
  }
}
