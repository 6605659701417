import { Component, Input, ViewChild } from '@angular/core';

import { AbstractTemplate } from './../template/abstract-template';

@Component({
  selector: 'template-simples',
  templateUrl: './template-simples.component.html',
  styleUrls: ['../template/abstract-template.css']
})
export class TemplateSimplesComponent extends AbstractTemplate {
  /**
   * Informa se o menu de perfil, deve ser ocultado
   */
  @Input() public escondePerfil: boolean = false;

  ngOnInit() {
    this.renderer.removeClass(document.body, 'sidebar-open');
  }

  clickVoltarHome() {
    this.router.navigate(['/']);
  }
}
