import { SegurancaRoutingModule } from './seguranca-routing.module';
import { HttpAuthInterceptor } from './../services/http-auth-token.interceptor/http-auth-token.interceptor';
import { AuthorizedComponent } from './../componentes/seguranca/authorized/authorized.component';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { environment } from './../../environments/environment';

export function tokenGetter(): string {
  return localStorage.getItem('Authorization')!;
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SegurancaRoutingModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        // allowedDomains: environment.tokenAllowedDomains,
        // disallowedRoutes: environment.tokenDisallowedRoutes
      }
    }),
  ],

  providers: [
    JwtHelperService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptor,
      multi: true
    }
  ],

  declarations: [
    AuthorizedComponent
  ]
})
export class SegurancaModule { }
