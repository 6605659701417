<template-simples [escondePerfil]="true">
    <div *ngIf="enviado != true else elseBlock">

      <div class="alert alert-warning" style="margin: 0em 0em 0.5em 0em;" [innerHTML]="mensagemFaleConosco"></div>

      <form #myForm="ngForm" style="border:1px gainsboro solid;">
        <div class="box-body">
          <h3>Fale conosco</h3>

          <iframe class="iframe"
          [src]="urlFrameFaleConosco"
          frameborder="0" scrolling="yes">
        </iframe>
        </div>
       <!-- <div class="box-body">
          <h3>Fale conosco</h3>
          <hr>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="email">* E-mail (remetente)</label>
              <div class="input-group">
                <span class="input-group-addon">
                  <i class="glyphicon glyphicon-envelope"></i>
                </span>
                <input type="email" class="form-control" id="remetente" name="remetente" placeholder="E-mail (remetente)"
                  [(ngModel)]="faleConosco.remetente" required email #remetente="ngModel" maxlength="75">
              </div>
              <div class="alert-input alert-danger"
                *ngIf="remetente.hasError('remetente') && !remetente.hasError('required')">Formato do e-mail é
                inválido</div>
            </div>
            <div class="form-group col-md-4">
              <label for="complemento">* Módulo:</label>
              <ng-select [items]="listaModulo" #idModulo="ngModel" [(ngModel)]="faleConosco.modulo.idModulo" name="modulo"
                (change)="onChangeModulo(modulo)" bindLabel="descricao" bindValue="idModulo" [clearable]="true"
                placeholder="Módulo">
              </ng-select>
            </div>
          </div>
          <div class="form-group">
            <div class="col-md-6">
              <label for="complemento">* Assunto:</label>
              <ng-select [items]="listaAssuntoFaleConosco" #idAssuntoFaleConosco="ngModel"
                [(ngModel)]="faleConosco.assuntoFaleConosco.idAssuntoFaleConosco" name="assunto" bindLabel="textoAssunto"
                bindValue="idAssuntoFaleConosco" [clearable]="false" placeholder="Assunto" required>
              </ng-select>
            </div>
            <div class="col-md-4" *ngIf="faleConosco.modulo?.idModulo == 23">
              <label for="numeroProtocolo">Número da solicitação:</label>
              <input type="text" class="form-control" id="numeroProtocolo" name="numeroProtocolo"
                placeholder="Número da Solicitação" [(ngModel)]="faleConosco.numeroProtocolo" #numeroProtocolo="ngModel"
                maxlength="75">
            </div>
          </div>
          <div class="form-group">
            <div class="form-group col-md-12" style="padding-top: 1em;">
              <label for="textoMensagem">* Mensagem</label>
              <div class="input-group">
                <span class="input-group-addon">
                  <i class="glyphicon glyphicon-list-alt"></i>
                </span>
                <textarea class="form-control" rows="9" id="textoMensagem" name="textoMensagem" placeholder="Mensagem"
                  [(ngModel)]="faleConosco.textoMensagem" #textoMensagem="ngModel" maxlength="4000" required>
                </textarea>
              </div>
              <div class="alert-input alert-danger"
                *ngIf="textoMensagem.hasError('required') && (textoMensagem.dirty || textoMensagem.touched)">
                {{ formatador.formataMensagem('MSG22', ['Texto Mensagem']) }} </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <h5 class="box-title"><b>Anexar Arquivo</b></h5>
              <div>
                <input id="file" type="file" name="file" ng2FileSelect [uploader]="uploader" multiple (change)="onChange($event);"
                  (onFileSelected)="validarExtensao()"
                  accept=".jpg, .png, .jpeg, .bmp, .pdf, .shp, .dbf, .shx, .prj, .gpkg" style="display: none" />
                <label for="file" style="cursor: pointer; margin-bottom: 1em;" class="btn btn-primary">
                  Escolher arquivos &nbsp;
                  <i class="fa fa-folder-open"></i>
                </label>
              </div>
              <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)"
                [uploader]="uploader" (onFileDrop)="validarExtensao()" class="well my-drop-zone col-md-5">
                Ou arraste aqui...
              </div>
              <div class="col-md-12">
                <table class="table">
                  <tbody>
                    <tr *ngFor="let item of uploader.queue">
                      <td><strong>{{ item?.file?.name }}</strong></td>
                      <td class="text-center">
                        <span *ngIf="item.isError"><i class="glyphicon glyphicon-remove"></i></span>
                      </td>
                      <td nowrap>
                        <button type="button" class="btn btn-danger btn-xs"
                          (click)="item.remove();habilitarQuantidadeDeDocumentoAnexado();removerAlerta();">
                          <span class="glyphicon glyphicon-trash"></span> Remover
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <br>
          <div class="form-group" style="padding-top: 4em;">
            <div class="form-group col-md-12">
            </div>
          </div>
        </div> -->
        <div class="box-footer" style="padding-top: -20px;">
          <div class="form-group col-md-12">
            <!--<button type="submit" class="btn btn-primary" (click)="enviarFaleConosco()"
              [disabled]="!myForm.valid || !captchaSuccess">Enviar</button>
            <button type="button" class="btn btn-secondary" (click)="voltar()" style="margin-left: 10px;">Voltar</button>-->
            <button type="button" class="btn btn-secondary" (click)="voltar()">Voltar</button>
          </div>
        </div>
      </form>
    </div>

    <ng-template #elseBlock>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="error-template">
              <h1>E-mail enviado com sucesso!</h1>

              <h4>Sua mensagem de fale conosco foi enviada com sucesso.</h4>

              <div class="error-actions" style="padding-top: 20px;">
                <a routerLink="/authorized" class="btn btn-primary btn-lg">
                  Clique aqui para efetuar login
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </template-simples>
