import { Injectable } from '@angular/core';

import { AbstractService } from '../abstract.service';
import { RestService } from '../rest-service';
import { TipoRequisicaoRestEnum } from './../../models/tipo-requisicao-rest.enum';
import { Usuario } from './../../models/usuario';

const PATH = '/desconectar';

@Injectable()
export class DesconectarService extends AbstractService {
  constructor(private rest: RestService) {
    super();
  }

  public logoff(usuario: Usuario) {
    this.rest
      .gerarSolicitacao(TipoRequisicaoRestEnum.POST, PATH, null, usuario)
      .subscribe();
  }
}
