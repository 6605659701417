import { Injectable, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as $ from 'jquery';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AlertService } from '../alert/alert.service';

@Injectable()
export class ModalService {
  public bsModalRef: BsModalRef;
  public template: TemplateRef<any>;

  public bsModalRefDialog: BsModalRef;
  public templateDialog: TemplateRef<any>;

  private form: NgForm;
  private config: ModalOptions;

  constructor(public bsModalService: BsModalService, private alertService: AlertService) {
    this.config = new ModalOptions();
    this.config.ignoreBackdropClick = true;
  }

  public setForm(form) {
    this.form = form;
  }

  public openModal() {
    this.alertService.clear();
    this.bsModalRef = this.bsModalService.show(this.template, this.config);

  }

  /**
   * Fecha o modal informando dismissReason como 'sucess'.
   */
  public fecharSucessModal() {
    this.bsModalService.setDismissReason('sucess');
    this.fecharModal();
  }

  public fecharModal() {
    if (this.bsModalRef != undefined) {
      this.bsModalRef.hide();
    }
  }

  /**
   * Recebe tipo e tamanho do modal.
   * Tipo do modal: modal-default, modal-info, modal-warning, modal-success ou modal-danger
   * Tamanho do modal: modal-sm, modal-md ou modal-lg
   */
  public openDialogModal(
    tipo?:
      | 'modal-default'
      | 'modal-info'
      | 'modal-warning'
      | 'modal-success'
      | 'modal-danger',
    tamanho?: 'modal-sm' | 'modal-md' | 'modal-lg'
  ) {
    this.alertService.clear();
    this.config.class = tipo + ' ' + tamanho;
    this.bsModalRefDialog = this.bsModalService.show(
      this.templateDialog,
      this.config
    );
  }

  /**
   * Fecha o modal de diálogo informando dismissReason como 'sucess'.
   */
  public fecharSucessDialogModal() {
    this.bsModalService.setDismissReason('sucess');
    this.fecharDialogModal();
  }

  public fecharDialogModal() {
    if (this.bsModalRefDialog != undefined) {
      this.bsModalRefDialog.hide();
    }
  }

  public resetForm() {
    if (this.form != undefined) {
      this.form.resetForm();
    }
  }

  public scrollToTop() {
    $('html,body').animate(
      {
        scrollTop: $('.wrapper').offset().top
      },
      '1000'
    );
    return false;
  }
}
