<a role="button" (click)="click()" popover="{{ modulo.descricao }}" triggers="mouseenter:mouseleave">
  <div class="info-box">
    <div class="info-box-icon" [ngStyle]="{'min-height': '105px', 'background-color': modulo.colorIcon}" style="min-height: 105px">
      <img  [src]="getIcon()">
    </div>

    <div class="info-box-content" [ngStyle]="{'background-color': getTitleColor()}">
      <div class="info-box-text-title">
        <strong>{{ modulo.sigla }}</strong>
      </div>
    </div>
    <div class="info-box-text">
      <!-- <span style="padding: 10px">Licenciamento Ambiental SimplificadoAmbiental Simplificado44</span> -->
      <span style="color: black">{{ modulo.nome }}</span>
    </div>
    <!-- /.info-box-content -->
  </div>
  <!-- /.info-box -->
</a>