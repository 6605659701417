<div id="strength" #strength style="margin-bottom: 30px;">
  <small>{{barLabel}}</small>
  <ul id="strengthBar">
    <li class="point" [style.background-color]="bar0"></li>
    <li class="point" [style.background-color]="bar1"></li>
    <li class="point" [style.background-color]="bar2"></li>
    <li class="point" [style.background-color]="bar3"></li>
    <li class="point" [style.background-color]="bar4"></li>
  </ul>
</div>
<div class="form-group" style="margin-bottom: -30px;" *ngIf="exibirParametrosSenha">
  <small style="color:gray; font-size: 0.9em;">
    Sua senha deve conter:
    <ul>
      <li *ngIf="tamanhoMinimo > 0">No mínimo {{ tamanhoMinimo }} caracteres</li>
      <li *ngIf="tamanhoMaximo > 0">No máximo {{ tamanhoMaximo }} caracteres</li>
      <li *ngIf="numeros == true">Números</li>
      <li *ngIf="letrasMinusculas == true">Letras minúsculas</li>
      <li *ngIf="letrasMaiusculas == true">Letras maiúsculas</li>
      <li *ngIf="caractersEspeciais == true">Caracteres especiais</li>
    </ul>
  </small>
  <br>
</div>
