import { Injectable } from '@angular/core';


export const mensagensPadrao = {
  MSG01: 'Nenhum registro encontrado.',
  MSG02: 'Favor preencher os campos obrigatórios.',
  MSG03: 'Dados gravados com sucesso.',
  MSG04: 'CPF informado não é válido. Favor verificar.',
  MSG07: 'E-mail não é válido. Favor verificar.',
  MSG08: 'As senhas digitadas não conferem. Favor verificar.',
  MSG09: 'A senha não atende aos requisitos mínimos de segurança exigidos pelo administrador do sistema.',
  MSG11: 'As alterações não confirmadas serão perdidas. Deseja continuar?',
  MSG12: 'Já existe um usuário cadastrado com este CPF. Favor verificar.',
  MSG13: 'Para realizar a pesquisa é necessário preencher ao menos um filtro.',
  MSG21: 'Sua senha expirará em {1} dia(s). Caso não efetuar a troca de senha dentro desse período, seu usuário será bloqueado.',
  MSG22: 'O campo {1} é obrigatório.',
  MSG23: 'Registro excluído com sucesso.',
  MSG24: 'Campo {1} deve conter no mínimo {2} caracteres.',
  MSG25: 'E-mail enviado com sucesso.',
  MSG26: 'Usuário ou senha inválido. Favor verificar.',
  MSG27: 'Não existe usuário cadastrado para o CPF informado. Favor verificar.',
  MSG28: 'Não será possível efetuar o logon porque a sua conta ainda não foi ativada. Favor ativar a sua conta através do e-mail recebido.',
  MSG29: 'Usuário Inativo. Entre em contato com o administrador do sistema.',
  MSG30: 'O usuário utilizado está bloqueado. Favor utilizar a opção "Esqueceu a senha?" para solicitar nova senha.',
  MSG31: 'Usuário fora do período temporário.',
  MSG32: 'A senha não atende aos requisitos mínimos de segurança exigidos pelo administrador do sistema. A senha atual não pode ser igual a uma senha anterior.',
};

/*=========================================================================================
# COLOCAR AQUI AS MENSAGENS DO CDU
# REGRA DE FORMAÇÃO: <NOME>_M<NUMERO_MENSAGEM> com os mesmos valores presentes no CDU
#=========================================================================================*/
export const mensagensCdu = {

/*=========================================================================================
# MENSAGENS PSEG_CDU_01
#=========================================================================================*/
  PSEG_CDU_01_M01:
    'Para se cadastrar, é necessário aceitar o Termo de Responsabilidade!',
  PSEG_CDU_01_M02:
    'Atenção! Ao desmarcar a opção “Usuário Interno”, o usuário perderá todas as permissões de usuário interno e as informações de Lotação. Deseja continuar?',

/*=========================================================================================
# MENSAGENS PSEG_CDU_01
#=========================================================================================*/
  PSEG_CDU_11_M01:
    'Não é possível clonar os perfis do mesmo usuário!',

/*=========================================================================================
# MENSAGEM FALE CONOSCO
#=========================================================================================*/
  PSEG_FALE_CONOSCO:
  'Em caso de dúvidas entrar em contato com LigMinas, 155, opção 7.'
};

/**
 * Realiza a formatação das mensagens de acordo com os parâmetros. O método recebe a mensagem a ser substituida e um array de strings com os parâmetros.
 * As mensagens devem conter a tag {1} {2}... com a numeração sequencial para cada parâmetro que será substituido.
 * Ex.: FormatadorDeMensagens.formataMensagem(mensagensCdu['PSEG_CDU_01_M01'], parametros)
 */
@Injectable()
export class FormatadorDeMensagens {

  public formataMensagem(key: string, parametros: string[]) {
    let mensagem = mensagensPadrao[key];
    for (let i = 0; i < parametros.length; i++) {
      let teste: string = '{' + (i + 1) + '}';
      mensagem = mensagem.replace(teste, parametros[i]);
    }
    return mensagem;
  }
}
