<template-menu>
  <div class="box-body title">
    <h3>
      Vincular Perfil ao Usuário
    </h3>
  </div>

  <section class="content">
    <div class="accordion" id="accordionFiltros">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFiltros">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFiltros"
            aria-expanded="true" aria-controls="collapseFiltros">
            Filtros
          </button>
        </h2>
        <div id="collapseFiltros" class="accordion-collapse collapse show" aria-labelledby="headingModulos"
          data-bs-parent="#accordionModulos">
          <div class="accordion-body">
            <form role="form">
              <div class="box-body">
                <div class="row">
                  <div class="form-group col-md-6">
                    <label for="usuarioNome">Usuário:</label>
                    <input [(ngModel)]="usuario.nome" name="nome" disabled class="form-control" id="usuarioNome">
                  </div>

                  <div class="form-group col-md-6">
                    <label for="usuarioCpf">CPF:</label>
                    <input class="form-control" [(ngModel)]="usuario.cpf" name="cpf" disabled id="usuarioCpf">
                  </div>
                </div>
              </div>

              <div class="box-body">
                <div class="form-group">
                  <label for="pesquisa_modulo">Módulos:</label>
                  <!-- search form -->
                  <div class="input-group form-group col-md-12" id="pesquisa_modulo">
                    <!-- /btn-group -->
                    <select class="form-control select2" #selectedValue>
                      <option selected="selected" value="0">Todos</option>
                      <option *ngFor="let modulo of modulos" [value]="modulo.idModulo">{{ modulo.sigla }} - {{
                        modulo.nome }}</option>
                    </select>
                  </div>
                  <!-- /.search form -->
                </div>
              </div>

              <div class="input-group-btn box-footer">
                <button type="submit" class="btn btn-primary pull-right"
                  (click)="filtrarModulo(selectedValue)">Pesquisar
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div style="text-align: right; margin-right: 2%;" class="mt-3">
      <button type="button" class="btn btn-success" (click)="openModal()">Clonar perfil de outro usuário
        <i class="glyphicon glyphicon-duplicate"></i>
      </button>
    </div>

    <div>
      <label>Perfis disponíveis:</label>

      <ng-container *ngFor="let modulo of obterModulos(); let i = index">
        <div class="accordion" *ngIf="usuarioVisualizaModulo(modulo)">
          <div class="accordion-item mt-3">
            <h2 class="accordion-header" [attr.id]="'heading' + modulo.idModulo">
              <div class="accordion-button d-flex align-items-center no-arrow"
                   [ngClass]="{'collapsed': !accordionState[i]}">
                <!-- Ícone de controle de abertura/fechamento à esquerda -->
                <i class="bi"
                   [ngClass]="accordionState[i] ? 'bi-dash' : 'bi-plus'"
                   (click)="toggleAccordion(i)">
                </i>
                <span class="ms-2">{{ modulo.nome }}</span>

                <!-- Checkbox para perfis alinhado à direita -->
                <input id="{{modulo.idModulo}}"
                       type="checkbox"
                       (click)="stopPropagation($event); checkPerfisPorModulo(modulo, $event.target.checked)"
                       [(ngModel)]="modulo.isCheck"
                       class="ms-auto me-2">
              </div>
            </h2>

            <!-- Corpo do accordion com estado controlado pelo array 'accordionState' -->
            <div [attr.id]="'collapse' + modulo.idModulo"
                 class="accordion-collapse collapse"
                 [ngClass]="{'show': accordionState[i]}"
                 [attr.aria-labelledby]="'heading' + modulo.idModulo"
                 [attr.data-bs-parent]="'#accordion' + modulo.idModulo">
              <div class="accordion-body">
                <table class="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>Perfil</th>
                      <th>Descrição</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let perfil of modulo.tbPerfis">
                      <td>
                        <span class="text">{{ perfil.nome }}</span>
                      </td>
                      <td>
                        <span class="text">{{ perfil.descricao }}</span>
                        <input type="checkbox" [(ngModel)]="perfil.isCheck" class="float-right pull-right">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>





    <div class="modal-footer mt-3">
      <seguranca-item [role]="roles.USUARIO_PERFIL_SALVAR">
        <button validar type="submit" class="btn btn-primary" onclick="saveToTop()" (click)="gravarUsuarioPerfil()">
          Gravar <i class="glyphicon glyphicon-ok"></i>
        </button>
      </seguranca-item>
      <button type="submit" class="btn" (click)="voltar()">
        Voltar <i class="fa fa-arrow-left"></i>
      </button>
    </div>
  </section>
</template-menu>

<modal [titulo]="txtTitulo" [msgDialog]="msgDialog">
  <p>Digite abaixo o login do usuário que deseja copiar o perfil.</p>
  <section class="content">
    <form #myForm="ngForm">
      <div class="box-body row">
        <div class="form-group col-md-6">
          <label for="login">Login do usuário a ser clonado:</label>
          <div class="input-group">
            <span class="input-group-text">
              <i class="bi bi-person"></i>
            </span>
            <input type="text" class="form-control" id="login" name="login" placeholder="Login do usuário"
              [(ngModel)]="usuarioClone.cpf" mask="000.000.000-00" type="text" required #login="ngModel"
              (keypress)="onEnter($event)">
            <div class="input-group-btn">
              <button type="button" class="btn btn-primary" style="border-radius: 0px !important;"
                (click)="buscarUsuarioClone()">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
          <div class="alert-input alert-danger" *ngIf="login.hasError('required') && (login.dirty || login.touched)">{{
            formatador.formataMensagem('MSG22', ['LOGIN']) }}</div>
        </div>
        <div class="form-group col-md-6">
          <label for="usuarioNome">Nome:</label>
          <input [(ngModel)]="usuarioClone.nome" name="nome" readonly class="form-control" id="usuarioNome" required>
        </div>
      </div>

      <div class="modal-footer">
        <button class="btn btn-primary" onclick="saveToTop()" (click)="openDialogModal(myForm);"
          [disabled]="!myForm.valid">Clonar</button>
        <button class="btn btn-secondary" (click)="cancelar(); myForm.reset();">Cancelar</button>
      </div>

      <div *ngIf="usuarioClone.nome != null && usuarioClone.nome != ''">
        <accordion>
          <accordion-group>
            <div accordion-heading>
              <span class="pull-left glyphicon glyphicon-plus"></span>
              <span class="pull-left glyphicon glyphicon-minus"></span>
              &nbsp;&nbsp; Perfis a serem clonados
            </div>
            <div *ngFor="let perfil of usuarioClone.perfis">
              <div *ngIf="perfil.tipoPerfil == 'INTERNO'">
                <b>{{ perfil.tbModulo.nome}}</b>
                <ul>
                  <li>{{ perfil.nome }}</li>
                </ul>
              </div>
            </div>
          </accordion-group>
        </accordion>
      </div>
    </form>
  </section>
  <p style="font-size:1.0em; margin-top: 0;">
    <u>Atenção</u>: o perfil do usuario
    <b>{{ usuario.nome }}</b> será
    <b>totalmente</b> substituído pelo perfil do usuário digitado acima.
  </p>
</modal>
