// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev-evo environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {

  production: false,
  ambiente: 'dev-evo',

  URL_ENVIRONMENT_API: 'https://dev-evo.ecosistemas.meioambiente.mg.gov.br/environments-api/?variaveis=',
  URL_PORTAL_SEGURANCA: 'https://dev-evo.ecosistemas.meioambiente.mg.gov.br/portalseguranca',

  token: [/\/oauth2\/token/],
  // oauthCallBack: 'https://dev-evo.ecosistemas.meioambiente.mg.gov.br/portalseguranca/authorized/',
  oauthCallBack: 'https://dev-evo.ecosistemas.meioambiente.mg.gov.br/pseg-ui/',
  tokenAllowedDomains: [ /dev-evo.ecosistemas.meioambiente.mg.gov.br/ ],
  tokenDisallowedRoutes: [/\/oauth2\/token/],
  logoutRedirectToUrl: 'https://dev-evo.ecosistemas.meioambiente.mg.gov.br/portalseguranca/login',
  URL_OAUTH: 'https://dev-evo.ecosistemas.meioambiente.mg.gov.br/pseg'

};
