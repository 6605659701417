import { Component } from '@angular/core';

import { AbstractTemplate } from '../template/abstract-template';
import { groupRoles } from './../../util/security-roles';

@Component({
  selector: 'template-menu',
  templateUrl: './template-menu.component.html',
  styleUrls: ['../template/abstract-template.css']
})
export class TemplateMenuComponent extends AbstractTemplate {
  group = groupRoles;

  clickModulo() {
    this.router.navigate(['/modulo']);
  }

  clickListarUsuario() {
    this.router.navigate(['/usuario/listar']);
  }

  clickVoltarHome() {
    this.router.navigate(['/home']);
  }

  fechar() {
    //window.close();
    this.clickVoltarHome();
  }
}
