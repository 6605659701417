import { SegurancaModule } from './rotas/seguranca.module';
import { AuthorizedComponent } from './componentes/seguranca/authorized/authorized.component';

import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { RecaptchaModule } from 'ng-recaptcha';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { FileSelectDirective, FileUploader, FileUploadModule } from 'ng2-file-upload';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import {  PopoverModule } from 'ngx-bootstrap/popover';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AbstractModule } from './abstract.module';
import { AppComponent } from './app.component';
import { AlertService } from './componentes/alert/alert.service';
import { BoxAcessoComponent } from './componentes/box-acesso/box-acesso.component';
import { ModalService } from './componentes/modal/modal.service';
import { StrengthBarService } from './componentes/strength-bar/strength-bar.service';
import { PaginaAcessoInvalidoComponent } from './erros/pagina-acesso-invalido.component';
import { PaginaConfirmacaoCadastroComponent } from './erros/pagina-confirmacao-cadastro.component';
import { PaginaConfirmacaoEmailComponent } from './erros/pagina-confirmacao-email.component';
import { PaginaErroSistemaComponent } from './erros/pagina-erro-sistema.component';
import { PaginaNaoEncontradaComponent } from './erros/pagina-nao-encontrada.component';
import { PaginaServicoIndisponivelComponent } from './erros/pagina-servico-indisponivel.component';
import { TimeOutSessaoUsuarioComponent } from './erros/pagina-timeout-sessao-usuario.component';
import { FaleConoscoComponent } from './fale-conosco/fale-conosco.component';
import { HomeComponent } from './home/home.component';
import { ManterPerfilUsuarioComponent } from './manter-perfil-usuario/manter-perfil-usuario.component';
import { ValidarContaComponent } from './manter-usuario/validar-conta.component';
import { PsegIndexComponent } from './pseg-index/pseg-index.component';
import { AppRoutingModule } from './rotas/app-routing.module';
import { AuthGuardService } from './rotas/auth-guard-service';
import { AuthService } from './services/auth-service';
import { DesconectarService } from './services/desconectar/desconectar.service';
import { EnvironmentService } from './services/environment/environment.service';
import { ErroService } from './services/erro/erro.service';
import { FaleConoscoService } from './services/fale-conosco/fale-conosco.service';
import { HomeService } from './services/home/home.service';
import { HttpAuthInterceptor } from './services/http-auth-token.interceptor/http-auth-token.interceptor';
import { HttpIndicatorInterceptor } from './services/http-interceptor/http-indicator.interceptor';
import { HttpIndicatorService } from './services/http-interceptor/http-indicator.service';
import { HttpAuthResponseInterceptor } from './services/http-token-response-interceptor/http-auth-response.interceptor';
import { LoginService } from './services/login/login-service';
import { PerfilAcaoService } from './services/manter-perfil-acao/perfil-acao.service';
import { PerfilUsuarioService } from './services/manter-perfil-usuario/perfil-usuario.service';
import { UsuarioExternoService } from './services/manter-usuario/usuario-externo.service';
import { UsuarioInternoService } from './services/manter-usuario/usuario-interno.service';
import { RestService } from './services/rest-service';
import { MaskConstants } from './util/mask-constants';
import { FormatadorDeMensagens } from './util/mensagens-padrao';
import { Validador } from './util/validador-cpf-cnpj';
import { FormsModule }   from '@angular/forms';
import { PublicFaqComponent } from './public-faq/public-faq.component';
import { FaqService } from './services/manter-faq/faq.service';
import { LoginGovBrService } from './services/portal-gov-br/login-gov-br.service';
import { AuthHomeServiceGuard } from './rotas/auth-guard-home-service';
import { SegurancaItemComponent } from './componentes/seguranca-item/seguranca-item.component';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { MatTabsModule } from '@angular/material/tabs';
import { CookieService } from 'ngx-cookie-service';

export function httpFactory(service: HttpIndicatorService) {
  return new HttpIndicatorInterceptor(service);
}



export function httpFactoryAuthRequest(
  authService: AuthService,
  erroService: ErroService,
) {
  return new HttpAuthInterceptor(authService, erroService);
}

export function httpFactoryAuthResponseRequest(authService: AuthService) {
  return new HttpAuthResponseInterceptor(authService);
}


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PsegIndexComponent,
    PaginaNaoEncontradaComponent,
    PaginaAcessoInvalidoComponent,
    ManterPerfilUsuarioComponent,
    ValidarContaComponent,
    PaginaConfirmacaoCadastroComponent,
    PaginaConfirmacaoEmailComponent,
    PaginaErroSistemaComponent,
    TimeOutSessaoUsuarioComponent,
    BoxAcessoComponent,
    PaginaServicoIndisponivelComponent,
    FaleConoscoComponent,
  ],

  imports: [
    BrowserModule,
    AbstractModule,
    MatTabsModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot({
      validation: true,
    }),
    RecaptchaModule,
    AccordionModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    CommonModule,
    NgSelectModule,
    FileUploadModule,
    FormsModule,
    SegurancaModule
  ],

  providers: [
    CookieService,
    EnvironmentService,
    FormatadorDeMensagens,
    LoginService,
    UsuarioExternoService,
    UsuarioInternoService,
    MaskConstants,
    AuthGuardService,
    AuthHomeServiceGuard,
    Validador,
    PerfilUsuarioService,
    RestService,
    AlertService,
    AuthService,
    PerfilAcaoService,
    HomeService,
    DesconectarService,
    ModalService,
    ErroService,
    HttpIndicatorService,
    StrengthBarService,
    FaleConoscoService,
    FaqService,
    LoginGovBrService,
    HttpAuthInterceptor,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: httpFactory,
      multi: true,
      deps: [HttpIndicatorService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: httpFactoryAuthRequest,
      multi: true,
      deps: [AuthService, ErroService, ModalService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: httpFactoryAuthResponseRequest,
      multi: true,
      deps: [AuthService]
    }

  ],

  bootstrap: [AppComponent]
})
export class AppModule {}
