import { AuthService } from './../auth-service';
import { catchError } from 'rxjs/operators';
import { HttpParams, HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TipoRequisicaoRestEnum } from '../../models/tipo-requisicao-rest.enum';
import { RestService } from '../rest-service';
import { AbstractService } from './../abstract.service';
import { FiltroFaqVO } from '../../models/vo/filtro-faq-vo';

const PATH = '/home';

@Injectable()
export class HomeService {
  constructor(private rest: RestService, private authService: AuthService, private http: HttpClient) {
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    this.authService.doLogout();
    return throwError(errorMessage);
  }

  /**
   * Busca lista de módulos em operação de acordo com o perfil do usuário logado.
   */
  public buscarListaModulosPorUsuarioPerfil(): Observable<any> {
     this.authService.limparTokenSemUserName();
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      PATH + '/buscarListaModulosPorUsuarioPerfil'
    ).pipe(catchError(this.handleError));
  }

  public buscarListaModulosPorUsuarioPerfilGovBR(): Observable<any> {
    this.authService.limparTokenSemUserName();

    const headers = new HttpHeaders({
      'Content-type': 'application/json; charset=utf-8'
    });

     return this.http.get(environment.URL_OAUTH+"/home/buscarListaModulosPorUsuarioPerfil",
        {
          headers: headers
        }
      );
  }

  /**
   * Busca lista de módulos em operação de acordo com o perfil do usuário logado.
   */
  public buscarModulosAtivos(): Observable<any> {
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      PATH + '/buscarModulosAtivos'
    );
  }
  /**
   * Busca lista de módulos em operação de acordo com o perfil do usuário logado.
   */
  public buscarModulosAtivosFAQ(): Observable<any> {
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      PATH + '/buscarModulosAtivosFAQ'
    );
  }

  public buscarListaFaqPorFiltro(filtro: FiltroFaqVO): Observable<any> {
    if (filtro.page != 0) {
      filtro.page = filtro.page - 1;
    }
    let params = new HttpParams()
      .set('idModulo', filtro.idModulo)
      .set('pergunta', filtro.pergunta)
      .set('resposta', filtro.resposta)
      .set('page', filtro.page)
      .set('idFaq', filtro.idFaq)
      .set('size', filtro.size);
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      PATH + '/filtrarFaqs',
      params
    );
  }

  /**
   * Busca lista de roles de acordo com o módulo informado e com o perfil do usuário logado.
   *
   * @param idModulo
   */
  public buscarRolesUsuarioPorModulo(idModulo): Observable<any> {
    let params = new HttpParams().set('idModulo', idModulo);
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      PATH + '/buscarRolesUsuarioPorModulo',
      params
    );
  }

  /**
   * Verifica se a senha do usuário está próxima de expirar de acordo com o parâmetro
   * TEMPO_AVISO_EXPIRA_SENHA e retorna a quantidade de dia.
   */
  public validaTempoExpiraSenha() {
     this.authService.limparTokenSemUserName();
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      PATH + '/validaExpiraSenha'
    ).pipe(catchError(this.handleError));
  }

  /**
   * Método responsável por reverter a senha que usuário já possuia no portal.
   *
   */
  public reverterSenha(cpf: any) {
    if(localStorage.getItem("sub") != null && localStorage.getItem("sub") !== undefined){
      this.rest.gerarSolicitacao(TipoRequisicaoRestEnum.PUT, '/autenticar-gov-br/senha/reverter', null, localStorage.getItem("sub"));
      localStorage.removeItem("sub");
    } else {
      this.rest.gerarSolicitacao(TipoRequisicaoRestEnum.PUT, '/autenticar-gov-br/senha/reverter', null, cpf);
    }
  }


}

