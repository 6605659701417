<template-simples>

  <br><br>
  <!-- style="margin-left: 15px;" -->
  <div class="container-fluid" >


    <div class="row">



      <div class="col-md-7" style="margin-bottom: 20px;">
        <div style="display: flex; align-items: center;">
          <span style="border-left: 3px solid gray; margin-right: 5px; height: 20px; display: inline-block;"></span>
          <span style="color: gray; margin-right: 5px; font-weight: bold;">Sistemas</span>

          <hr style="flex-grow: 1; border-width: 2px;">
        </div>
      </div>

        <div class="col-md-5" style="margin-bottom: 20px;">
          <div style="display: flex; align-items: center;">
            <span style="border-left: 3px solid gray; margin-right: 5px; height: 20px; display: inline-block;"></span>
            <span style="color: gray; margin-right: 5px; font-weight: bold;">Notificações</span>
            <hr style="flex-grow: 1; border-width: 2px;">
          </div>


          <div style="margin-top: 10px">
            <app-modal-marcar-visualizados (chamadaModal)="recarregarNotificacoes($event)"></app-modal-marcar-visualizados>
          </div>

        </div>



      <!-- Cards ocupando 3/5 da tela -->
      <div class="col-md-7" style="margin-top: -57px">

        <!-- SEMAD -->
        <div class="row">
          <div class="col-md-4" *ngFor="let modulo of todosModulos; let i = index">

            <!--SEMAD-->
            <div class="mb-4" *ngIf="modulo.orgao == 'SEMAD'">
              <div class="card h-100 shadow-sm" style="border-color:#D73E3E; height: 112px; border-top-left-radius: 15px; border-top-right-radius: 15px; border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;">
                <div class="d-flex justify-content-between align-items-center p-2">
                  <span style="font-weight: bold; font-size: 16px; color: #D73E3E;">
                    {{modulo.orgao}}
                  </span>
                  <span>
                    <i class="bi bi-info-circle-fill" style="color: #D73E3E;" title="{{modulo.textoExplicativo}}"></i>
                  </span>
                </div>

                <div class="card-body d-flex justify-content-center align-items-center" style="height: 70px;">
                  <p class="card-text" style="color: #D73E3E; text-align: center; font-size: clamp(14px, 2vw, 16px);">
                    {{modulo.descricao}}
                  </p>
                </div>

                <div class="text-center text-white d-flex justify-content-center align-items-center"
                     style="border-bottom-right-radius: 15px; border-bottom-left-radius: 15px; background-color: #D73E3E; cursor: pointer; height: 44px;"
                     (click)="abrirModulo(modulo.link)">
                  <span style="font-weight: bold;">Acessar</span>
                </div>

              </div>
            </div>


            <div class="mb-4" *ngIf="modulo.orgao == 'SISEMA'">
              <div class="card h-100 shadow-sm" style="border-color:#D8953D; height: 12vh; border-radius: 15px; ">
                <div class="d-flex justify-content-between align-items-center p-2">
                  <span style="font-weight: bold; font-size: 16px; color: #D8953D;">
                    {{modulo.orgao}}
                  </span>
                  <span>
                    <i class="bi bi-info-circle-fill" style="color: #D8953D;" title="{{modulo.textoExplicativo}}"></i>
                  </span>
                </div>

                <!-- Reduzir a altura do card-body -->
                <div class="card-body d-flex justify-content-center align-items-center" style="height: 70px;">
                  <p class="card-text" style="color: #D8953D; text-align: center; font-size: clamp(14px, 2vw, 16px);">
                    {{modulo.descricao}}
                  </p>
                </div>

                <!-- Mantém a altura do botão "Acessar" -->
                <div class="text-white d-flex justify-content-center align-items-center"
                     style="border-bottom-right-radius: 15px; border-bottom-left-radius: 15px; background-color: #D8953D; cursor: pointer; height: 44px;"
                     (click)="abrirModulo(modulo.link)">
                  <span style="font-weight: bold;">Acessar</span>
                </div>
              </div>
            </div>


            <div class="mb-4" *ngIf="modulo.orgao == 'FEAM'">
              <div class="card h-100 shadow-sm rounded-card" style="border-color:#196C35; border-top-left-radius: 15px; border-top-right-radius: 15px; border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;">
                <div class="d-flex justify-content-between align-items-center p-2">
                  <span style="font-weight: bold; font-size: 16px; color: #196C35;">
                    {{modulo.orgao}}
                  </span>
                  <span>
                    <i class="bi bi-info-circle-fill" style="color: #196C35;" title="{{modulo.textoExplicativo}}"></i>
                  </span>
                </div>

                <div class="card-body d-flex justify-content-center align-items-center" style="height: 70px;">
                  <p class="card-text" style="color: #196C35; text-align: center; font-size: clamp(14px, 2vw, 16px);">
                    {{modulo.descricao}}
                  </p>
                </div>

                <div class="text-center text-white d-flex justify-content-center align-items-center"
                     style="border-bottom-right-radius: 15px; border-bottom-left-radius: 15px; background-color: #196C35; cursor: pointer; height: 44px;"
                     (click)="abrirModulo(modulo.link)">
                  <span style="font-weight: bold;">Acessar</span>
                </div>
              </div>
            </div>


            <div class="mb-4" *ngIf="modulo.orgao == 'IEF'">
              <div class="card h-100 shadow-sm rounded-card" style="border-color:#70B930; border-top-left-radius: 15px; border-top-right-radius: 15px; border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;">
                <div class="d-flex justify-content-between align-items-center p-2">
                  <span style="font-weight: bold; font-size: 16px; color: #70B930;">
                    {{modulo.orgao}}
                  </span>
                  <span>
                    <i class="bi bi-info-circle-fill" style="color: #70B930;" title="{{modulo.textoExplicativo}}"></i>
                  </span>
                </div>

                <div class="card-body d-flex justify-content-center align-items-center" style="height: 70px;">
                  <p class="card-text" style="color: #70B930; text-align: center; font-size: clamp(14px, 2vw, 16px);">
                    {{modulo.descricao}}
                  </p>
                </div>

                <div class="text-center text-white d-flex justify-content-center align-items-center"
                     style="border-bottom-right-radius: 15px; border-bottom-left-radius: 15px; background-color: #70B930; cursor: pointer; height: 44px;"
                     (click)="abrirModulo(modulo.link)">
                  <span style="font-weight: bold;">Acessar</span>
                </div>
              </div>
            </div>


            <div class="mb-4" *ngIf="modulo.orgao == 'IGAM'">
              <div class="card  shadow-sm " style="border-color:#085DA7; border-top-left-radius: 15px;  border-top-right-radius: 15px; border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;">
                <div class="d-flex justify-content-between align-items-center p-2">
                  <span style="font-weight: bold; font-size: 16px; color: #085DA7; ">
                    {{modulo.orgao}}
                  </span>
                  <span>
                    <i class="bi bi-info-circle-fill" style="color: #085DA7;" title="{{modulo.textoExplicativo}}"></i>
                  </span>
                </div>

                <div class="card-body d-flex justify-content-center align-items-center" style="height: 70px;">
                  <p class="card-text" style="color: #085DA7; text-align: center; font-size: clamp(14px, 2vw, 16px);">
                    {{modulo.descricao}}
                  </p>
                </div>

                <div class="text-center text-white d-flex justify-content-center align-items-center"
                     style="border-bottom-right-radius: 15px; border-bottom-left-radius: 15px; height: 44px; background-color: #085DA7; cursor: pointer; height: 44px;"
                     (click)="abrirModulo(modulo.link)">
                  <span style="font-weight: bold;">Acessar</span>
                </div>
              </div>
            </div>

          </div>
        </div>


        <br><br>

      </div>





      <!-- Layout adicional ocupando 2/5 da tela -->
      <div class="col-md-5" style="display: flex; position: relative;">
        <mat-tab-group class="custom-tab-group" style="width: 100%; flex-grow: 1;">
          <mat-tab>
            <ng-template mat-tab-label class="custom-tab left-align">
              <div class="notification-icon">
                <i class="bi bi-bell-fill" style="color: #085DA7; font-size: 18px;"></i>
                <span class="notification-badge">{{ notificacoesResponse.notificacoes.Pendente.length }}</span>
              </div>
              &nbsp;
              <span>Não Visualizados</span>
            </ng-template>

            <div *ngFor="let notificacao of notificacoesResponse.notificacoes.Pendente" class="notificacao-item">
              <div class="modulo-container">
                <p [ngClass]="{
                  'modulo-semad': !notificacao.orgao || notificacao.orgao === 'SEMAD',
                  'modulo-igam': notificacao.orgao === 'IGAM',
                  'modulo-sisema': notificacao.orgao === 'SISEMA',
                  'modulo-ief': notificacao.orgao === 'IEF',
                  'modulo-feam': notificacao.orgao === 'FEAM'
                }">
                {{ notificacao.sistema }}
              </p>
                <p class="dataHora-text">
                  <i class="bi bi-clock"></i> {{ notificacao.created_at | date:'dd/MM/yyyy HH:mm' }}
                </p>
              </div>
              <div class="modulo-container">
                <p class="mensagem-text">{{ notificacao.titulo }}</p>
                <p>
                  <app-modal-notificacao (chamadaModal)="recarregarNotificacoes($event)" [notificacao]="notificacao"></app-modal-notificacao>
                 </p>
              </div>
              <hr>
            </div>
          </mat-tab>

          <mat-tab>
            <ng-template mat-tab-label class="custom-tab left-align">
              <span class="left-align">
                <i class="bi bi-bell" style="font-size: 18px;"></i>
              </span>
              &nbsp;
              <span class="left-align">Visualizados</span>
            </ng-template>
            <div *ngFor="let notificacao of notificacoesResponse.notificacoes.Lida" class="notificacao-item">
              <div class="modulo-container">
                <p [ngClass]="{
                  'modulo-semad': !notificacao.orgao || notificacao.orgao === 'SEMAD',
                  'modulo-igam': notificacao.orgao === 'IGAM',
                  'modulo-sisema': notificacao.orgao === 'SISEMA',
                  'modulo-ief': notificacao.orgao === 'IEF',
                  'modulo-feam': notificacao.orgao === 'FEAM'
                }">
                {{ notificacao.modulo }}
              </p>
              <p class="dataHora-text">
                <i class="bi bi-clock"></i> <span style="color: gray"></span>{{ notificacao.created_at | date:'dd/MM/yyyy HH:mm' }}
              </p>
              </div>
              <div class="modulo-container">
                <p class="mensagem-text">{{ notificacao.titulo }}</p>
                <p>
                  <app-modal-notificacao (chamadaModal)="recarregarNotificacoes($event)" [notificacao]="notificacao"></app-modal-notificacao>
                 </p>
              </div>
              <hr>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>




    </div>


  </div>
</template-simples>
