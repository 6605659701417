import { TipoRequisicaoRestEnum } from './../../models/tipo-requisicao-rest.enum';
import { RestService } from './../../services/rest-service';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment.hom';
import { AbstractService } from './../../services/abstract.service';
import { Injectable } from '@angular/core';

const PATH = '/estrutura-organizacional';


@Injectable()
export class EstruturaOrganizacionalService extends AbstractService {

  estrut = null;

  constructor(private rest: RestService) {
    super();
  }

  /**
   * Busca a lista de Estrutura Organizacinoal.
   *
   * @param uf
   */
  public listar(): Observable<any> {
    let dataObserver;
    let data = new Observable(observer => (dataObserver = observer));

    this.rest.gerarSolicitacao(TipoRequisicaoRestEnum.GET, PATH).subscribe(retorno => {
      this.estrut = retorno;
      if (dataObserver != null) {
        dataObserver.next(retorno);
      }
    });
    return this.estrut;
  }



}
