import { NotificacoesResponse } from './../../models/vo/notificacoes-response-vo';
import { NotificacaoService } from './../../services/notificacao/notificacao.service';
import { LoginService } from './../../services/login/login-service';
import { Injectable, Renderer2, OnInit, Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UsuarioExternoService } from '../../services/manter-usuario/usuario-externo.service';
import { Usuario } from './../../models/usuario';
import { AuthService } from './../../services/auth-service';
import { DesconectarService } from './../../services/desconectar/desconectar.service';
import { HttpIndicatorService } from './../../services/http-interceptor/http-indicator.service';
@Directive()
@Injectable()
export class AbstractTemplate implements OnInit {
  loading = false;
  private usuario: Usuario;
  public versao: string = '2.9.1';

  constructor(
    public router: Router,
    private authService: AuthService,
    private usuarioService: UsuarioExternoService,
    private desconectarService: DesconectarService,
    private httpIndicatorService: HttpIndicatorService,
    private activatedRoute: ActivatedRoute,
    public renderer: Renderer2, private service: LoginService,
  ) {


  }

  public ngOnInit(): void {
    this.httpIndicatorService.onLoadingChanged.subscribe(
      isLoading => (this.loading = isLoading)
    );
  }


  carregaUsuario() {
    if (this.authService.isAuthenticated() && this.usuario == undefined
    && this.activatedRoute.snapshot.queryParams.consulta == null) {
    this.usuarioService
      .buscarUsuarioLogado()
      .subscribe(usuario => (this.usuario = usuario));
    }
  }

  usuarioAutenticado():boolean{
    return this.authService.isAuthenticated();
  }

  primeiroNome(): string {
    if (this.authService.getToken()) {
      return this.authService.getPrimeiroNomeUsuario();
    }
  }

  // primeiroNome() {
  //     return this.authService.getNomeUsuario().split(' ')[0];
  // }

  getNome() {
    if (this.usuario != undefined) {
      return this.usuario.nome;
    }
  }

  clickTrocarSenha() {
    this.router.navigate(['/trocar-senha']);
  }

  clickAlterarUsuario() {
    this.router.navigate(['/usuario/alterar']);
  }

  desconectar() {
    if (!this.usuario) {
      this.usuarioService
      .buscarUsuarioLogado()
      .subscribe(data => {
        this.usuario = data,
          this.realizarLogoff();
        });
    } else {
      this.realizarLogoff();
    }
  }

  private realizarLogoff() {
    this.authService.logout();
  }

}
