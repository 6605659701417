import { NotificacoesResponse } from './../../models/vo/notificacoes-response-vo';
import { NotificacaoService } from './../../services/notificacao/notificacao.service';
import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'app-modal-marcar-visualizados',
  templateUrl: './modal-marcar-visualizados.component.html',
  styleUrl: './modal-marcar-visualizados.component.css'
})
export class ModalMarcarVisualizadosComponent implements OnInit{

  @Output() chamadaModal = new EventEmitter();
  @ViewChild('modal', {static: false}) modal: TemplateRef<any>;
  @ViewChild('vc', {read: ViewContainerRef, static: false}) vc: ViewContainerRef;
  backdrop: any;
  notificacoesResponse: NotificacoesResponse;
  marcouTodosNotificacoes = false;
  existeNotificoesPendentes = false;
  constructor(private notificacaoService: NotificacaoService) {
    this.notificacoesResponse = new NotificacoesResponse();
  }

  buscarNotificacoes(){
    this.notificacaoService.buscarTodasNotificacoes().subscribe(data => {
      this.notificacoesResponse = data
      this.notificacoesResponse.notificacoes?.Pendente?.length > 0 ? this.existeNotificoesPendentes = true : this.existeNotificoesPendentes = false;
    });
  }

  showDialog() {

    // Insere o template no container
    const view = this.modal.createEmbeddedView(null);
    this.vc.insert(view);

    // Obtém o modal pelo ID diretamente após a inserção no DOM
    const modalElement = document.getElementById('marcarVisualizados');  // Assume-se que o ID do modal é 'usuarios'

    // Mover o modal para o body
    document.body.appendChild(modalElement);

    // Modificar estilos para exibir o modal corretamente
    modalElement.classList.remove('fade');  // Remove a animação fade
    modalElement.classList.add('modal-open');  // Adiciona a classe modal-open para exibir corretamente
    modalElement.classList.add('show');  // Adiciona a classe show para exibição
    modalElement.style.display = 'block';  // Garante que o modal apareça

    // Criar o backdrop manualmente
    this.backdrop = document.createElement('DIV');
    this.backdrop.className = 'modal-backdrop show';  // Classe necessária para o backdrop
    document.body.appendChild(this.backdrop);
  }

  marcarTodosComoVisualizado(){
    this.notificacoesResponse.notificacoes.Pendente.forEach(notificacao => {
      this.notificacaoService.marcarComoLido(notificacao.id).subscribe();
      this.marcouTodosNotificacoes = true;
    });
    this.closeDialog();
  }


  ngOnInit() {
    this.buscarNotificacoes();
  }

  closeDialog() {
    if(this.marcouTodosNotificacoes){
      this.chamadaModal.emit('notificacaoFechou');
    }

    // Limpa o conteúdo dinâmico do ViewContainerRef
    this.vc.clear();

    // Verifica se o backdrop existe antes de tentar removê-lo
    if (this.backdrop && document.body.contains(this.backdrop)) {
      document.body.removeChild(this.backdrop);
    }

    // Obtém o modal pelo ID para garantir que ele está anexado ao body
    const modalElement = document.getElementById('marcarVisualizados');

    // Verifica se o modal existe no body antes de removê-lo
    if (modalElement && document.body.contains(modalElement)) {
      document.body.removeChild(modalElement);
    }
  }

}
