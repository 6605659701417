import { NotificacoesResponse } from './../models/vo/notificacoes-response-vo';
import { NotificacaoService } from './../services/notificacao/notificacao.service';
import { AuthService } from './../services/auth-service';
import { Component, OnInit } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { OrgaoEnum } from '../models/orgao.enum';
import { TrocarSenhaService } from '../services/trocar-senha/trocar-senha.service';
import { ModalService } from './../componentes/modal/modal.service';
import { Modulo } from './../models/modulo';
import { HomeService } from './../services/home/home.service';
import { FormatadorDeMensagens } from './../util/mensagens-padrao';
import {MatTabsModule} from '@angular/material/tabs';
import { Notificacao } from '../models/vo/notificacao-vo';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  //SEMAD = 0, IGAM = 1, IEF = 2, FEAM = 3, SISEMA = 4;
  modulos: Modulo[];
  modulosRestantes: Modulo[];
  modulosOrdenados: Modulo[];
  igam: Modulo[];
  ief: Modulo[];
  semad: Modulo[];
  feam: Modulo[];
  sisema: Modulo[];
  todosModulos: Modulo[];
  notificacoesResponse: NotificacoesResponse;
  pendentes: Notificacao[];
  lidas: Notificacao[];
  public msgDialog: String;
  panelOpenState = false;
  inscricao: Subscription;
  botao = [];

  constructor(
    private homeService: HomeService, private notificacaoService: NotificacaoService,
    private modalService: ModalService,
    private formatador: FormatadorDeMensagens,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) {
    this.modulos = [];
    this.modulosRestantes = [];
    this.modulosOrdenados = [];
    this.igam = [];
    this.sisema = [];
    this.todosModulos = [];
    this.notificacoesResponse = new NotificacoesResponse();
  }

  ngOnInit() {

    const urlParams = new URLSearchParams(window.location.search);


    if (this.activatedRoute.snapshot.queryParams.consulta == null) {

      //verifica se o token foi gerado usando autenticacao gov.br ou se foi acessando o portal diretamente
       if(!this.authService.tokenGovBr()){
        //busca modulos pseg novo
        this.homeService
        .buscarListaModulosPorUsuarioPerfil()
        .subscribe(modulos => {
          if(modulos == null){
            this.authService.doLogout();
          }
          this.buscarNotificacoes(modulos);
          this.ordenarListaModulos(modulos);
        });
      } else {
        //busca modulos a partir da api do pseg antigo
        this.homeService
        .buscarListaModulosPorUsuarioPerfilGovBR()
        .subscribe(modulos => {
          if(modulos == null){
            this.authService.doLogout();
          }
          this.ordenarListaModulos(modulos);
        });
      }



    this.homeService.validaTempoExpiraSenha().subscribe(retorno => {
      if(retorno == null){
        this.authService.doLogout();
      }
      if (retorno > 0) {
        this.showAviso(retorno);
      }});
    }


    // this.modulos.forEach(modulo => {
    //   console.log(modulo.orgao);
    // });



  }

  buscarNotificacoes(modulos: any){
    // console.log(modulos);

    if(modulos.length > 0){
      this.notificacaoService.buscarTodasNotificacoes().subscribe(data => {
        this.notificacoesResponse = data

      console.log(this.notificacoesResponse);
      for(let i = 0; i < modulos.length; i++){
        let modulo = modulos[i];
        for(let x = 0; x < this.notificacoesResponse?.notificacoes?.Lida?.length; x++){
          let notificacao = this.notificacoesResponse.notificacoes.Lida[x];

          if(notificacao.modulo == modulo.sigla){
            notificacao.orgao = modulo.orgao;
          }
        }

        for(let x = 0; x < this.notificacoesResponse?.notificacoes?.Pendente?.length; x++){
          let notificacao = this.notificacoesResponse?.notificacoes?.Pendente[x];
          if(notificacao.modulo == modulo.sigla){
            notificacao.orgao = modulo.orgao;
          }
        }
      }
    });
    }

  }


  recarregarNotificacoes(valor){
    if(valor == 'notificacaoFechou'){
      this.buscarNotificacoes(this.modulos);
    }
  }



  ordenarListaModulos(modulos) {
    let I;
    /* Coloca o módulo CADU como primeira opção */
    for (I = 0; I < modulos.length; I++) {
      if (modulos[I].sigla == 'CADU') {
        this.modulosOrdenados.push(modulos[I]);
    } else {
        this.modulosRestantes.push(modulos[I]);
      }
    }

    modulos = this.modulosRestantes;
    this.modulosRestantes = [];

    /* Coloca o módulo SLA como segunda opção */
    for (I = 0; I < modulos.length; I++) {
      if (modulos[I].sigla == 'SLA') {
        this.modulosOrdenados.push(modulos[I]);
      } else {
        this.modulosRestantes.push(modulos[I]);
      }
    }

    modulos = this.modulosRestantes;
    this.modulosRestantes = [];

    /* Insere o restante dos módulos, conforme ordenação vinda do Back-End */
    for (I = 0; I < modulos.length; I++) {
        this.modulosOrdenados.push(modulos[I]);

    }

    /*Remove objetos que estão vazios na lista recuperada do Back-End*/
    for (I = 0; I < this.modulosOrdenados.length; I++) {

      if (this.modulosOrdenados[I].descricao != null && this.modulosOrdenados[I].descricao != undefined) {
        this.modulos.push(this.modulosOrdenados[I]);
      }
    }

    this.modulos.forEach(modulo => {

      this.sisema = this.modulos.filter(
        modulo => OrgaoEnum.SISEMA == Number.parseInt(OrgaoEnum[modulo.orgao])
      );

      this.igam = this.modulos.filter(
        modulo => OrgaoEnum.IGAM == Number.parseInt(OrgaoEnum[modulo.orgao])
      );

      this.ief = this.modulos.filter(
        modulo => OrgaoEnum.IEF == Number.parseInt(OrgaoEnum[modulo.orgao])
      );

      this.semad = this.modulos.filter(
        modulo => OrgaoEnum.SEMAD == Number.parseInt(OrgaoEnum[modulo.orgao])
      );

      this.feam = this.modulos.filter(
        modulo => OrgaoEnum.FEAM == Number.parseInt(OrgaoEnum[modulo.orgao])
      );
    });

    this.semad.forEach(semad => {
      this.todosModulos.push(semad);
    })
    this.sisema.forEach(sisema => {
      this.todosModulos.push(sisema);
    });
    this.feam.forEach(feam => {
      this.todosModulos.push(feam);
    });
    this.ief.forEach(ief => {
      this.todosModulos.push(ief);
    });
    this.igam.forEach(igam => {
      this.todosModulos.push(igam);
    });

  }

  abrirModulo(link) {
    window.open(link, '_self');
  }

  moduloSemad(): Modulo[] {
    let list: Modulo[] = this.modulos.filter(
      modulo => OrgaoEnum.SEMAD == Number.parseInt(OrgaoEnum[modulo.orgao])
    );
    this.detalheBoxModulo(list, iconColorSemad);

    return list;
  }

  moduloIgam(): Modulo[] {
    let list: Modulo[] = this.modulos.filter(
      modulo => OrgaoEnum.IGAM == Number.parseInt(OrgaoEnum[modulo.orgao])
    );
    this.detalheBoxModulo(list, iconColorIgam);
    return list;
  }

  moduloIef(): Modulo[] {
    let list: Modulo[] = this.modulos.filter(
      modulo => OrgaoEnum.IEF == Number.parseInt(OrgaoEnum[modulo.orgao])
    );
    this.detalheBoxModulo(list, iconColorIef);

    return list;
  }

  moduloFeam(): Modulo[] {
    let list: Modulo[] = this.modulos.filter(
      modulo => OrgaoEnum.FEAM == Number.parseInt(OrgaoEnum[modulo.orgao])
    );
    this.detalheBoxModulo(list, iconColorFeam);

    return list;
  }

  /**
   * Preenche a cor do título do box de acordo com a posição do modulo.
   */
  private detalheBoxModulo(modulos: Modulo[], iconColor) {
    let position = 0;
    modulos.forEach(modulo => {
      modulo.colorIcon = iconColor[position];
      position += 1;
      if (position == 4) {
        position = 0;
      }
    });
  }

  public showAviso(dias) {
    this.msgDialog = this.formatador.formataMensagem('MSG21', [dias]);
    this.modalService.openDialogModal('modal-warning');
  }

  ngOnDestroy(): void {
    this.modalService.fecharDialogModal();
  }

  click(idModulo, link) {
    this.inscricao = this.homeService
      .buscarRolesUsuarioPorModulo(idModulo)
      .subscribe(retorno => {window.open(link, '_self');});
  }

}

export const iconColorSemad = {
  0: '#f9a32e',
  1: '#e18b2f',
  2: '#ad642f',
  3: '#6b3a21'
};
export const iconColorIgam = {
  0: '#1ab1e6',
  1: '#0f97d6',
  2: '#137db6',
  3: '#225c91'
};
export const iconColorIef = {
  0: '#86aa43',
  1: '#789841',
  2: '#647e3c',
  3: '#607241'
};
export const iconColorFeam = {
  0: '#008e76',
  1: '#087d66',
  2: '#086954',
  3: '#095b4c'
};
