<button type="button" *ngIf="existeNotificoesPendentes" class="btn btn-sm" style="border-color: #828492; background-color: white; color: #828492"
  (click)="showDialog()">
  <i class="bi bi-bell-fill" style="color: #828492; font-size: 18px"></i>
  &nbsp; Marcar como Visualizados
</button>

<button type="button" *ngIf="!existeNotificoesPendentes" class="btn btn-sm" style="border-color: #828492; background-color: white; color: #828492"
  disabled>
  <i class="bi bi-bell-fill" style="color: #828492; font-size: 18px"></i>
  &nbsp; Marcar como Visualizados
</button>

<ng-container #vc></ng-container>
<ng-template #modal>
  <div class="modal fade" id="marcarVisualizados" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog modal-lg modal-custom" role="document">
      <div class="modal-content">
        <!-- <button type="button" class="close-btn" (click)="closeDialog()">×</button> -->


        <div class="notificacao-item">
          <div class="modal-body" style="margin-top: -10px">
            <div class="modulo-container">
              <p class="modulo-mensagem">
                Ao clicar no botão Marcar todos como Visualizado, todas as
                mensagem vão para a caixa de visualizados.
              </p>
            </div>

          </div>

          <div class="modulo-container">
            <p class="mensagem-confirmacao">Você confirma?</p>
          </div>

        </div>


        <!-- Botões de ação: alinhados à esquerda e direita -->
        <div class="d-flex justify-content-between" style="margin: 20px 18px">
          <!-- Botão Ir ao Sistema  (esquerda) -->
          <button type="button" class="btn" style="background-color: white; border-color: #085da7; width: 100px"
            (click)="marcarTodosComoVisualizado()">
            <span style="color: #085da7">Sim</span>
          </button>

          <!-- Botão Fechar Alerta (direita)  -->
          <button class="btn" style="
              background-color: white;
              border-radius: 3px;
              border-color: #dc2121;
              width: 100px;
            " (click)="closeDialog()">
            <span style="color: #dc2121"> Não </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
