import { AuthService } from './../services/auth-service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthHomeServiceGuard implements CanActivate {


  constructor(
    private auth: AuthService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if (this.auth.isAccessTokenInvalido() && localStorage.getItem('refreshToken') !== null ) {
        console.log('Navegação com access token inválido. Obtendo novo token...');

        return this.auth.gerarAccessTokenComRefresToken()
          .then(() => {
            if (this.auth.isAccessTokenInvalido()){
              this.auth.logout();
              this.router.navigate(['/authorized']);
              return false;
            }
            return true;
          });
      } else if (next.data.roles && !this.auth.temQualquerPermissao(next.data.roles)) {
        this.router.navigate(['/acesso-invalido']);
        return false;
      }
      return true;

  }

}
