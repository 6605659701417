import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';

import { ModalService } from './modal.service';
import { AlertService } from '../alert/alert.service';
import { Alert } from '../alert/alert';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  @ViewChild('template') private template: TemplateRef<any>;
  @ViewChild('templateDialog') private templateDialog: TemplateRef<any>;
  @ViewChild('simpleTemplate') simpleTemplate: TemplateRef<any>;
  bsModalRef: BsModalRef;

  @Input() public titulo: String;
  @Input() public msgDialog: String;
  @Input() public exibeBotaoOk: boolean;

  alerts: Alert[] = [];

  constructor(public modalService: ModalService, private alertService: AlertService, private bsModalService: BsModalService) {
    this.exibeBotaoOk = false;
  }

  ngAfterViewInit() {
  this.modalService.template=this.template;
  this.modalService.templateDialog=this.templateDialog;
  }
  ngOnInit() {
    this.alertService.getAlertModal().subscribe((alert: Alert) => {
      if (!alert) {
        // clear alerts when an empty alert is received
        this.alerts = [];
        return;
      }

      // add alert to array
      this.alerts.push(alert);
    });
    // this.modalService.template = this.template;
    // this.modalService.templateDialog = this.templateDialog;
  }

  fecharModal() {
    this.modalService.fecharModal();
  }


  buttonNaoDialogModal() {
    this.modalService.fecharDialogModal();
  }

  buttonSimDialogModal() {
    this.modalService.fecharSucessDialogModal();
  }

  removeAlert(alert: Alert) {
    this.alerts = this.alerts.filter(x => x !== alert);
  }
}
