import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AlertService } from '../../componentes/alert/alert.service';
import { ModalService } from '../../componentes/modal/modal.service';
import { AbstractService } from '../abstract.service';
import { RestService } from '../rest-service';
import { Acao } from '../../models/acao';
import { TipoRequisicaoRestEnum } from '../../models/tipo-requisicao-rest.enum';
import { FiltroAcaoVO } from '../../models/vo/filtro-acao-vo';
import { mensagensPadrao } from '../../util/mensagens-padrao';
import { FiltroFaqVO } from '../../models/vo/filtro-faq-vo';
import { Faq } from '../../models/faq';
import { ModuloService } from '../manter-modulo/modulo.service';
import { AuthService } from '../auth-service';

const PATH = '/faq';

@Injectable()
export class FaqService extends AbstractService {
  constructor(
    private rest: RestService,
    private alertService: AlertService,
    private modalService: ModalService,
    private moduloService: ModuloService,
    private authService: AuthService
  ) {
    super();
  }

  /**
   * Retorna a quantidade total de registros acordo com o filtro.
   *
   * @param filtro
   */
  public countTotalFaq(filtro: FiltroFaqVO): Observable<any> {
    let params = new HttpParams()
      .set('idModulo', filtro.idModulo)
      .set('pergunta', filtro.pergunta)
      .set('resposta', filtro.resposta);
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      PATH + '/countFaq',
      params
    );
  }

  validaRole(roleItem: string) {
    return  this.authService.validaRole(roleItem);
   }

  /**
   * Busca lista de ações de acordo com as informações do filtro, da página atual
   * e da quantidade de itens por página.
   *
   * @param filtro
   */
  public buscarListaPorFiltro(filtro: FiltroFaqVO): Observable<any> {
    if (filtro.page != 0) {
      filtro.page = filtro.page - 1;
    }
    let params = new HttpParams()
      .set('idModulo', filtro.idModulo)
      .set('pergunta', filtro.pergunta)
      .set('resposta', filtro.resposta)
      .set('page', filtro.page)
      .set('size', filtro.size);
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      PATH + '/filtrarFaqs',
      params
    );
  }

  public buscarModuloPorId(id): Observable<any> {
    let dataObserver;
    let data = new Observable(observer => (dataObserver = observer));

    this.moduloService.buscarPorId(id).subscribe(dados => {
      if (dataObserver != null) {
        dataObserver.next(dados);
      }
    });

    return data;
  }

  public buscarPorId(id): Observable<any> {
    let params = new HttpParams().set('id', id);
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      PATH + '/buscarPorId',
      params
    );
  }


  /**
   * Realiza a chamada ao serviço de cadastro de ação na API do Portal de Segurança.
   *
   * @param faq
   */
  public incluirAlterarFaq(faq: Faq):Observable<any>  {
    faq.status=true;
    let dataObserver;
    let data = new Observable(observer => (dataObserver = observer));
    this.rest
      .gerarSolicitacao(TipoRequisicaoRestEnum.POST, PATH, null, faq)
      .subscribe(retorno => {
        faq= retorno;
        this.alertService.clear();
        this.alertService.success(mensagensPadrao['MSG03']);
        if (dataObserver != null) {
          dataObserver.next(retorno);
        }
      });
      return data;
  }

  public invertOrdemElementosLista(nodeIdFaq,overNodeIdFaq) :Observable<any> {
    let dataObserver;
    let data = new Observable(observer => (dataObserver = observer));
    let params = new HttpParams()
        .set('nodeIdFaq', nodeIdFaq)
        .set('overNodeIdFaq', overNodeIdFaq);
    this.rest.gerarSolicitacao(TipoRequisicaoRestEnum.GET, PATH +"/inverterOrdemLista", params ).subscribe(dados => {
      if (dataObserver != null) {
        dataObserver.next(dados);
      }
    });

    return data;
  }


//   :Observable<any>{
//     let dataObserver;
//     let data = new Observable(observer => (dataObserver = observer));
//     let params = new HttpParams()
//     .set('nodeIdFaq', nodeIdFaq)
//     .set('overNodeIdFaq', overNodeIdFaq);
//     this.rest
//       .gerarSolicitacao(TipoRequisicaoRestEnum.GET, PATH +"/inverterOrdemLista", params )
//       .subscribe(retorno => {
//         if (dataObserver != null) {
//           dataObserver.next(retorno);
//         }
//         this.alertService.clear();
//         this.alertService.success(mensagensPadrao['MSG03']);
//       });
// return data;
//   }

  /**
   * Realiza a chamada ao serviço de alteração de ação na API do Portal de Segurança.
   *
   * @param acao
   */
  public alterarFaq(faq: Faq) :Faq{
    this.rest
      .gerarSolicitacao(TipoRequisicaoRestEnum.PUT, PATH, null, faq)
      .subscribe(data => {
        this.alertService.clear();
        this.alertService.success(mensagensPadrao['MSG03']);
        faq=data;
      });
      return  faq;
  }
  public mensagemSucesso() {
        // this.alertService.clear();
        this.alertService.success(mensagensPadrao['MSG03']);
  }

  /**
   * Exclui a ação.
   *
   * @param idAcao
   */
  public excluirFaq(idFaq: Number): Observable<any> {
    this.alertService.clear();
    let retornoObserver;
    let retorno = new Observable(observer => (retornoObserver = observer));
    this.rest
      .gerarSolicitacao(TipoRequisicaoRestEnum.DELETE, PATH, null, idFaq)
      .subscribe(data => {
        if (data != null) {
          this.alertService.success(mensagensPadrao['MSG23']);
          if (retornoObserver != null) {
            retornoObserver.next(data);
          }
        }
      });

    return retorno;
  }
}
