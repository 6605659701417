<template-simples [escondePerfil]="true">

<section class="content">

  <form #myForm="ngForm">

    <div class="box-body">
      <div class="form-group col-md-12">
      <h3>
        Como posso ajudá-lo?
      </h3>
    </div>
      <div class="form-group col-md-8">
        <ng-select [items]="faqs" #idPergunta="ngModel" [(ngModel)]="selectedFaq" name="selectedFaq"
        bindLabel="pergunta"  [clearable]="true"
        (change)="onChangeFaq(selectedFaq)"
        placeholder="Pergunta">
      </ng-select>
      </div>
      <div class="form-group col-md-4">
        <ng-select  [items]="listaModulos" #idModulo="ngModel" [(ngModel)]="modulo" name="modulo"
          bindLabel="descricao"  [clearable]="true" highlightColor="#9575cd"
          (change)="onChangeModulo(modulo)"
          placeholder="Módulo">
        </ng-select>
      </div>

    </div>


  </form>
  <div  class="form-group col-md-12">
  <br>
  <div class="modal-footer">
    <button type="submit" class="btn btn-default float-right pull-right" (click)="limpar()">Limpar pesquisa
    </button>
  </div>
    <div class="row">
      <accordion  [closeOthers]="true" >
        <accordion-group  *ngFor="let faq of faqs" id="{{faq.idFaq}}" [isOpen]="false">
          <div accordion-heading class="mouse_change">
            <span class="pull-left glyphicon glyphicon-plus"></span>
            <span class="pull-left glyphicon glyphicon-minus"></span>
            &nbsp;&nbsp;{{faq.pergunta}}
          </div>
          <div  [innerHtml]="gethtmlProperty(faq.resposta)"> </div>
        </accordion-group>
      </accordion>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" style="margin-top: -40px;" class="btn btn" (click)="voltar()">
      Voltar <i class="fa fa-arrow-left"></i>
    </button>
  </div>
</section>

</template-simples>
<!-- <div *ngIf="faqs" [innerHtml]="faqs[0].resposta"> </div> -->
<!-- <div *ngIf="faqs" #dataContainer [innerHtml]="faqs[0].resposta"></div> -->

