import { register } from 'ts-node/dist';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { StrengthBarService } from './strength-bar.service';

@Component({
  selector: 'app-strength-bar',
  templateUrl: './strength-bar.component.html',
  styleUrls: ['./strength-bar.component.css']
})
export class StrengthBarComponent implements OnInit, OnChanges {
  @Input('barLabel') barLabel: string;
  @Input('senha') senha: string;
  @Input('forcaDaSenha') forcaDaSenha: number = 0;
  bar0: string;
  bar1: string;
  bar2: string;
  bar3: string;
  bar4: string;
  cores: string[] = ['#FFF', '#F00', '#F90', '#FF0', '#9F0', '#0F0'];

  tamanhoMaximo: number = null;
  tamanhoMinimo: number = null;
  caractersEspeciais = /[^A-Z-0-9]/i;
  letrasMinusculas = /[a-z]/;
  letrasMaiusculas = /[A-Z]/;
  numeros = /[0-9]/;

  complexidadeDaSenha = [];
  exibirParametrosSenha: boolean = false;

  constructor(public strengthBarService: StrengthBarService) {}

  ngOnInit() {
    this.carregarParametros();
  }

  ngOnChanges() {
    let idx = this.testarForca(this.senha);
    this.setBarColors(5, '#DDD');
    this.setBarColors(idx, this.cores[idx]);
    this.forcaDaSenha = idx;
  }

  testarForca(senha) {
    let forca: number = 1;

    if (senha == null || senha == '') {
      return 0;
    } else if (
      senha.length < this.tamanhoMinimo ||
      (this.tamanhoMaximo > 0 && senha.length > this.tamanhoMaximo)
    ) {
      return 1;
    }

    for (let i in this.complexidadeDaSenha) {
      if (this.complexidadeDaSenha[i].test(senha)) {
        forca++;
      }
    }
    if (forca > this.complexidadeDaSenha.length) {
      forca = 5;
    }
    return forca;
  }

  setBarColors(count, cor) {
    for (let i = 0; i < count; i++) {
      this['bar' + i] = cor;
    }
  }

  private carregarParametros() {
    this.strengthBarService.carregarParametros(
      'PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH, PASSWORD_SPECIAL, PASSWORD_LOWER_CASE, PASSWORD_UPPER_CASE, PASSWORD_NUMERIC'
    );
    StrengthBarService.parametros.subscribe(data => {
      data.forEach(par => {
        if (par.nome == 'PASSWORD_MAX_LENGTH') {
          if (par.valor > 0) {
            this.exibirParametrosSenha = true;
          }
          this.tamanhoMaximo = par.valor;
        }
        if (par.nome == 'PASSWORD_MIN_LENGTH') {
          if (par.valor > 0) {
            this.exibirParametrosSenha = true;
          }
          this.tamanhoMinimo = par.valor;
        }
        if (par.nome == 'PASSWORD_SPECIAL') {
          if (par.valor == true) {
            this.exibirParametrosSenha = true;
            this.complexidadeDaSenha.push(this.caractersEspeciais);
          }
          this.caractersEspeciais = par.valor;
        }
        if (par.nome == 'PASSWORD_LOWER_CASE') {
          if (par.valor == true) {
            this.exibirParametrosSenha = true;
            this.complexidadeDaSenha.push(this.letrasMinusculas);
          }
          this.letrasMinusculas = par.valor;
        }
        if (par.nome == 'PASSWORD_UPPER_CASE') {
          if (par.valor == true) {
            this.exibirParametrosSenha = true;
            this.complexidadeDaSenha.push(this.letrasMaiusculas);
          }
          this.letrasMaiusculas = par.valor;
        }
        if (par.nome == 'PASSWORD_NUMERIC') {
          if (par.valor == true) {
            this.exibirParametrosSenha = true;
            this.complexidadeDaSenha.push(this.numeros);
          }
          this.numeros = par.valor;
        }
      });
    });
  }
}
