<a (click)="showDialog()" role="button" style="margin-left: 15px;font-size: 13px; font-weight: bold; color: #474747;">Trocar senha</a>

<ng-container #vc></ng-container>
  <ng-template #modal  >
    <div class="modal fade" id="user" tabindex="-1" role="dialog" aria-labelledby="myModal" >
      <div class="modal-dialog modal-lg modal-custom" role="document">
        <div class="modal-content">
          <div class="modal-header">
                    <div  style="margin-bottom: 20px;">
                      <div style="display: flex; align-items: center;">
                        <span style="border-left: 3px solid gray; margin-right: 5px; height: 20px; display: inline-block;"></span>
                        <alert></alert>
                        <span style="color: gray; margin-right: 5px; font-weight: bold;">Alterar Senha</span>

                        <hr style="flex-grow: 1; border-width: 2px;">
                      </div>
                    </div>
                  </div>
                  <div class="modal-body" style="margin-top: -20px;" >

                      <form [formGroup]="formUser" style="margin-left: 10px; background-color: #DCDDDF; border-radius: 10px 10px 10px 10px; border: 1px; border-color: #707070;">
                        <div class="box-body">
                          <div class="form-group">

                            <div class="row" style="margin-top: 30px;margin-left: 18px;margin-right: 18px;">
                              <div class="form-group col-md-6">
                                <label for="cpf">Senha Atual: <span style="color: red;">*</span></label>

                                <div class="input-group">
                                  <span class="input-group-text" id="basic-addon1">
                                      <i class="bi bi-credit-card"></i>
                                  </span>

                                  <input type="password" class="form-control"  placeholder="senhaAtual" style="font-size: 14px; background-color: #E9EAEB; border-top-right-radius: 5px; border-bottom-right-radius: 5px;"
                                           formControlName="senhaAtual" >
                                </div>

                              </div>


                            </div>




                            <div class="row" style="margin-top: 20px; margin-left: 18px;margin-right: 18px;">

                              <div class="form-group col-md-6">
                                <label for="cpf">Nova Senha: <span style="color: red;">*</span></label>

                                <div class="input-group">
                                  <span class="input-group-text" id="basic-addon2">
                                    <i class="bi bi-credit-card"></i>
                                  </span>
                                  <input type="password" class="form-control" placeholder="novaSenha" maxlength="100" style="font-size: 14px; background-color: #E9EAEB; border-top-right-radius: 5px; border-bottom-right-radius: 5px;"
                                   formControlName="novaSenha">
                                </div>

<!--
                                <div class="alert-input alert-danger" *ngIf="formUsuario.get('novaSenha').hasError('required') && (formUsuario.get('novaSenha').dirty || formUsuario.get('novaSenha').touched)">{{ formatador.formataMensagem('MSG22', ['novaSenha']) }}</div>
 -->
                              </div>

                              <div class="form-group col-md-6">
                                <label for="cpf">Confirme a nova senha: <span style="color: red;">*</span></label>

                                <div class="input-group">
                                  <span class="input-group-text" id="basic-addon2">
                                    <i class="bi bi-credit-card"></i>
                                  </span>
                                  <input type="password" class="form-control" maxlength="100"  placeholder="confirmeSenha" style="font-size: 14px; background-color: #E9EAEB; border-top-right-radius: 5px; border-bottom-right-radius: 5px;"
                                  formControlName="confirmeSenha">
                                </div>
                              </div>

                              <div class="form-group">

                                <app-strength-bar [senha]="formUser.get('novaSenha').value" [forcaDaSenha]="forcaDaSenha" class="strength" #campo></app-strength-bar>
                              </div>




                            </div>



                        <div class="row" style="margin-top: 20px; margin-left: 18px;margin-right: 18px;">
                          <div class="form-group">
                            <re-captcha name="captcha" required (resolved)="onCaptchaComplete($event)"  [siteKey]="recaptchaSiteKey"></re-captcha>
                          </div>
                        </div>


                          </div>


                        </div>

                        <div class="row" style="margin-top: 50px; margin-left: 18px;margin-right: 18px; margin-bottom: 20px;">

                          <div class="form-group col-md-4">
                          <div class="input-group">
                            <button class="btn" style="margin-left: 8px;background-color: #3F3F40; width: 80%;" (click)="closeDialog()">
                              <span style="color: white; font-weight: bold;"> <i class="bi bi-arrow-left-circle-fill"></i> Voltar</span>
                            </button>
                          </div>
                          </div>

                          <div class="form-group col-md-8">
                          <div class="input-group">
                            <button type="button" class="btn" (click)="gravarTrocaDeSenha()" [disabled]="(!formUser.valid || !captchaSuccess)" style="background-color: #54585E; width: 98%;" >
                              <span style="color: white; font-weight: bold;"> <i class="bi bi-floppy"></i> Gravar alteração</span>
                            </button>
                          </div>
                          </div>

                        </div>

      </form>


                  </div>


              </div>
          </div>
      </div>
  </ng-template>
