import { Component, Input, OnInit } from '@angular/core';
import { Usuario } from '../../models/usuario';
import { EstruturaOrganizacional } from '../../models/estrutura-organizacional';
import { UsuarioInternoService } from '../../services/manter-usuario/usuario-interno.service';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'estrutura-organizacional',
  templateUrl: './estrutura-organizacional.component.html',
  styleUrls: ['./estrutura-organizacional.component.css'],
  animations: [
    trigger('expandCollapse', [
      state('collapsed', style({ height: '0px', overflow: 'hidden', opacity: 0 })),
      state('expanded', style({ height: '*', overflow: 'hidden', opacity: 1 })),
      transition('collapsed <=> expanded', [
        animate('300ms ease-in-out')
      ])
    ])
  ]
})
export class EstruturaOrganizacionalComponent implements OnInit {
  @Input() usuarioForm: Usuario;

  nodes: EstruturaOrganizacional[] = [];
  listaEstruturaOrganizacional: EstruturaOrganizacional[] = [];
  setorExtenso: String = '';
  selectedNodeId: number | null = null;

  constructor(private usuarioService: UsuarioInternoService) {}

  ngOnInit() {
    this.carregarListas();
  }

  carregarListas() {
    this.usuarioService.buscarEstruturaOrganizacional().subscribe(listaEstruturaOrganizacional => {
      this.listaEstruturaOrganizacional = listaEstruturaOrganizacional;
      this.preencherDadosIniciais();
      this.labelSetorExtenso();
    });
  }

  preencherDadosIniciais() {
    this.nodes = this.listaEstruturaOrganizacional.filter(node => node.idEstruturaOrganizacionalPai === null);
    this.nodes.forEach(node => {
      this.addChildren(node);
      node.isExpanded = false;
    });
  }

  addChildren(node: EstruturaOrganizacional) {
    node.children = this.listaEstruturaOrganizacional.filter(child => child.idEstruturaOrganizacionalPai === node.idEstruturaOrganizacional);
    node.children.forEach(child => {
      this.addChildren(child);
      child.isExpanded = false;
    });
  }

  toggleExpand(node: EstruturaOrganizacional) {
    node.isExpanded = !node.isExpanded;
  }

  selectNode(node: EstruturaOrganizacional) {
    this.selectedNodeId = node.idEstruturaOrganizacional;
    this.usuarioForm.idItemOrganograma = this.selectedNodeId;
    this.setorExtenso = node.descricao;
  }

  isSelected(node: EstruturaOrganizacional): boolean {
    return node.idEstruturaOrganizacional === this.selectedNodeId;
  }

  labelSetorExtenso() {
    const selectedNode = this.listaEstruturaOrganizacional.find(node => node.idEstruturaOrganizacional === this.usuarioForm.idItemOrganograma);
    this.setorExtenso = selectedNode ? selectedNode.descricao : '';
  }
}
