<div class="form-group col-md-12">
  <div class="row">
    <div class="accordion" id="accordionUnidadesSecundarias">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingUnidadesSecundarias">
          <button class="accordion-button" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseUnidadesSecundarias" aria-expanded="true"
            aria-controls="collapseUnidadesSecundarias">
            Unidades secundária: {{ setorExtenso || 'Não selecionado'}}
          </button>
        </h2>
        <div id="collapseUnidadesSecundarias" class="accordion-collapse collapse show"
          aria-labelledby="headingUnidadesSecundarias" data-bs-parent="#accordionUnidadesSecundaria">
          <div class="accordion-body">
            <ul class="list-group">
              <ng-container *ngFor="let node of nodes">
                <li class="list-group-item">
                  <input class="me-2" type="checkbox"
                    [checked]="usuarioForm.listaSetorSecundarioSelecionado.includes(node.idEstruturaOrganizacional)"
                    (change)="toggleSelection(node)" />
                  <span *ngIf="node.children?.length > 0" (click)="toggleExpand(node)" style="cursor: pointer;"
                    class="me-4">
                    <i [ngClass]="{'bi bi-arrow-right': !node.isExpanded, 'bi bi-arrow-down': node.isExpanded}"></i>
                  </span>
                  {{ node.descricao }}
                  <ul *ngIf="node.children?.length > 0" [@expandCollapse]="node.isExpanded ? 'expanded' : 'collapsed'"
                    class="list-group ms-3">
                    <ng-container *ngFor="let child of node.children">
                      <li class="list-group-item">
                        <input class="me-2" type="checkbox"
                          [checked]="usuarioForm.listaSetorSecundarioSelecionado.includes(child.idEstruturaOrganizacional)"
                          (change)="toggleSelection(child)" />
                        <span *ngIf="child.children?.length > 0" (click)="toggleExpand(child)" style="cursor: pointer;"
                          class="me-4">
                          <i
                            [ngClass]="{'bi bi-arrow-right': !child.isExpanded, 'bi bi-arrow-down': child.isExpanded}"></i>
                        </span>
                        {{ child.descricao }}
                        <ul *ngIf="child.children?.length > 0"
                          [@expandCollapse]="child.isExpanded ? 'expanded' : 'collapsed'" class="list-group ms-3">
                          <ng-container *ngFor="let grandchild of child.children">
                            <li class="list-group-item">
                              <input class="me-2" type="checkbox"
                                [checked]="usuarioForm.listaSetorSecundarioSelecionado.includes(grandchild.idEstruturaOrganizacional)"
                                (change)="toggleSelection(grandchild)" />
                              <span *ngIf="grandchild.children?.length > 0" (click)="toggleExpand(grandchild)"
                                style="cursor: pointer;" class="me-4">
                                <i
                                  [ngClass]="{'bi bi-arrow-right': !grandchild.isExpanded, 'bi bi-arrow-down': grandchild.isExpanded}"></i>
                              </span>
                              {{ grandchild.descricao }}
                              <ul *ngIf="grandchild.children?.length > 0"
                                [@expandCollapse]="grandchild.isExpanded ? 'expanded' : 'collapsed'"
                                class="list-group ms-3">
                                <ng-container *ngFor="let greatGrandchild of grandchild.children">
                                  <li class="list-group-item">
                                    <input class="me-2" type="checkbox"
                                      [checked]="usuarioForm.listaSetorSecundarioSelecionado.includes(greatGrandchild.idEstruturaOrganizacional)"
                                      (change)="toggleSelection(greatGrandchild)" />
                                    <span *ngIf="greatGrandchild.children?.length > 0"
                                      (click)="toggleExpand(greatGrandchild)" style="cursor: pointer;" class="me-4">
                                      <i
                                        [ngClass]="{'bi bi-arrow-right': !greatGrandchild.isExpanded, 'bi bi-arrow-down': greatGrandchild.isExpanded}"></i>
                                    </span>
                                    {{ greatGrandchild.descricao }}
                                    <ul *ngIf="greatGrandchild.children?.length > 0"
                                      [@expandCollapse]="greatGrandchild.isExpanded ? 'expanded' : 'collapsed'"
                                      class="list-group ms-3">
                                      <ng-container
                                        *ngFor="let greatGreatGrandchild of greatGrandchild.children">
                                        <li class="list-group-item">
                                          <input class="me-2" type="checkbox"
                                            [checked]="usuarioForm.listaSetorSecundarioSelecionado.includes(greatGreatGrandchild.idEstruturaOrganizacional)"
                                            (change)="toggleSelection(greatGreatGrandchild)" />
                                          <span *ngIf="greatGreatGrandchild.children?.length > 0"
                                            (click)="toggleExpand(greatGreatGrandchild)" style="cursor: pointer;" class="me-4">
                                            <i
                                              [ngClass]="{'bi bi-arrow-right': !greatGreatGrandchild.isExpanded, 'bi bi-arrow-down': greatGreatGrandchild.isExpanded}"></i>
                                          </span>
                                          {{ greatGreatGrandchild.descricao }}
                                          <ul *ngIf="greatGreatGrandchild.children?.length > 0"
                                            [@expandCollapse]="greatGreatGrandchild.isExpanded ? 'expanded' : 'collapsed'"
                                            class="list-group ms-3">
                                            <ng-container
                                              *ngFor="let greatGreatGreatGrandchild of greatGreatGrandchild.children">
                                              <li class="list-group-item">
                                                <input class="me-2" type="checkbox"
                                                  [checked]="usuarioForm.listaSetorSecundarioSelecionado.includes(greatGreatGreatGrandchild.idEstruturaOrganizacional)"
                                                  (change)="toggleSelection(greatGreatGreatGrandchild)" />
                                                <span *ngIf="greatGreatGreatGrandchild.children?.length > 0"
                                                  (click)="toggleExpand(greatGreatGreatGrandchild)"
                                                  style="cursor: pointer;" class="me-4">
                                                  <i
                                                    [ngClass]="{'bi bi-arrow-right': !greatGreatGreatGrandchild.isExpanded, 'bi bi-arrow-down': greatGreatGreatGrandchild.isExpanded}"></i>
                                                </span>
                                                {{ greatGreatGreatGrandchild.descricao }}
                                              </li>
                                            </ng-container>
                                          </ul>
                                        </li>
                                      </ng-container>
                                    </ul>
                                  </li>
                                </ng-container>
                              </ul>
                            </li>
                          </ng-container>
                        </ul>
                      </li>
                    </ng-container>
                  </ul>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
