<ng-template #template>
  <!-- Modal Header -->
  <div class="modal-header">
    <h5 class="modal-title">{{ titulo }}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="fecharModal()"></button>
  </div>

  <!-- Alerts -->
  <div *ngFor="let alert of alerts" class="alert alert-danger alert-dismissible fade show d-flex justify-content-between align-items-center" role="alert" style="margin: 1%">
    {{ alert.message }}
    <button type="button" class="btn-close" aria-label="Close" (click)="removeAlert(alert)"></button>
  </div>
  

  <!-- Modal Body -->
  <div class="modal-body">
    <ng-content></ng-content>
  </div>
</ng-template>

<ng-template #templateDialog>
  <!-- Modal Body for Dialog -->
  <div class="modal-body text-center">
    <h5>{{ msgDialog }}</h5>
  </div>

  <!-- Modal Footer with Buttons -->
  <div class="modal-footer d-flex justify-content-between" *ngIf="!exibeBotaoOk">
    <button type="button" class="btn btn-secondary" (click)="buttonNaoDialogModal()">Não</button>
    <button type="button" class="btn btn-primary" (click)="buttonSimDialogModal()">Sim</button>
  </div>
  <div class="modal-footer justify-content-center" *ngIf="exibeBotaoOk">
    <button type="button" class="btn btn-primary" (click)="buttonSimDialogModal()">Ok</button>
  </div>
</ng-template>
