import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pseg-index',
  templateUrl: './pseg-index.component.html',
  styleUrls: ['./pseg-index.component.css']
})
export class PsegIndexComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
