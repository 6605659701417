<body class="hold-transition skin-black-light sidebar-collapse sidebar-mini">

  <div class="wrapper" >

    <header class="main-header">
      <nav class="navbar navbar-expand-lg" >
        <div class="container-fluid">
          <a (click)="clickVoltarHome()" class="navbar-brand d-flex align-items-center text-decoration-none">
            <img src="barra.png" class="size-logo" alt="Logo">
            <span class="ms-2 nav-subtitle">Portal de Serviços do Sisema</span>
          </a>

          <div *ngIf=usuarioAutenticado()>

          <!-- Botão de colapso para dispositivos móveis -->
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent" >
            <ul class="navbar-nav ms-auto" >
              <li class="nav-item dropdown user user-menu" >
                <a class="nav-link dropdown-toggle" href="#" id="userMenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <img src="assets/icon/person.png" class="rounded-circle user-image" alt="User Image" width="30">
                  <span class="d-none d-sm-inline">{{ primeiroNome() }}</span>
                </a>

                <!--Menu de troca de senha-->
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="userMenu"  style="border: 3px solid #828492; border-radius: 15px 15px 15px 15px; width: 300px; height: 200px; background-color: rgba(220,221,223, 0.4);">
                  <li style="display: flex; align-items: center; margin-top: 20px;">
                    <img src="assets/icon/user-solid-modificado.svg" style="margin-left: 18px; background: #474747 0% 0% no-repeat padding-box; margin-right: 15px; width: 60px; height: 60px;" class="rounded-circle img-thumbnail float-start">

                    <span style="font-size: 20px; font-weight: bold; color: #474747;">
                      {{ primeiroNome() }}
                    </span>
                  </li>

                  <div class="d-flex justify-content-between align-items-center mb-4" style="margin-top: 20px;">
                    <app-modal-alterar-senha></app-modal-alterar-senha>
                    <app-modal-alterar-usuario ></app-modal-alterar-usuario>
                  </div>

                  <a (click)="fechar()" class="btn btn-default btn-flat" style="width: 98%; background-color: rgba(220,221,223, 0.4); border:3px solid #828492; color: #474747; font-size: 15px; font-weight: bold; position: absolute; bottom: 10px; left: 50%; transform: translateX(-50%);">
                    fechar
                  </a>
                </ul>


              </li>
            </ul>
          </div>

        </div>

        </div>
      </nav>
    </header>


    <aside class="main-sidebar">
      <section class="sidebar" style="margin-top: 30px;">
        <ul class="sidebar-menu" data-widget="tree">

          <li>
            <a href="#" routerLinkActive="active" style="margin-left: -8px;" class="sidebar-toggle text-decoration-none" data-toggle="push-menu" role="button">
              <i class="bi bi-list fs-2"></i>
            </a>
          </li>

          <li>
            <a (click)="clickVoltarHome()" routerLinkActive="active" class="sidebar-toggle text-decoration-none" data-toggle="push-menu" role="button">
              <img src="assets/icon/logoIcon2.svg" style="width: 20px; height: 20px; margin-right: 2px;">
              <span style="display: inline-block;">Página inicial</span>
            </a>
          </li>

          <seguranca-item [group]="group.ITEM_MENU_MODULO">
            <li validar>
              <a (click)="clickModulo()" role="button" routerLinkActive="active">
                <i class="fa fa-clipboard"></i>
                <span >Módulos</span>
              </a>
            </li>
          </seguranca-item>
          <seguranca-item [group]="group.ITEM_MENU_USUARIO">
            <li validar>
              <a (click)="clickListarUsuario()" role="button" routerLinkActive="active" >
                <i class="fa fa-users"></i>
                <span >Usuários</span>
              </a>
            </li>
          </seguranca-item>
        </ul>
      </section>
    </aside>

    <div class="control-sidebar-bg"></div>

    <div class="content-wrapper">
      <alert></alert>
      <ng-content></ng-content>
      <div class="overlay" *ngIf="loading">
        <i class="fa fa-refresh fa-spin"></i>
      </div>
    </div>

    <footer class="main-footer">
      <div class="container" style="text-align: center; color: #828492">
        <strong>Portal de Serviços do Sisema - Versão: {{versao}}</strong>
      </div>
    </footer>
  </div>

  <div class='scrolltop'>
    <div class='scroll icon' onclick="toTop()">
      <i class="fa fa-4x fa-angle-up"></i>
    </div>
  </div>

</body>
