import { NotificacaoService } from './../../services/notificacao/notificacao.service';
import { AlertService } from './../../componentes/alert/alert.service';
import { Notificacao } from './../../models/vo/notificacao-vo';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'app-modal-notificacao',
  templateUrl: './modal-notificacao.component.html',
  styleUrl: './modal-notificacao.component.css'
})
export class ModalNotificacaoComponent implements OnInit{

  @Output() chamadaModal = new EventEmitter();
  @Input() notificacao: Notificacao;
  @ViewChild('modal', {static: false}) modal: TemplateRef<any>;
  @ViewChild('vc', {read: ViewContainerRef, static: false}) vc: ViewContainerRef;
  backdrop: any;

  constructor(private alertService: AlertService, private notificacaoService: NotificacaoService) {
  }


  ngOnInit() {

  }

  abrirSistema(){

  }

  private marcarComoLida() {
    if(this.notificacao.status === 'Pendente' || this.notificacao.data_visualizacao === null){
      this.notificacaoService.marcarComoLido(this.notificacao.id).subscribe();
    }
  }

  showDialog() {
    this.marcarComoLida();
    // Insere o template no container
    const view = this.modal.createEmbeddedView(null);
    this.vc.insert(view);

    // Obtém o modal pelo ID diretamente após a inserção no DOM
    const modalElement = document.getElementById('id_notificacao');  // Assume-se que o ID do modal é 'usuarios'

    // Mover o modal para o body
    document.body.appendChild(modalElement);

    // Modificar estilos para exibir o modal corretamente
    modalElement.classList.remove('fade');  // Remove a animação fade
    modalElement.classList.add('modal-open');  // Adiciona a classe modal-open para exibir corretamente
    modalElement.classList.add('show');  // Adiciona a classe show para exibição
    modalElement.style.display = 'block';  // Garante que o modal apareça

    // Criar o backdrop manualmente
    this.backdrop = document.createElement('DIV');
    this.backdrop.className = 'modal-backdrop show';  // Classe necessária para o backdrop
    document.body.appendChild(this.backdrop);
  }

  closeDialog() {
    if(this.notificacao.status === 'Pendente' || this.notificacao.data_visualizacao === null){
      this.chamadaModal.emit('notificacaoFechou');
    }
    // Limpa o conteúdo dinâmico do ViewContainerRef
    this.vc.clear();

    // Verifica se o backdrop existe antes de tentar removê-lo
    if (this.backdrop && document.body.contains(this.backdrop)) {
      document.body.removeChild(this.backdrop);
    }

    // Obtém o modal pelo ID para garantir que ele está anexado ao body
    const modalElement = document.getElementById('id_notificacao');

    // Verifica se o modal existe no body antes de removê-lo
    if (modalElement && document.body.contains(modalElement)) {
      document.body.removeChild(modalElement);
    }

  }
}
