<template-menu>

  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="error-template">
          <h1>Bem vindo!</h1>
          <h3>Portal de Segurança - SEMAD</h3>
        </div>
      </div>
    </div>
  </div>

</template-menu>