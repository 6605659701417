import { Component, Input, OnInit } from '@angular/core';
// import { group } from '@angular/core/src/animation/dsl';
import { group } from '@angular/animations';

import { AuthService } from './../../services/auth-service';

@Component({
  selector: 'seguranca-item',
  template: `<ng-content select="[validar]" *ngIf="showItem"></ng-content>`,
  styleUrls: ['./seguranca-item.component.css']
})
export class SegurancaItemComponent implements OnInit {
  showItem: boolean = false;

  /**
   * Recebe uma role para ser validada.
   */
  @Input()
  public set role(roleItem: string) {
    this.validaRole(roleItem);
  }

  /**
   * Recebe um array de roles para serem validados.
   */
  @Input()
  public set group(groupItem: string[]) {
    this.validaGroup(groupItem);
  }

  constructor(private authService: AuthService) {}

  ngOnInit() {}

  validaRole(roleItem: string) {
    this.showItem = this.authService.validaRole(roleItem);
  }

  validaGroup(groupItem: string[]) {
    this.showItem = this.authService.validaGroupRole(groupItem);
  }
}
