import { AssuntoFaleConosco } from './assunto-fale-conosco';
import { Modulo } from './modulo';
import { FileItem } from 'ng2-file-upload';

export class FaleConosco {
  idFaleConosco: any;
  modulo: Modulo;
  assuntoFaleConosco: AssuntoFaleConosco;
  numeroProtocolo: string;
  remetente: string;
  textoMensagem: string;
  dataHoraCriacao: Date;
}
