
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { error } from 'util';

import { AuthService } from './../auth-service';

@Injectable()
export class HttpAuthResponseInterceptor implements HttpInterceptor {
  constructor(public authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.recuperarHeader(event);
        }
        return event;
      }),
      catchError(this.handleError),);
  }
  public handleError = (error: Response) => {
    this.recuperarHeader(error);
    return observableThrowError(error);
  }

  private recuperarHeader(event: any) {
    let header = event.headers;
    if (
      header.has('Authorization') &&
      header.get('Authorization') != undefined
    ) {
      this.authService.putToken(header.get('Authorization'));
    }
  }
}
