export class FiltroFaqVO {
  constructor() {
    this.idModulo = 0;
    this.idFaq =0;
    this.pergunta = '';
    this.resposta = '';
    this.page = 0;
    this.size = 0;
  }

  public idModulo;
  public pergunta;
  public resposta;
  public page;
  public idFaq;
  public size;
}
