import { AfterViewChecked } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'paginador',
  templateUrl: './paginador.component.html',
  styleUrls: ['./paginador.component.css']
})
export class PaginadorComponent implements OnInit, OnChanges {
  public static readonly TOTAL_PAGS_PADRAO: number = 20;
  public static readonly PAG_PADRAO: number = 1;
  public static readonly REG_PADRAO: number = 0;
  public static readonly ADJACENTES_PADRAO: number = 10;

  @Input() public qtdPorPagina: number;
  @Input() public totalRegistros: number;
  @Input() public qtdAdjacentes: number;
  @Output()
  public onPaginate: EventEmitter<number> = new EventEmitter<number>();

  pagina: number;
  paginas: Array<number>;
  exibirProximo: boolean;
  qtdPaginas: number;
  carregado: number = 0;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {}

  /**
   * Gera os links de paginação.
   */
  gerarLinks() {
    this.exibirProximo = this.qtdPaginas !== this.pagina;
    this.paginas = [];
    let iniAdjacente =
      this.pagina - this.qtdAdjacentes <= 0
        ? 1
        : this.pagina - this.qtdAdjacentes;

    let fimAdjacente =
      this.pagina + this.qtdAdjacentes >= this.qtdPaginas
        ? this.qtdPaginas
        : this.pagina + this.qtdAdjacentes;

    for (let i = iniAdjacente; i <= fimAdjacente; i++) {
      this.paginas.push(i);
    }
  }

  /**
   * Método responsável por chamar o Emitter de
   * paginação.
   *
   * @param number pagina
   * @param any $event número da página a ser exibida.
   */
  paginar(pagina: number, $event: any) {
    $event.preventDefault();
    this.pagina = pagina;
    this.gerarLinks();
    this.onPaginate.emit(pagina);
  }

  totalPages(): number {
    return Math.ceil(this.totalRegistros / this.qtdPorPagina) || 0;
  }

  lastPage(): boolean {
    return this.pagina >= this.qtdPaginas;
  }

  firtPage(): boolean {
    return this.pagina <= 1;
  }

  rangeRegistrosInicial(): number {
    let retorno = this.qtdPorPagina * this.pagina - this.qtdPorPagina;
    if (retorno >= 0) {
      retorno = retorno + 1;
    }
    return retorno;
  }

  rangeRegistrosFinal(): number {
    let retorno = 0;
    if (this.totalRegistros > 0) {
      retorno = this.qtdPorPagina * this.pagina;
      if (retorno >= this.totalRegistros) {
        retorno = this.totalRegistros;
      }
    }
    return retorno;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.totalRegistros) {
      this.carregado = 1;
      this.qtdAdjacentes =
        this.qtdAdjacentes || PaginadorComponent.ADJACENTES_PADRAO;
      this.qtdPorPagina =
        this.qtdPorPagina || PaginadorComponent.TOTAL_PAGS_PADRAO;
      this.pagina =
        +this.route.snapshot.queryParams.page || PaginadorComponent.PAG_PADRAO;
      this.totalRegistros =
        this.totalRegistros || PaginadorComponent.REG_PADRAO;
      this.qtdPaginas = Math.ceil(this.totalRegistros / this.qtdPorPagina);
      this.gerarLinks();
    }
  }
}
