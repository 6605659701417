import { UsuarioVO } from '../../models/vo/usuario-vo';

import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AlertService } from '../../componentes/alert/alert.service';
import { FiltroUsuarioVO } from '../../models/vo/filtro-usuario-vo';
import { AbstractService } from '../abstract.service';
import { RestService } from '../rest-service';
import { TipoRequisicaoRestEnum } from './../../models/tipo-requisicao-rest.enum';
import { Usuario } from './../../models/usuario';
import { mensagensPadrao } from './../../util/mensagens-padrao';
import { Parametro } from '../../models/parametro';
import { ErroService } from '../erro/erro.service';
import { catchError, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class UsuarioExternoService extends AbstractService {
  static parametros = new EventEmitter<Parametro>();

  path: string = '/usuarioExterno';
  private readonly API = `${environment.URL_PORTAL_SEGURANCA}`;

  usuario: Usuario;

  constructor(
    private rest: RestService, private erroService: ErroService,
    private alertService: AlertService,private http: HttpClient,
    public router: Router
  ) {
    super();
    this.usuario = new Usuario();
  }

  /**
   * Realiza a chamada ao serviço que grava usuário na API do Portal de Segurança.
   *
   * @param usuario
   */
  public gravarUsuario(usuario: Usuario) {
    console.log(usuario);
    this.http.post(this.API+this.path +"/salvar", usuario).pipe(take(1)).subscribe(data => {
     if (data != null) {
       this.alertService.success(mensagensPadrao['MSG03']);
       this.router.navigate(['/confirmacao']);
     }
   },err =>  {
     this.alertService.error(err.error.message);
   });
 }

  /**
   * Realiza a chamada ao serviço de alteração de usuário na API do Portal de Segurança.
   *
   * @param usuario
   */
  public alterarUsuario(usuario: Usuario) {
    usuario['perfis'] = [];
    this.rest
      .gerarSolicitacao(TipoRequisicaoRestEnum.PUT, this.path, null, usuario)
      .pipe(catchError(this.erroService.handleError))
      .subscribe(data => {
        if (data != null) {
          this.alertService.success(mensagensPadrao['MSG03']);
        }
      },(err) => {
        this.alertService.errorModal(err.error.message);
        console.log(err.error.message);
      });
  }

  /**
   * Realiza a consulta do usuário logado.
   */
  public buscarUsuarioLogado(): Observable<any> {
    let params = new HttpParams();
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      this.path + '/buscarUsuarioLogado',
      params
    );
  }

  /**
   * Valida a conta do usuário após realizar o cadastro e clicar no link enviado por e-mail.
   *
   * @param id
   * @param hash
   */
  public validarConta(id, hash) {
    let params = new HttpParams();
    params = params.append('id', id);
    params = params.append('hash', hash);
    this.rest
      .gerarSolicitacao(
        TipoRequisicaoRestEnum.GET,
        this.path + '/validarConta',
        params
      )
      .subscribe(data => {
        this.router.navigate(['/confirmacao-email']);
      });
  }

  /**
   * Realiza uma consulta do nome do usuário atravéz do CPF passado.
   * Utilizado quando o parâmetro de integração com a SEF estiver habilitado.
   *
   * @param cpf
   */
  public buscarNomePeloCpf(cpf): Observable<UsuarioVO> {
    let params = new HttpParams().set('cpf', cpf);
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      this.path + '/buscarNomePeloCpf',
      params
    );
  }

  /**
   * Realiza a busca dos parâmetros necessários.
   *
   * @param parametros
   */
  public carregarParametros(parametros: string) {
    let params = new HttpParams().set('parametros', parametros);
    this.rest
      .gerarSolicitacao(TipoRequisicaoRestEnum.GET, this.path + '/listarParametros', params)
      .subscribe(data =>
        UsuarioExternoService.parametros.emit(JSON.parse(JSON.stringify(data)))
      );
  }

}
