
import {finalize, tap} from 'rxjs/operators';






import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpIndicatorService } from './http-indicator.service';
@Injectable()
export class HttpIndicatorInterceptor implements HttpInterceptor {
  constructor(private httpIndicatorService: HttpIndicatorService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // emit onStarted event before request execution
    this.httpIndicatorService.onStarted(req);
    return (
      next
        .handle(req).pipe(tap((event: HttpEvent<any>) => {
        }),finalize(() => this.httpIndicatorService.onFinished(req)),)
    );
  }
}
