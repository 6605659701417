import { Component, OnInit, ViewChild, Input, Pipe } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FileUploader, FileLikeObject, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';

import { AlertService } from '../componentes/alert/alert.service';
import { MaskConstants } from './../util/mask-constants';
import {
  FormatadorDeMensagens,
  mensagensCdu
} from './../util/mensagens-padrao';
import { EnvironmentService } from './../services/environment/environment.service';
import { FaleConoscoService } from './../services/fale-conosco/fale-conosco.service';
import { ModuloService } from './../services/manter-modulo/modulo.service';
import { FaleConosco } from './../models/fale-conosco';
import { Modulo } from './../models/modulo';
import { AssuntoFaleConosco } from './../models/assunto-fale-conosco';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

const URL = '/faleConosco/enviarArquivosFaleConosco';

@Component({
  selector: 'app-fale-conosco',
  templateUrl:
    '../paginas/privado/fale-conosco/fale-conosco.component.html',
  styleUrls: [
    '../paginas/privado/fale-conosco/css/fale-conosco.component.css'
  ]
})

export class FaleConoscoComponent implements OnInit {

  public uploader: FileUploader = new FileUploader({ url: URL });
  
  public listaModulo: Modulo[] = [];
  public listaAssuntoFaleConosco: AssuntoFaleConosco[] = [];
  recaptchaSiteKey = this.environmentService.env.RECAPTCHA_SITE_KEY;
  captchaSuccess: boolean = false;

  public modulo: Modulo;
  public urlFrameFaleConosco : SafeResourceUrl;
  public assuntoFaleConosco: AssuntoFaleConosco;

  public mensagemFaleConosco;

  private allowedMimeType = [];
  private maxFileSize = 150 * 1024 * 1024;
  private extensoes = ['.jpg', '.png', '.jpeg', '.bmp', '.pdf', '.shp', '.dbf', '.shx', '.prj', '.gpkg'];
  public hasBaseDropZoneOver: boolean = false;
  public idFaleConosco: number;


  public faleConosco: FaleConosco;
  @ViewChild('myForm') public form;

  @Input()
  public set value(faleConosco) {
    this.faleConosco = new FaleConosco();
    if (faleConosco != undefined) {
      this.faleConosco.modulo.idModulo = faleConosco.modulo.idModulo;
      this.faleConosco.assuntoFaleConosco.idAssuntoFaleConosco = faleConosco.assuntoFaleConosco.idAssuntoFaleConosco;
      this.faleConosco.textoMensagem = faleConosco.textoMensagem;
      this.faleConosco.numeroProtocolo = faleConosco.numeroProtocolo;
    }
  }

  @Input()
  public enviado: boolean = false;

  constructor(
    private faleConoscoService: FaleConoscoService,
    public moduloService: ModuloService,
    private alertService: AlertService,
    public formatador: FormatadorDeMensagens,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private environmentService: EnvironmentService,
    public alert: AlertService,
    public sanitizer:DomSanitizer
  ) {
    this.faleConosco = new FaleConosco();
    this.modulo = new Modulo();
    this.faleConosco.modulo = this.modulo;
    this.faleConosco.modulo.idModulo = this.modulo.idModulo;
    this.assuntoFaleConosco = new AssuntoFaleConosco();
    this.faleConosco.assuntoFaleConosco = this.assuntoFaleConosco;
    this.faleConosco.assuntoFaleConosco.idAssuntoFaleConosco = this.assuntoFaleConosco.idAssuntoFaleConosco;

  }

  ngOnInit() {
    this.mensagemFaleConosco = mensagensCdu.PSEG_FALE_CONOSCO;
    this.carregarListaModulo();
    this.carregaUrlFrameFaleComNosco();
    this.iniciarUploader();
  }

  private carregaUrlFrameFaleComNosco(){
    this.urlFrameFaleConosco =  this.sanitizer.bypassSecurityTrustResourceUrl(this.environmentService.env.FRAME_FALE_CONOSCO_URL);
  }

  /**
   * Carrega a lista de módulos.
   */
  private carregarListaModulo() {
    this.faleConoscoService.buscarListaModulo()
      .subscribe(retorno => {
        this.listaModulo = retorno;
      });
  }

  /**
   * Preenche o combo box Assunto com os assuntos fale conosco associados ao módulo selecionado.
   */
  onChangeModulo(modulo: any) {
    if (modulo.idModulo != undefined) {
      this.faleConoscoService.buscarListaAssuntoFaleConoscoPorIdModulo(modulo.idModulo)
        .subscribe(retorno => {
          this.listaAssuntoFaleConosco = retorno;
        });
    } else {
      modulo.idModulo = null;
    }
  }

  /**
   * Verifica a utilização do componente de captcha
   * @param response
   */
  private onCaptchaComplete(response: any) {
    this.captchaSuccess = response!=undefined && response!=null && response.length>0;
  }

  /**
   * Envia a mensagem de fale conosco para o e-mail destino cadastrado na base de dados.
   */
  enviarFaleConosco() {
    this.alertService.clear();
    if (!this.captchaSuccess) {
      this.alertService.error(
        this.formatador.formataMensagem('MSG22', ['Captcha'])
      );
    } else {
      if (this.uploader.queue.length > 0) {
        this.faleConoscoService.salvarFaleConosco(this.faleConosco).subscribe(idFaleConosco => {
          this.uploader.options.additionalParameter = {
            idFaleConosco: idFaleConosco
          }
          this.uploader.options.additionalParameter.idFaleConosco = idFaleConosco;
          this.idFaleConosco = idFaleConosco;
          if (this.uploader.options.additionalParameter.idFaleConosco != null) {
            this.uploader.uploadAll()
          }
        });
      } else {
        this.faleConoscoService.enviarFaleConoscoSemAnexo(this.faleConosco).subscribe(data => {
          if (data == true) {
            this.enviado = true;
          }
        });
      }
    }
    /**
     * Executa ao terminar todo o carremento dos arquivos.
     */
    this.uploader.onCompleteAll = () => {
      this.faleConoscoService.enviarFaleConoscoComAnexo(this.uploader.options.additionalParameter.idFaleConosco).subscribe(data => {
        if (data == true) {
          this.enviado = true;
        } else {
          this.enviado = false;
        }
      });
    };
  }
  /**
   * Direciona o usuário para a tela de login.
   */
  voltar() {
    this.router.navigate(['/authorized']);
  }


  private iniciarUploader() {
    let URL_BASE = this.environmentService.env.PORTAL_URL;
    this.uploader = new FileUploader({
      url: URL_BASE + URL + "/" + '',
      autoUpload: false,
      allowedMimeType: null,
      removeAfterUpload: true,
      maxFileSize: this.maxFileSize,
      //headers: [{name:"Authorization", value: this.authService.getToken()}]
    });

    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    //this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.onWhenAddingFileFailed();
  }
  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    this.finalizar('Não foi possível anexar o documento. Favor verificar se tem ao menos um documento selecionado.',false);
  }

  /**
  * Faz validações de tamanho de arquivo e de apenas um arquivo por vez.
  *
  */
  public onWhenAddingFileFailed() {
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      let mensagem: string = '';
      switch (filter.name) {
        case 'fileSize':
          mensagem = `Tamanho máximo excedido (${(item.size / 1024 / 1024).toPrecision(3)} MB de ${this.maxFileSize / 1024 / 1024}MB aceitos)`;
          break;
        case 'mimeType':
          const allowedTypes = this.allowedMimeType.join();
          mensagem = `Somente é possível anexar um arquivo por vez. Caso queira substituir o arquivo, favor excluir o documento já anexado.`;
          break;
        default:
          mensagem = `Ocorreu algum erro. Entre em contato com o administrador.`;
      }
      this.finalizar(mensagem, false);
    }
  }

  public finalizar(mensagem, sucesso) {
    /*    this.removerAlerta();
       sucesso ? this.alert.success(mensagem) : this.alert.error(mensagem); */
  }

  /**
 * Faz a validação da extensão do arquivo permitida para anexar.
 * Foi necessário criar uma validação de forma personalizada pois os tipos de arquivo ShapeFile e GeoPackage não
 * tem MIMETYPE definido, inviabilizando o uso normal do NG2-File-Select.
 *
*/
  public validarExtensao() {
    this.removerAlerta();
    let extensaoArquivo: string = this.uploader.queue[0]._file.name.substring(this.uploader.queue[0]._file.name.lastIndexOf('.'));
    if (this.extensoes.indexOf(extensaoArquivo) == -1) {
      this.finalizar("Mensagem", false);
      this.uploader.clearQueue();
    }
    this.habilitarQuantidadeDeDocumentoAnexado();
  }

  public removerAlerta() {
    this.alert.clear();
  }

  habilitarQuantidadeDeDocumentoAnexado(): void {
    const currentUrl = this.uploader.options.url; // Recupera a URL atual do uploader
    if (this.uploader.queue.length >= 1) {
      // Se houver pelo menos 1 arquivo, permitir todos os tipos de MIME
      this.uploader.setOptions({
        url: currentUrl, 
        allowedMimeType: [] // Permite todos os tipos de MIME
      });
    } else {
      // Se não houver arquivos, removemos a restrição de tipos MIME
      this.uploader.setOptions({
        url: currentUrl,
        allowedMimeType: undefined // Permite todos os tipos de arquivos
      });
    }
  }

  onChange(event: any): void {
    event.srcElement.value = '';
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

}
