import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AlertService } from '../../componentes/alert/alert.service';
import { AbstractService } from '../abstract.service';
import { RestService } from '../rest-service';
import { Funcionalidade } from './../../models/funcionalidade';
import { Perfil } from './../../models/perfil';
import { TipoRequisicaoRestEnum } from './../../models/tipo-requisicao-rest.enum';
import { mensagensPadrao } from './../../util/mensagens-padrao';

const PATH = '/perfilAcao';

@Injectable()
export class PerfilAcaoService extends AbstractService {
  constructor(private rest: RestService, private alertService: AlertService) {
    super();
  }

  /**
   * Busca perfil com a lista de ações vinculadas preenchida de acordo com id do perfil informado.
   *
   * @param id
   */
  public buscarPerfilAcoesPorIdPerfil(id): Observable<any> {
    let params = new HttpParams().set('id', id);
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      PATH + '/buscarPerfilAcoesPorIdPerfil',
      params
    );
  }

  /**
   * Busca o módulo com as suas funcionalidades de acordo com o id do módulo informado.
   *
   * @param id
   */
  public buscarModuloFuncionalidadesAcoes(idModulo: any): Observable<any> {
    this.alertService.clear();
    let params = new HttpParams().set('idModulo', idModulo);
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      PATH + '/buscarModuloFuncionalidadesAcoes',
      params
    );
  }

  /**
   * Busca a lista de funcionalidades com as respectivas ações.
   *
   * @param id
   */
  buscarListaFuncionalidadesAcoes(): Observable<any> {
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      PATH + '/buscarFuncionalidades'
    );
  }

  /**
   * Busca o perfil com as suas ações vinculadas de acordo com o id do perfil informado.
   *
   * @param id
   */
  buscarPerfilAcoes(id): Observable<any> {
    let params = new HttpParams().set('id', id);
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      PATH + '/buscarPerfilAcoes',
      params
    );
  }

  /**
  * Verifica e valida as ações que o usuário já possui.
  *
  * @param funcionalidades
  */
  public checkTodos(funcionalidades: Funcionalidade[]) {
    if (funcionalidades != null) {
      let isCheck: Boolean = true;
      let qtdPerfisMarcados: number = 0;

      funcionalidades.forEach(funcionalidade => {
        if (funcionalidade.tbAcoes != null) {
          funcionalidade.tbAcoes.forEach(acao => {
            if (acao.isCheck) {
              qtdPerfisMarcados = qtdPerfisMarcados + 1;
            }
          });

          if (qtdPerfisMarcados == funcionalidade.tbAcoes.length) {
            $('#' + funcionalidade.idFuncionalidade).prop(
              'indeterminate',
              false
            );
            funcionalidade.isCheck = true;
          } else if (
            qtdPerfisMarcados > 0 &&
            qtdPerfisMarcados < funcionalidade.tbAcoes.length
          ) {
            $('#' + funcionalidade.idFuncionalidade).prop(
              'indeterminate',
              true
            );
          } else {
            $('#' + funcionalidade.idFuncionalidade).prop(
              'indeterminate',
              false
            );
            funcionalidade.isCheck = false;
          }
          qtdPerfisMarcados = 0;
          isCheck = true;
        }
      });
    }
  }

  /**
   * Realiza a chamada ao serviço de vincular ações ao perfil na API do Portal de Segurança.
   *
   * @param perfil
   */
  gravarPerfilAcoes(perfil: Perfil) {
    this.rest
      .gerarSolicitacao(
        TipoRequisicaoRestEnum.POST,
        PATH + '/salvarPerfilAcoes',
        null,
        perfil
      )
      .subscribe(data => {
        if (data != null) {
          this.alertService.success(mensagensPadrao['MSG03']);
        }
      });
  }
}
