import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Environment } from '../../models/environment';

const PATH: string = environment.URL_ENVIRONMENT_API;

@Injectable()
export class EnvironmentService {
  public env = {} as Environment;

  constructor(private http: HttpClient) {}

  public carregarEnvironments(): Promise<any> {
    let variaveis: string[] = [];
    variaveis.push('PORTAL_URL');
    variaveis.push('CADU_URL');
    variaveis.push('RECAPTCHA_SITE_KEY');
    variaveis.push('SLA_URL');
    variaveis.push('FRAME_FALE_CONOSCO_URL');
    variaveis.push('URL_LOGIN_GOV_BR');
    variaveis.push('OAUTH2_SLA_CLIENT_ID');
    variaveis.push('OAUTH2_SLA_CLIENT_SECRET');

    return new Promise(resolve => {
      this.http.get(PATH + variaveis).subscribe(dados => {
        resolve(dados);
        this.env.PORTAL_URL = dados['PORTAL_URL'];
        this.env.CADU_URL = dados['CADU_URL'];
        this.env.SLA_URL = dados['SLA_URL'];
        this.env.RECAPTCHA_SITE_KEY = dados['RECAPTCHA_SITE_KEY'];
        this.env.FRAME_FALE_CONOSCO_URL = dados['FRAME_FALE_CONOSCO_URL'];
        this.env.URL_LOGIN_GOV_BR = dados['URL_LOGIN_GOV_BR'];
        this.env.OAUTH2_SLA_CLIENT_ID = dados['OAUTH2_SLA_CLIENT_ID'];
        this.env.OAUTH2_SLA_CLIENT_SECRET = dados['OAUTH2_SLA_CLIENT_SECRET'];
      });
    });
  }
}

