import { UsuarioInternoService } from './../manter-usuario/usuario-interno.service';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AlertService } from '../../componentes/alert/alert.service';
import { Usuario } from '../../models/usuario';
import { AbstractService } from '../abstract.service';
import { RestService } from '../rest-service';
import { Modulo } from './../../models/modulo';
import { TipoRequisicaoRestEnum } from './../../models/tipo-requisicao-rest.enum';
import { mensagensPadrao } from './../../util/mensagens-padrao';
import * as $ from 'jquery';


import { AuthService } from '../auth-service';

@Injectable()
export class PerfilUsuarioService extends AbstractService {
  path: string = '/perfilUsuario';

  constructor(
    private rest: RestService,
    private alertService: AlertService,
    private usuarioService: UsuarioInternoService,
    private authService: AuthService
  ) {
    super();
  }

  /**
   * Busca lista de módulos carregando todos os perfis vinculados.
   */
  buscarListaModulosPerfis(): Observable<any> {
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      this.path + '/buscarModulos'
    );
  }

  usuarioVisualizaModulo(m: Modulo): boolean {
    if (this.authService.buscarModulos() == null || this.authService.buscarModulos().length == 0) {
      return false;
    }
    return this.authService.buscarModulos().indexOf(m.idModulo) >= 0 ? true : false;
  }


  /**
   * Busca o usuário com os seus perfis vinculados de acordo com o id do usuário informado.
   *
   * @param id
   */
  buscarUsuarioPerfis(id): Observable<any> {
    let params = new HttpParams().set('id', id);
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      this.path + '/buscarUsuarioPerfis',
      params
    );
  }

  /**
   * Realiza a chamada ao serviço de vincular perfis ao usuário na API do Portal de Segurança.
   *
   * @param usuario
   */
  gravarUsuarioPerfil(usuario: Usuario) {
    this.rest
      .gerarSolicitacao(
        TipoRequisicaoRestEnum.POST,
        this.path + '/salvarUsuarioPerfil',
        null,
        usuario
      )
      .subscribe(data => {
        if (data != null) {
          this.alertService.success(mensagensPadrao['MSG03']);
        }
      });
  }

  /**
   * Clona os perfis do usuário clone para o usuário.
   *
   * @param idUsuario
   * @param idUsuarioClone
   */
  public clonarUsuarioPerfil(idUsuario, idUsuarioClone) {
    let params = new HttpParams()
      .set('idUsuario', idUsuario)
      .set('idUsuarioClone', idUsuarioClone);

    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      this.path + '/clonarUsuarioPerfil',
      params
    );
  }

  /**
   * Verifica e valida os perfis que o usuário já possui.
   *
   * @param modulos
   */
  public checkTodos(modulos: Modulo[]) {
    if (modulos != null) {
      let isCheck: Boolean = true;
      let qtdPerfisMarcados: number = 0;

      modulos.forEach(modulo => {
        if (modulo.tbPerfis != null) {
          modulo.tbPerfis.forEach(perfil => {
            if (perfil.isCheck) {
              qtdPerfisMarcados = qtdPerfisMarcados + 1;
            }
          });

          if (qtdPerfisMarcados == modulo.tbPerfis.length) {
            $('#' + modulo.idModulo).prop('indeterminate', false);
            modulo.isCheck = true;
          } else if (
            qtdPerfisMarcados > 0 &&
            qtdPerfisMarcados < modulo.tbPerfis.length
          ) {
            $('#' + modulo.idModulo).prop('indeterminate', true);
          } else {
            $('#' + modulo.idModulo).prop('indeterminate', false);
            modulo.isCheck = false;
          }
          qtdPerfisMarcados = 0;
          isCheck = true;
        }
      });
    }
  }

  /**
   * Realiza uma consulta do nome do usuário atravéz do CPF passado.
   * Utilizado quando o parâmetro de integração com a SEF estiver habilitado.
   *
   * @param cpf
   */
  public buscarUsuarioPeloCpf(cpf): Observable<any> {
    return this.usuarioService.buscarUsuarioPeloCpf(cpf);
  }
  /**
   * Realiza uma consulta do nome do usuário atravéz do CPF passado.
   * carrega apenas perfils que tenham o modulo do usuário logado.
   *
   * @param cpf
   */
  public buscarPorCpfFiltraPerfilModulo(cpf): Observable<any> {
    return this.usuarioService.buscarPorCpfFiltraPerfilModulo(cpf);
  }
}
