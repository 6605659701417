import { AuthService } from './../../../services/auth-service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
export const CLIENT_ID='portalseguranca';
@Component({
  selector: 'app-authorized',
  templateUrl: './authorized.component.html',
  styleUrls: ['./authorized.component.css']
})
export class AuthorizedComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private route: Router) {
    }

    ngOnInit(): void {
      if(localStorage.getItem('refreshToken') == null || this.authService.isAccessTokenInvalido()){
        this.authService.autenticar();
      }

      this.activatedRoute.queryParams.subscribe((params:any) => {
        if (params.code) {
           this.authService.gerarAccessTokenComCode(params.code, params.state, false)
            .then(()=> {
              if(this.authService.donoToken() === CLIENT_ID){
                this.authService.logout();
              } else {
                this.route.navigate(["/home"]);
              }
            })
            .catch((e:any) => {
              console.error('Erro no callback')
            });
        } else {
          this.route.navigate(['/']);
        }
      })
    }

}

