import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AlertService } from '../../componentes/alert/alert.service';
import { ModalService } from '../../componentes/modal/modal.service';
import { AbstractService } from '../abstract.service';
import { RestService } from '../rest-service';
import { Modulo } from './../../models/modulo';
import { TipoRequisicaoRestEnum } from './../../models/tipo-requisicao-rest.enum';
import { mensagensPadrao } from './../../util/mensagens-padrao';
import { AuthService } from '../auth-service';

const PATH = '/modulo';

@Injectable()
export class ModuloService extends AbstractService {
  constructor(private authService: AuthService,
    private rest: RestService,
    private router: Router,
    private alertService: AlertService,
    public modalService: ModalService
  ) {
    super();
  }

  /**
   * Busca a lista de todos os módulos.
   */
  public buscarListaModulos(): Observable<any> {
    this.alertService.clear();
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      PATH + '/buscarListaModulos'
    );
  }

  /**
   * Realiza a inclusão ou alteração do módulo na base de dados.
   *
   * @param modulo
   */
  public gravarModulo(modulo: Modulo) {
    modulo.loginUltAtual = this.authService.donoToken();
    if (modulo.idModulo == undefined) {
      this.incluirModulo(modulo);
    } else {
      this.alterarModulo(modulo);
    }
  }

  /**
   * Realiza a chamada ao serviço de cadastro de módulo na API do Portal de Segurança.
   *
   * @param modulo
   */
  public incluirModulo(modulo: Modulo) {
    this.rest
      .gerarSolicitacao(TipoRequisicaoRestEnum.POST, PATH, null, modulo)
      .subscribe(data => {
        this.alertService.clear();
        this.alertService.success(mensagensPadrao['MSG03']);
        this.modalService.fecharSucessModal();
      });
  }

  /**
   * Realiza a chamada ao serviço de alteração de módulo na API do Portal de Segurança.
   *
   * @param modulo
   */
  public alterarModulo(modulo: Modulo) {
    this.rest
      .gerarSolicitacao(TipoRequisicaoRestEnum.PUT, PATH, null, modulo)
      .subscribe(data => {
        this.alertService.clear();
        this.alertService.success(mensagensPadrao['MSG03']);
        this.modalService.fecharSucessModal();
      });
  }

  /**
   * Exclui o módulo caso o mesmo não possua funcionalidades e perfis vinculados.
   *
   * @param idModulo
   */
  public excluirModulo(idModulo: Number): Observable<any> {
    this.alertService.clear();
    let retornoObserver;
    let retorno = new Observable(observer => (retornoObserver = observer));
    this.rest
      .gerarSolicitacao(TipoRequisicaoRestEnum.DELETE, PATH, null, idModulo)
      .subscribe(data => {
        if (data != null) {
          this.alertService.success(mensagensPadrao['MSG23']);
          if (retornoObserver != null) {
            retornoObserver.next(data);
          }
        }
      });

    return retorno;
  }

  /**
   * Recupera o módulo pelo id informado.
   *
   * @param id
   */
  public buscarPorId(id): Observable<any> {
    let params = new HttpParams().set('id', id);
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      PATH + '/buscarPorId',
      params
    );
  }

  /**
   * Retorna a quantidade de registros de acordo com o filtro.
   *
   * @param filtroModuloDTO
   */
  public countModulos(filtroModuloDTO): Observable<any> {
    let params = new HttpParams()
      .set('sigla', filtroModuloDTO.sigla)
      .set('nome', filtroModuloDTO.nome)
      .set('status', filtroModuloDTO.status);
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      PATH + '/countModulos',
      params
    );
  }

  /**
   * Busca lista de módulos de acordo com as informações do filtro, da página atual e da quantidade de itens por página.
   *
   * @param filtroModuloDTO
   * @param page
   * @param size
   */
  public buscarModulos(filtroModuloDTO, page, size): Observable<any> {
    if (page != 0) {
      page = page - 1;
    }
    let params = new HttpParams()
      .set('page', page)
      .set('size', size)
      .set('sigla', filtroModuloDTO.sigla)
      .set('nome', filtroModuloDTO.nome)
      .set('status', filtroModuloDTO.status);
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      PATH + '/buscarModulos',
      params
    );
  }
}
