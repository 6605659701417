import { HttpParams } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AlertService } from '../../componentes/alert/alert.service';
import { AbstractService } from './../abstract.service';
import { RestService } from '../rest-service';
import { TipoRequisicaoRestEnum } from '../../models/tipo-requisicao-rest.enum';

import { FaleConosco } from './../../models/fale-conosco';
import { mensagensPadrao } from './../../util/mensagens-padrao';
import { ModuloService } from './../manter-modulo/modulo.service';
import { AssuntoFaleConosco } from './../../models/assunto-fale-conosco';
import { Modulo } from './../../models/modulo';

@Injectable()
export class FaleConoscoService extends AbstractService {

  path: string = '/faleConosco';

  constructor(
    private rest: RestService,
    private alertService: AlertService,
    private moduloService: ModuloService,
    public router: Router
  ) {
    super();
  }

  /**
   * Busca a lista de todos os módulos para o fale conosco.
   */
  public buscarListaModulo(): Observable<any> {
    this.alertService.clear();
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      this.path + '/buscarListaModulo'
    );
  }

  /**
   * Realiza uma consulta do assunto do fale conosco através do id do módulo selecionado.
   *
   * @param idModulo
   */
  public buscarListaAssuntoFaleConoscoPorIdModulo(idModulo: any): Observable<any> {
    let params = new HttpParams().set('idModulo', idModulo);
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      this.path + '/buscarListaAssuntoFaleConoscoPorIdModulo',
      params
    );
  }

  /**
   * Envia a mensagem de fale conosco e grava na base de dados.
   *
   * @param faleConosco
   */
  public salvarFaleConosco(faleConosco: FaleConosco) {
    return this.rest
      .gerarSolicitacao(
        TipoRequisicaoRestEnum.POST,
        this.path + '/salvarFaleConosco',
        null,
        faleConosco
      )
  }

    /**
   * Envia a mensagem de fale conosco e grava na base de dados SEM anexo.
   *
   * @param faleConosco
   */
  public enviarFaleConoscoSemAnexo(faleConosco: FaleConosco) {
    return this.rest
      .gerarSolicitacao(
        TipoRequisicaoRestEnum.POST,
        this.path + '/enviarFaleConoscoSemAnexo',
        null,
        faleConosco
      )
  }
    /**
   * Envia a mensagem de fale conosco e grava na base de dados COM anexo.
   *
   * @param faleConosco
   */
  public enviarFaleConoscoComAnexo(idFaleConosco: any) {
    return this.rest
      .gerarSolicitacao(
        TipoRequisicaoRestEnum.POST,
        this.path + `/enviarFaleConoscoArquivos/${idFaleConosco}`
      )
  }
}
