import { Router } from '@angular/router';
import { EnvironmentService } from './environment/environment.service';
import { Usuario } from './../models/usuario';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { RestService } from './rest-service';
import { TipoRequisicaoRestEnum } from '../models/tipo-requisicao-rest.enum';
import { HomeService } from './home/home.service';
import { CookieService } from 'ngx-cookie-service';
import { Modulo } from '../models/modulo';
export const AUTHORIZATION = 'Authorization';
export const REFRESH_TOKEN = 'refreshToken';
export const CODEVERIFIER = 'codeVerifier';
export const CLIENT_ID = 'portalseguranca';
const BEARER = 'Bearer';

@Injectable()
export class AuthService {

   constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService,
    private environmentService: EnvironmentService, private router: Router,
    private cookieService: CookieService
  ) {
    this.carregarToken();
  }

  oauthTokenUrl = environment.URL_PORTAL_SEGURANCA + '/oauth2/token';
  oauthAuthorizeUrl = environment.URL_PORTAL_SEGURANCA + '/oauth2/authorize'
  jwtPayload: any;
  modulos: Modulo[]=[];

  clearCookie(name: string): void {
    this.cookieService.delete(name);
  }

  public cadastrarUsuarioPrimeiroAcesso(usuario: Usuario){
    let cpf = usuario.cpf;
    let nome = usuario.nome;
    let email = usuario.email;

    const headers = new HttpHeaders({
      'Content-type': 'application/json; charset=utf-8'
    });

    this.environmentService.carregarEnvironments().then(() => {

      // this.http.get(`${this._ssoUri}`+ PATH +`/usuario?code=${code}`,
      this.http.post(environment.URL_OAUTH+"/autenticar-gov-br" + `/cadastrarUsuarioPrimeiroAcesso?cpf=${cpf}&nome=${nome}&email=${email}`,
        {
          headers: headers
        }
      ).subscribe(
        () => {
          console.log("Usuário cadastrado...");
        },
        err => {
          console.error('Erro ao cadastrar usuário primeiro acesso gov.br: ', err);
        },
        () => {}
      );
    });
  }

  public cadastrarParticipante(cpf: any){

    const headers = new HttpHeaders({
      'Content-type': 'application/json; charset=utf-8'
    });

    this.environmentService.carregarEnvironments().then(() => {

      // this.http.get(`${this._ssoUri}`+ PATH +`/usuario?code=${code}`,
      this.http.post(environment.URL_OAUTH+"/autenticar-gov-br" + `/cadastrarParticipante?cpf=${cpf}`,
        {
          headers: headers
        }
      ).subscribe(
        () => {
          console.log("Usuário cadastrado...");
        },
        err => {
          console.error('Erro ao cadastrar participante: ', err);
        },
        () => {}
      );
    });
  }

 public autenticar(ssoUri?: string, usuario?: Usuario, loginGovBr?:boolean) {

    if(loginGovBr){
      const params = new URLSearchParams();
      params.append('username', usuario.cpf);
      params.append('password', usuario.senhaGovBr);
      params.append('grant_type', 'password');
      params.append('client_id', 'portalseguranca');
      const headers = new HttpHeaders({
        'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
        'Authorization': 'gov.br'
      });

      this.http.post(
        environment.URL_OAUTH+'/oauth/token',
        params.toString(),
        {
          headers: headers
        }
      ).subscribe(
        (token: any) => {
          const expireDate = new Date().getTime() + (1000 * token.expires_in);
          console.log('expireDate: ', expireDate);
          this.putToken(token.access_token);
          this.router.navigate(['/home']);
        },
        err => {
          console.error('autenticar: ', err);
        },
        () => {}
      );


    } else {


      const state = this.gerarChaveAleatoria(40);
      const codeVerifier = this.gerarChaveAleatoria(128);

      localStorage.setItem('state', state);
      localStorage.setItem('codeVerifier', codeVerifier);

      const challengeMethod = 'S256'
      const codeChallenge = CryptoJS.SHA256(codeVerifier)
        .toString(CryptoJS.enc.Base64)
        .replace('/=/g', '')
        .replace('/\+/g', '')
        .replace('/\//g', '');

      const redirectURI = encodeURIComponent(environment.oauthCallBack);
      const scope = 'read+write'
      const responseType = 'code'

      const params = [
        'response_type=' + responseType,
        'client_id=' + CLIENT_ID,
        'state=' + state,
        'redirect_uri=' + redirectURI,
        'code_challenge=' + codeChallenge,
        'code_challenge_method=' + challengeMethod,
        'scope=' + scope,
      ]

      window.location.href = this.oauthAuthorizeUrl + '?' +  params.join('&');

    }

  }



  private gerarChaveAleatoria(tamanho: number) {
    let resultado = '';
    //Chars são URL safe
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < tamanho; i++) {
      resultado += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return resultado;
}

logout() {
  this.clearCookie('JSESSIONID');
  localStorage.clear();
  window.location.href = environment.URL_PORTAL_SEGURANCA + '/logout?returnTo=' + environment.oauthCallBack;
}

public gerarAccessTokenComRefresToken(): Promise<void> {
  if(localStorage.getItem(REFRESH_TOKEN) !== null &&
  localStorage.getItem(REFRESH_TOKEN) !== undefined && this.isAccessTokenInvalido()){
    const payload = new HttpParams()
    .append('grant_type', 'refresh_token')
    .append('client_id', CLIENT_ID)
    .append('refresh_token', localStorage.getItem(REFRESH_TOKEN)!)
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/x-www-form-urlencoded')
      .append('Authorization', 'Basic cG9ydGFsc2VndXJhbmNhOmFiYzEyMw==');
  return this.http.post<any>(this.oauthTokenUrl, payload,
       {headers} )
    .toPromise()
    .then((response:any) => {
      if(response['access_token'] !== undefined){
        this.armazenarToken(response['access_token']);
      }
      this.armazenarRefreshToken(response['refresh_token'])
      if(this.donoToken() === CLIENT_ID){
        this.logout();
      }
      return Promise.resolve();
    })
    .catch((response:any) => {
      this.logout();
      console.error('Erro ao renovar token com refresh token.', response);
      return Promise.resolve();
    });
  } else {
    if(this.donoToken() === CLIENT_ID){
      this.logout();
    }
    return Promise.resolve();
  }
}

public gerarAccessTokenComCode(code: string, state: string, loginGovBr: boolean) : Promise<any>{

  if(this.isAccessTokenInvalido() && localStorage.getItem(REFRESH_TOKEN) === null){
    const stateSalvo = localStorage.getItem('state');
    let headers;
    let payload;

        if (stateSalvo !== state) {
          this.limparAccessToken();
          return Promise.reject(null);
        }
        headers = new HttpHeaders()
        .append('Content-Type', 'application/x-www-form-urlencoded')

        const codeVerifier = localStorage.getItem(CODEVERIFIER)!;

        payload = new HttpParams()
          .append('grant_type', 'authorization_code')
          .append('code', code.substring(0).split('&')[0])
          .append('redirect_uri', environment.oauthCallBack)
          .append('code_verifier', codeVerifier);






      return this.http.post<any>(this.oauthTokenUrl, payload, {headers},
        )
        .toPromise()
        .then((response:any) => {
          this.armazenarToken(response['access_token']);
          this.armazenarRefreshToken(response['refresh_token']);
          this.router.navigate(["home"]);
          return Promise.resolve(null);
        })
        .catch((response:any) => {
          console.error('Erro ao gerar o token com authorization_code.', response);
          return Promise.resolve();
        });
  } else {
    return Promise.resolve();
  }
  }



  isAccessTokenInvalido() {
    const token = localStorage.getItem(AUTHORIZATION);
    return !token || this.jwtHelper.isTokenExpired(token);
  }

  temPermissao(permissao: string) {
    return this.jwtPayload && this.jwtPayload.authorities.includes(permissao);
  }

  donoToken(){
    if(this.jwtPayload !== undefined && this.jwtPayload.user_name !== undefined){
      return this.jwtPayload.user_name
    }
    if(this.jwtPayload !== undefined && this.jwtPayload.sub !== undefined){
      return this.jwtPayload.sub
    } else {
      return null;
    }
  }

  tokenGovBr(): Boolean{
    if(this.jwtPayload !== undefined && this.jwtPayload.sub !== undefined){
      return false;
    } else {
      return true;
    }
  }

  temQualquerPermissao(roles: any) {
    for (const role of roles) {
      if (this.temPermissao(role)) {
        return true;
      }
    }

    return false;
  }

   public armazenarToken(token: any) {
    this.jwtPayload = this.jwtHelper.decodeToken(token);

    if(this.jwtPayload.user_name != null && !this.jwtPayload.user_name || this.jwtPayload.user_name == null || this.jwtPayload.user_name == undefined){
      this.limparAccessToken();
    }

    localStorage.setItem(AUTHORIZATION, token);

    if(this.jwtHelper.isTokenExpired(token)){
      this.limparAccessToken();
    }
  }

  public limparTokenSemUserName(){
    this.jwtPayload = this.jwtHelper.decodeToken(this.getToken());
    if(this.jwtPayload.user_name != null && !this.jwtPayload.user_name || this.jwtPayload.user_name == null || this.jwtPayload.user_name == undefined){
      this.limparAccessToken();
    }
  }

  public getNomeUsuario(){
    this.jwtPayload = this.jwtHelper.decodeToken(this.getToken());
    return this.jwtPayload.nome !== undefined ? this.jwtPayload.nome : null
  }

  public buscarCpfNoToken(){
    this.jwtPayload = this.jwtHelper.decodeToken(this.getToken());
    return this.jwtPayload.sub !== undefined ? this.jwtPayload.sub : null
  }

  public carregarToken() {
    const token = localStorage.getItem(AUTHORIZATION);

    if (token) {
      this.armazenarToken(token);
    }
  }

  public getPrimeiroNomeUsuario(){
    this.jwtPayload = this.jwtHelper.decodeToken(this.getToken());

    // nome do usuario no token do portal gov.br
    if(this.jwtPayload.name == "" && this.jwtPayload.name != ""){
      return this.jwtPayload.name !== undefined ? this.jwtPayload.name : null
    }

     // nome do usuario no token do portal de seguranca Spring Boot
    if(this.jwtPayload.nome == ""){
      this.doLogout();
    } else {
      return this.jwtPayload.nome !== undefined ? this.jwtPayload.nome.split(" ",1) : null
    }
  }

  limparAccessToken() {
    localStorage.removeItem(AUTHORIZATION);
    this.jwtPayload = null;
  }

  private armazenarRefreshToken(refreshToken: string) {
    localStorage.setItem('refreshToken', refreshToken);
  }

  // portal antigo para baixo

  public getToken() {
    const token = localStorage.getItem(AUTHORIZATION);
    if(!token)
      return undefined;

    return `${BEARER} ${token}`;
  }

  public putToken(token: string) {
    localStorage.setItem(AUTHORIZATION, token);
  }


  public removerToken() {
    localStorage.removeItem(AUTHORIZATION);
  }

  public isAuthenticated() {
    const token = this.getToken();
    return !!token;
  }

  public putRefreshToken(refreshToken: string) {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }

  public getRoles() {
    const token = this.getToken().replace(BEARER, '').trim();
    const base64Data = token.split('\.')[1];
    const jsonData = atob(base64Data);
    const data = JSON.parse(jsonData);
    const roles = <string[]>data.authorities;
    return roles;
  }
  public getModulos(): string[] {
    let items = JSON.parse(atob(this.getToken().split('\.')[1]));
    return items.modulos != undefined ? items.modulos : [];
  }

  public buscarModulos(): Modulo[] {
    this.jwtPayload = this.jwtHelper.decodeToken(this.getToken());

    // Cria um novo vetor temporário para armazenar os módulos
    let modulosTemp: Modulo[] = [];

    // Adiciona os módulos decodificados ao vetor temporário
    this.jwtPayload.modulos.forEach((modulo: Modulo) => {
      modulosTemp.push(modulo);
    });

    // Retorna o vetor de módulos sem modificar diretamente this.modulos
    return modulosTemp;
}

  /**
   * Valida a role informada, consultando as permissões do usuário armazenadas no localStorage.
   */
  validaRole(roleItem: string) {
    const roles = this.getRoles();
    const isValido = roles.some(role => role == roleItem);
    return isValido;
  }

  /**
   * Valida todas as roles recebidas, consultando as permissões do usuário armazenadas no localStorage.
   */
  validaGroupRole(groupItem: string[]): boolean {
    let isValido: boolean = true;
    let roles = this.getRoles();
    if (roles.length > 0) {
      groupItem.forEach(roleItem => {
        let flag: boolean = false;
        if (isValido) {
          roles.forEach(role => {
            if (roleItem == role) {
              flag = true;
            }

            if (roles.indexOf(role) + 1 == roles.length && !flag) {
              isValido = false;
            }
          });
        }
      });
    } else {
      isValido = false;
    }

    return isValido;
  }

  doLogout() {
    this.removerToken();
  }
}


