import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagina-confirmacao-email',
  template: `
  <style>
  .error-template {padding: 40px 15px;text-align: center;}
  .error-actions {margin-top:15px;margin-bottom:15px;}
  .error-actions .btn { margin-right:10px; }
</style>

<div class="container">
<div class="row">
    <div class="col-md-12">
        <div class="error-template">
          <h1>Conta validada com sucesso!</h1>
          <div class="error-actions" style="padding-top: 20px;">
            <a routerLink="/authorized" class="btn btn-primary btn-lg">
              Clique aqui para efetuar login </a>
          </div>
        </div>
    </div>
</div>
</div>`
})
export class PaginaConfirmacaoEmailComponent implements OnInit {
  constructor() {}

  public ngOnInit(): void {}
}
