import { Injectable, OnInit } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';

import { AbstractService } from '../services/abstract.service';
import { AuthService } from './../services/auth-service';
import { LoginGovBrService } from '../services/portal-gov-br/login-gov-br.service';

@Injectable()
export class AuthGuardService extends AbstractService
  implements OnInit, CanActivate {
  constructor(private router: Router,
    private authService: AuthService,
    private loginGovBrService: LoginGovBrService) {
    super();
  }

  public ngOnInit(): void {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.validaAcessoPagina(route.data['roles']);
  }

  /**
   * Verifica se o usuário se encontra autenticado e caso seja informado alguma role, a mesma será validada com as roles armazenadas no localStorage.
   */
  private validaAcessoPagina(requiredRoles?: string[]): boolean {
    let retorno: boolean = false;

    if (this.authService.isAuthenticated()) {
      retorno = true;
      if (requiredRoles != undefined && requiredRoles.length > 0) {
        retorno = this.authService.validaGroupRole(requiredRoles);
      }
    }

    if (!retorno) {
      const urlParams = new URLSearchParams(window.location.search);
      console.log('auth-guard 1');
      if(urlParams.get('code')) {
        if(urlParams.get('code').length == 128){
          this.authService.gerarAccessTokenComCode(urlParams.get('code'), urlParams.get('state'), false);
        } else {
          this.loginGovBrService.getUsuarioGovBr(urlParams.get('code'));
        }
      }
      else {
        this.router.navigate(['/authorized']);
      }
    }

    return retorno;
  }
}

