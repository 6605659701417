import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Modulo } from '../../models/modulo';
import { OrgaoEnum } from '../../models/orgao.enum';
import { AuthService } from './../../services/auth-service';
import { HomeService } from './../../services/home/home.service';

@Component({
  selector: 'box-acesso',
  templateUrl: './box-acesso.component.html',
  styleUrls: ['./box-acesso.component.css']
})
export class BoxAcessoComponent implements OnInit {
  @Input() modulo: Modulo;
  inscricao: Subscription;

  constructor(
    private router: Router,
    private authService: AuthService,
    private homeService: HomeService
  ) { }

  ngOnInit() { }

  getIcon() {
    let img = '';

    if (OrgaoEnum.SEMAD == Number.parseInt(OrgaoEnum[this.modulo.orgao])) {
      img = 'assets/icon/pseg.png';
    } else if (
      OrgaoEnum.IGAM == Number.parseInt(OrgaoEnum[this.modulo.orgao])
    ) {
      img = 'assets/icon/paper.png';
    } else if (OrgaoEnum.IEF == Number.parseInt(OrgaoEnum[this.modulo.orgao])) {
      img = 'assets/icon/paper.png';
    } else if (
      OrgaoEnum.FEAM == Number.parseInt(OrgaoEnum[this.modulo.orgao])
    ) {
      img = 'assets/icon/paper.png';
    }

    return img;
  }

  getTitleColor() {
    let color = '#';

    if (OrgaoEnum.SEMAD == Number.parseInt(OrgaoEnum[this.modulo.orgao])) {
      color += 'fdbb2d';
    } else if (
      OrgaoEnum.IGAM == Number.parseInt(OrgaoEnum[this.modulo.orgao])
    ) {
      color += '2cc4f2';
    } else if (OrgaoEnum.IEF == Number.parseInt(OrgaoEnum[this.modulo.orgao])) {
      color += 'a1cd44';
    } else if (
      OrgaoEnum.FEAM == Number.parseInt(OrgaoEnum[this.modulo.orgao])
    ) {
      color += '00987f';
    }

    return color;
  }

  /**
   * Redireciona para a próxima página.
   */
  click() {
    this.inscricao = this.homeService
      .buscarRolesUsuarioPorModulo(this.modulo.idModulo)
      .subscribe(retorno => {
        /* this.authService.putRoles(retorno), */
          window.open(this.modulo.link, '_blank');
        // window.location.replace(this.modulo.link);
      });
  }

}
