import { environment } from './../../../environments/environment';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AlertService } from '../../componentes/alert/alert.service';
import { AbstractService } from '../abstract.service';
import { Usuario } from './../../models/usuario';
import { AuthService } from './../auth-service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EnvironmentService } from '../environment/environment.service';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class LoginService {



  constructor(
    public router: Router,
    public authService: AuthService,
    private alertService: AlertService,
    private http: HttpClient,
    private environmentService: EnvironmentService
  ) {
    // super();
    this.authService.getToken();
  }
  /**
   * Método responsável por chamar o serviço de back-end para executar a autenticação.
   *
   * @param usuario
   */
  public autenticar(ssoUri: string, usuario: Usuario, loginGovBr?:boolean) {
    this.alertService.clear();
    this.authService.removerToken();

    const params = new URLSearchParams();
    params.append('username', usuario.cpf);
    params.append('password', loginGovBr ? usuario.senhaGovBr:usuario.senha);
    params.append('grant_type', 'password');
    // params.append('client_id', this.environmentService.env.OAUTH2_SLA_CLIENT_ID);

    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      // 'Authorization': `Basic ${btoa(`${this.environmentService.env.OAUTH2_SLA_CLIENT_ID}:${this.environmentService.env.OAUTH2_SLA_CLIENT_SECRET}`)}`
    });

    this.http.post(
      `${ssoUri}/oauth/token`,
      params.toString(),
      {
        headers: headers
      }
    ).subscribe(
      (token: any) => {
        const expireDate = new Date().getTime() + (1000 * token.expires_in);
        console.log('expireDate: ', expireDate);
        this.authService.putToken(token.access_token);
        this.router.navigate(['/home']);
      },
      err => {
        console.error('autenticar: ', err);
      },
      () => {}
    );
  }







}
