import { StrengthBarService } from './../../componentes/strength-bar/strength-bar.service';
import { AbstractModule } from './../../abstract.module';
import { CommonModule } from '@angular/common';
import { ModalService } from './../../componentes/modal/modal.service';
import { AlertService } from './../../componentes/alert/alert.service';
import { TrocarSenhaService } from './../../services/trocar-senha/trocar-senha.service';
import { EnvironmentService } from './../../services/environment/environment.service';
import { FormatadorDeMensagens, mensagensPadrao } from './../../util/mensagens-padrao';
import { AuthService } from './../../services/auth-service';
import { UsuarioInternoService } from './../../services/manter-usuario/usuario-interno.service';
import { Usuario } from './../../models/usuario';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-alterar-senha',
  templateUrl: './modal-alterar-senha.component.html',
  styleUrl: './modal-alterar-senha.component.css'
})
export class ModalAlterarSenhaComponent implements OnInit{

  recaptchaSiteKey = this.environmentService.env.RECAPTCHA_SITE_KEY;
  captchaSuccess: boolean = false;
  mensagensPadrao: any;
  @ViewChild('campo') campoStrengthBar: ElementRef;
  novaSenhaConfirmacao: string;

  public formUser: FormGroup = new FormGroup({});
  public usuario = new Usuario();
  @Output() chamadaModal = new EventEmitter();
  @Input() valorPai: string;
  public desativarCpf = false;

  @ViewChild('modal', {static: true}) modal: TemplateRef<any>;
  @ViewChild('vc', {read: ViewContainerRef, static: true}) vc: ViewContainerRef;

  backdrop: any;
  showDialog() {
    // this.carregarDados();  // Função para carregar os dados necessários para o modal

    // Insere o template no container
    const view = this.modal.createEmbeddedView(null);
    this.vc.insert(view);

    // Obtém o modal pelo ID diretamente após a inserção no DOM
    const modalElement = document.getElementById('user');  // Assume-se que o ID do modal é 'usuarios'

    // Mover o modal para o body
    document.body.appendChild(modalElement);

    // Modificar estilos para exibir o modal corretamente
    modalElement.classList.remove('fade');  // Remove a animação fade
    modalElement.classList.add('modal-open');  // Adiciona a classe modal-open para exibir corretamente
    modalElement.classList.add('show');  // Adiciona a classe show para exibição
    modalElement.style.display = 'block';  // Garante que o modal apareça

    // Criar o backdrop manualmente
    this.backdrop = document.createElement('DIV');
    this.backdrop.className = 'modal-backdrop show';  // Classe necessária para o backdrop
    document.body.appendChild(this.backdrop);
  }

  constructor(private usuarioInternoService: UsuarioInternoService, private authService: AuthService,
    private formBuilder: FormBuilder, public formatador: FormatadorDeMensagens, private cdr: ChangeDetectorRef,
    private trocarSenhaService: TrocarSenhaService,
    private environmentService: EnvironmentService, private alertService: AlertService, public strengthBarService: StrengthBarService,
    ) {
  }

  carregarDados(){
    this.usuarioInternoService.buscarUsuarioPeloCpf(this.authService.buscarCpfNoToken()).subscribe(retorno => {
      this.usuario = retorno;
      this.inicializarFormulario(retorno);

    });
    this.mensagensPadrao = mensagensPadrao;
  }


  gravarTrocaDeSenha() {
    this.usuario.novaSenha = this.formUser.get('novaSenha').value;
    this.usuario.senhaAtual = this.formUser.get('senhaAtual').value;

    this.alertService.clear();
    if (this.campoStrengthBar['forcaDaSenha'] < 5) {
      this.alertService.error(mensagensPadrao['MSG09']);
    } else if (this.usuario.novaSenha != this.formUser.get('confirmeSenha').value) {
      this.alertService.error(mensagensPadrao['MSG08']);
    } else if (!this.captchaSuccess) {
      this.alertService.error(
        this.formatador.formataMensagem('MSG22', ['Captcha'])
      );
    } else {
      this.trocarSenhaService.trocarSenha(this.usuario);
      this.closeDialog();
    }

  }

 /**
   * Verifica a utilização do componente de cptcha
   * @param response
   */
 public onCaptchaComplete(response: any) {
  this.captchaSuccess = response!=undefined && response!=null && response.length>0;
  }


  /**
   * Realiza o carregamento dos dados necessários para o modal e
   * faz o subscribe para buscar o usuário logado.
   */
  ngOnInit() {
    this.carregarDados();
    this.trocarSenhaService
    .buscarUsuarioLogado()
    .subscribe(
      usuario => (this.usuario = JSON.parse(JSON.stringify(usuario)))
    );

  }
  private inicializarFormulario(retorno: Usuario) {
    // Inicializa o formulário vazio
    this.formUser = this.formBuilder.group({
      senhaAtual: ['', Validators.required],
      novaSenha: ['', Validators.required],
      confirmeSenha: ['', Validators.required]
    });
  }




  closeDialog() {
    this.chamadaModal.emit(false);

    const modalElement = document.body.querySelector('.modal');
    if (modalElement) {
      document.body.removeChild(modalElement); // Remove o modal do body
    }
    if (this.backdrop) {
      document.body.removeChild(this.backdrop); // Remove o backdrop
    }

    // Remove o atributo inert quando o modal for fechado
    const mainContent = document.querySelector('main');
    if (mainContent) {
      mainContent.removeAttribute('inert');
    }

    this.formUser.reset();
  }




}
