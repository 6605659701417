export const securityRoles = {
  // PERMISSÕES PARA TELA DE MODULO
  MODULO_PESQUISAR: 'ROLE_PSEG_MMMM_PESQUISAR',
  MODULO_SALVAR: 'ROLE_PSEG_MMMM_SALVAR',
  MODULO_ALTERAR: 'ROLE_PSEG_MMMM_ALTERAR',
  MODULO_EXCLUIR: 'ROLE_PSEG_MMMM_EXCLUIR',
  MODULO_CLIENT: 'ROLE_PSEG_MMMM_CLIENT',
  // PERMISSÕES PARA TELA DE USUARIO
  USUARIO_PESQUISAR: 'ROLE_PSEG_MTRU_PESQUISAR',
  USUARIO_SALVAR: 'ROLE_PSEG_MTRU_SALVAR',
  USUARIO_ALTERAR: 'ROLE_PSEG_MTRU_ALTERAR',
  USUARIO_VINCULAR_SISTEMA: 'ROLE_PSEG_MTRU_VINCULAR_SISTEMA',

  // PERMISSÕES PARA TELA DE FUNCIONALIDADE
  FUNCIONALIDADE_PESQUISAR: 'ROLE_PSEG_FUNCIONALIDADE_PESQUISAR',
  FUNCIONALIDADE_SALVAR: 'ROLE_PSEG_FUNCIONALIDADE_SALVAR',
  FUNCIONALIDADE_ALTERAR: 'ROLE_PSEG_FUNCIONALIDADE_ALTERAR',
  FUNCIONALIDADE_EXCLUIR: 'ROLE_PSEG_FUNCIONALIDADE_EXCLUIR',

  // PERMISSÕES PARA TELA DE PERFIL
  PERFIL_PESQUISAR: 'ROLE_PSEG_PERFIL_PESQUISAR',
  PERFIL_SALVAR: 'ROLE_PSEG_PERFIL_SALVAR',
  PERFIL_ALTERAR: 'ROLE_PSEG_PERFIL_ALTERAR',
  PERFIL_EXCLUIR: 'ROLE_PSEG_PERFIL_EXCLUIR',

    // PERMISSÕES PARA TELA DE faq
    FAQ_PESQUISAR: 'ROLE_PSEG_FAQS_PESQUISAR',
    FAQ_SALVAR: 'ROLE_PSEG_FAQS_SALVAR',
    FAQ_ALTERAR: 'ROLE_PSEG_FAQS_ALTERAR',
    FAQ_EXCLUIR: 'ROLE_PSEG_FAQS_EXCLUIR',

  // PERMISSÕES PARA TELA DE ACAO
  ACAO_PESQUISAR: 'ROLE_PSEG_ACAO_PESQUISAR',
  ACAO_SALVAR: 'ROLE_PSEG_ACAO_SALVAR',
  ACAO_ALTERAR: 'ROLE_PSEG_ACAO_ALTERAR',
  ACAO_EXCLUIR: 'ROLE_PSEG_ACAO_EXCLUIR',

  // PERMISSÕES PARA TELA DE PERFIL ACAO
  PERFIL_ACAO_PESQUISAR: 'ROLE_PSEG_PERFIL_ACAO_PESQUISAR',
  PERFIL_ACAO_SALVAR: 'ROLE_PSEG_PERFIL_ACAO_SALVAR',

  // PERMISSÕES PARA TELA DE USUARIO PERFIL
  USUARIO_PERFIL_PESQUISAR: 'ROLE_PSEG_VIUP_PESQUISAR',
  USUARIO_PERFIL_SALVAR: 'ROLE_PSEG_VIUP_SALVAR',

  // PERMISSÕES PARA TELA DE TROCAR SENHA
  TROCAR_SENHA_SALVAR: 'ROLE_PSEG_TROCAR_SENHA_SALVAR',

  // PERMISSÕES TELA DE EDITAR USUARIO INTERNO
  ACAO_VINCULAR_SISTEMA:'ROLE_PSEG_MTRU_VINCULAR_SISTEMA'
};

export const groupRoles = {
  // ITEM MENU MODULO
  ITEM_MENU_MODULO: [securityRoles.MODULO_PESQUISAR],

  // ITEM MENU USUARIO
  ITEM_MENU_USUARIO: [securityRoles.USUARIO_PESQUISAR]
};

