import { Perfil } from './perfil';
import { Modulo } from './modulo'

export class Usuario {
  constructor() {
    this.idUsuario = null;
    this.cpf = null;
    this.nome = null;
    this.email = null;
    this.emailSecundario = null;
    this.senha = null;
    this.interno = null;
    this.status = null;
    this.temporario = null;
    this.dataUltAltSenha = null;
    this.motivoInativacao = null;
    this.token = null;
    this.refreshToken = null;
    this.temporarioDataFim = null;
    this.temporarioDataInicio = null;
    this.ultimoAcesso = null;
    this.historicoSenhas = null;
    this.perfis = null;
    this.nomeImportado = null;
    this.masp = null;
    this.telefone = null;
    this.idItemOrganograma = null;
    this.usuarioSiam = null;
    this.novaSenha = null;
    this.senhaAtual = null;
    this.loginUltAtual = null;
    this.dataHoraUltAtual = null;
    this.dataHoraCriacao = null;
    this.exibeListaEmail= false;
    this.viaEmailSecundario= false;
    this.viaEmailSecundario= null;
    this.emailEnviado= null;
    this.modulos= null;
    this.listaSetorSecundarioSelecionado= [];
    this.listOrgranogramaSecundarioOld= [];
    this.senhaGovBr = null;
  }

  public idUsuario: number;
  public cpf: string;
  public nome: any;
  public email: string;
  public emailSecundario: string;
  public emailEnviado: string;
  public senha: string;
  public interno: boolean;
  public status: string;
  public temporario: boolean;
  public dataUltAltSenha: Date;
  public motivoInativacao: string;
  public token: string;
  public refreshToken: string;
  public temporarioDataFim: Date;
  public temporarioDataInicio: Date;
  public ultimoAcesso: Date;
  public historicoSenhas: {};
  public perfis: Perfil[];
  public modulos: Modulo[];
  public listaSetorSecundarioSelecionado: number[];
  public nomeImportado: boolean;
  public exibeListaEmail: boolean;
  public viaEmailSecundario: boolean;
  public masp: string;
  public telefone: string;
  public idItemOrganograma: number;
  public usuarioSiam: string;
  public novaSenha: string;
  public senhaAtual: string;
  public loginUltAtual: string;
  public dataHoraUltAtual: Date;
  public dataHoraCriacao: Date;
  public listOrgranogramaSecundarioOld:{};
  public senhaGovBr: string;
}
