import { AlertService } from './../alert/alert.service';
import { MaskConstants } from './../../util/mask-constants';
import { UsuarioExternoService } from './../../services/manter-usuario/usuario-externo.service';
import { FormatadorDeMensagens } from './../../util/mensagens-padrao';

import { Usuario } from './../../models/usuario';
import { AuthService } from './../../services/auth-service';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-modal-alterar-usuario',
  templateUrl: './modal-alterar-usuario.component.html',
  styleUrl: './modal-alterar-usuario.component.css'
})
export class ModalAlterarUsuarioComponent implements OnInit{

  public formUsuario: FormGroup;
  public usuario = new Usuario();
  @Output() chamadaModal = new EventEmitter();
  @Input() valorPai: string;
  public desativarCpf = false;

  @ViewChild('modal', {static: false}) modal: TemplateRef<any>;
  @ViewChild('vc', {read: ViewContainerRef, static: false}) vc: ViewContainerRef;
  backdrop: any;

  constructor(private usuarioExternoService: UsuarioExternoService, private authService: AuthService,
    private formBuilder: FormBuilder, private alertService: AlertService,
    public maskConstants: MaskConstants,) {
  }


  showDialog() {
    this.usuarioExternoService.buscarUsuarioLogado().subscribe(retorno => {
      this.usuario = retorno;
      console.log(retorno);
      this.formUsuario = this.formBuilder.group({
        cpf: [retorno.cpf, [Validators.required]],  // Não coloque 'disabled' aqui
        nome: [retorno.nome, [Validators.required]],
        emailPrincipal: [retorno.email, [Validators.required]],
        emailSecundario: [retorno.emailSecundario]
      });

      // Insere o template no container
      const view = this.modal.createEmbeddedView(null);
      this.vc.insert(view);

      // Obtém o modal pelo ID diretamente após a inserção no DOM
      const modalElement = document.getElementById('usuarios');  // Assume-se que o ID do modal é 'usuarios'

      // Mover o modal para o body
      document.body.appendChild(modalElement);

      // Modificar estilos para exibir o modal corretamente
      modalElement.classList.remove('fade');  // Remove a animação fade
      modalElement.classList.add('modal-open');  // Adiciona a classe modal-open para exibir corretamente
      modalElement.classList.add('show');  // Adiciona a classe show para exibição
      modalElement.style.display = 'block';  // Garante que o modal apareça

      // Criar o backdrop manualmente
      this.backdrop = document.createElement('DIV');
      this.backdrop.className = 'modal-backdrop show';  // Classe necessária para o backdrop
      document.body.appendChild(this.backdrop);
    });
  }


  ngOnInit() {

  }
  fechar(valor) {
    this.chamadaModal.emit(valor);
  }

  gravar(){
    this.alertService.clear();
    this.usuario.email = this.formUsuario.get('emailPrincipal').value;
    this.usuario.nome = this.formUsuario.get('nome').value;
    this.usuario.emailSecundario = this.formUsuario.get('emailSecundario').value;
    this.usuarioExternoService.alterarUsuario(this.usuario);
  if (!this.formUsuario ) {
    console.error('O formulário não está inicializado ou não é válido.');
    return;
  }

  }

  closeDialog() {
    // Limpa o conteúdo dinâmico do ViewContainerRef
    this.vc.clear();

    // Verifica se o backdrop existe antes de tentar removê-lo
    if (this.backdrop && document.body.contains(this.backdrop)) {
      document.body.removeChild(this.backdrop);
    }

    // Obtém o modal pelo ID para garantir que ele está anexado ao body
    const modalElement = document.getElementById('usuarios');

    // Verifica se o modal existe no body antes de removê-lo
    if (modalElement && document.body.contains(modalElement)) {
      document.body.removeChild(modalElement);
    }

    // Reseta o formulário
    this.formUsuario.reset();
  }


  verificaValidTouched(campo: string) {
    return !this.formUsuario.get(campo).valid &&
    (this.formUsuario.get(campo).touched ||
    this.formUsuario.get(campo).dirty);
  }

  // Verifica se o campo já foi clicado pelo usuário, caso Sim, exibie mensagem de validação para Required
  verificaRequired(campo: string) {
    return (
      this.formUsuario.get(campo).hasError('required') &&
      (this.formUsuario.get(campo).touched ||
      this.formUsuario.get(campo).dirty));
  }

  // Aplicar a classe de erro para CSS - has-error/has-feedback
  aplicaCssErro(campo: string) {
    return {
      'is-invalid': this.verificaValidTouched(campo),
      'has-feedback': this.verificaValidTouched(campo)
    };
  }

}
