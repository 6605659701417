
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AlertService } from '../../componentes/alert/alert.service';
import { FiltroUsuarioVO } from '../../models/vo/filtro-usuario-vo';
import { AbstractService } from '../abstract.service';
import { RestService } from '../rest-service';
import { TipoRequisicaoRestEnum } from './../../models/tipo-requisicao-rest.enum';
import { Usuario } from './../../models/usuario';
import { mensagensPadrao } from './../../util/mensagens-padrao';

@Injectable()
export class TrocarSenhaService extends AbstractService {
  path: string = '/trocarSenha';

  usuario: Usuario;

  constructor(
    private rest: RestService,
    private alertService: AlertService,
    public router: Router
  ) {
    super();
    this.usuario = new Usuario();
  }

  /**
   * Realiza a consulta do usuário logado.
   */
  public buscarUsuarioLogado(): Observable<any> {
    let params = new HttpParams();
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      this.path + '/buscarUsuarioLogado',
      params
    );
  }

  /**
   * Realiza a chamada ao serviço de alteração de senha do usuário na API do Portal de Segurança.
   *
   * @param usuario
   */
  public trocarSenha(usuario: Usuario) {
    this.rest
      .gerarSolicitacao(TipoRequisicaoRestEnum.PUT, this.path, null, usuario)
      .subscribe(data => {
        if (data != null) {
          this.alertService.success(mensagensPadrao['MSG03']);
        }
      });
  }
}
