import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AlertService } from '../componentes/alert/alert.service';
import { UsuarioExternoService } from '../services/manter-usuario/usuario-externo.service';

@Component({
  selector: 'app-validar-conta',
  templateUrl:
    '../paginas/privado/manter-usuario/confirmar-cadastro/validar-conta.component.html',
  styleUrls: [
    '../paginas/privado/manter-usuario/css/manter-usuario.component.css'
  ]
})
export class ValidarContaComponent implements OnInit {
  inscricao: Subscription;
  id: number;
  hash: string;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private usuarioService: UsuarioExternoService,
    private alertService: AlertService
  ) {}

  ngOnInit() {}

  validarConta() {
    this.alertService.clear();
    this.inscricao = this.route.params.subscribe((params: any) => {
      this.id = params['id'];
      this.hash = params['hash'];
    });

    this.usuarioService.validarConta(this.id, this.hash);
  }
}
