import { Modulo } from './../../models/modulo';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AlertService } from '../../componentes/alert/alert.service';
import { FiltroUsuarioVO } from '../../models/vo/filtro-usuario-vo';
import { AbstractService } from '../abstract.service';
import { RestService } from '../rest-service';
import { TipoRequisicaoRestEnum } from './../../models/tipo-requisicao-rest.enum';
import { Usuario } from './../../models/usuario';
import { mensagensPadrao } from './../../util/mensagens-padrao';

const PATH = '/usuarioInterno';

@Injectable()
export class UsuarioInternoService extends AbstractService {
  usuario: Usuario;

  constructor(
    private rest: RestService,
    private alertService: AlertService,
    public router: Router
  ) {
    super();
    this.usuario = new Usuario();
  }

  /**
   * Realiza a chamada ao serviço de alteração de usuário na API do Portal de Segurança.
   *
   * @param usuario
   */
  public alterarUsuario(usuario: Usuario) {
    console.log(usuario);
    this.rest
      .gerarSolicitacao(TipoRequisicaoRestEnum.PUT, PATH, null, usuario)
      .subscribe(data => {
        console.log('passou 1');
        if (data != null) {
          console.log('passou 2');
          usuario.perfis=data.perfis;
          console.log('passou 3');
          usuario.listOrgranogramaSecundarioOld=data.listOrgranogramaSecundarioOld;
          console.log('passou 4');
          this.alertService.success(mensagensPadrao['MSG03']);
          console.log('passou 5');
        }
      });
  }


  buscarListaModulosPerfis(): Observable<any> {
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      '/perfilUsuario/buscarModulos'
    );
  }

  /**
   * Realiza uma consulta de usuário por id.
   *
   * @param id
   */
  public buscarPorId(id): Observable<any> {
    let params = new HttpParams().set('id', id);
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      PATH + '/buscarPorId',
      params
    );
  }


  /**
   * Realiza consulta de modulo pelo id do usuario.
   *
   * @param idUsuario
   */
   public modulosPorIdUsuario(idUsuario): Observable<Modulo[]> {
    let params = new HttpParams().set('id', idUsuario);
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      PATH + '/modulosPorIdUsuario',
      params
    );
  }

  /**
   * busca a Lista da Estrutura Organizacional.
   *
   */
  public buscarEstruturaOrganizacional(): Observable<any> {
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      PATH + '/buscarEstruturaOrganizacional'
    );
  }

  /**
   * Realiza uma consulta de usuário por id.
   *
   * @param id
   */
  public buscarUsuarioPeloCpf(cpf): Observable<Usuario> {
    let params = new HttpParams().set('cpf', cpf);
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      PATH + '/buscarPorCpf',
      params
    );
  }
  /**
   * Realiza uma consulta de usuário por cpf.
   *carrega apenas perfils que tenham o mesmo módulo do usuário
   * @param id
   */
  public buscarPorCpfFiltraPerfilModulo(cpf): Observable<any> {
    let params = new HttpParams().set('cpf', cpf);
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      PATH + '/buscarPorCpfFiltraModuloUsuarioLogado',
      params
    );
  }

  /**
   * Realiza uma consulta dos usuários através dos filtros passados como parâmetro.
   *
   * @param filtro
   */
  public buscarListaPorFiltro(filtro: FiltroUsuarioVO): Observable<any> {
    if (filtro.page != 0) {
      filtro.page = filtro.page - 1;
    }
    let params = new HttpParams()
      .set('idUsuario', filtro.idUsuario)
      .set('cpf', filtro.cpf)
      .set('nome', filtro.nome)
      .set('email', filtro.email)
      .set('page', filtro.page)
      .set('size', filtro.size);
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      PATH + '/filtrarUsuarios',
      params
    );
  }

  /**
   * Realiza uma contagem dos registros selecionados de acordo com os filtros passados como parâmetro.
   *
   * @param filtro
   */
  public countTotalUsuario(filtro: FiltroUsuarioVO): Observable<any> {
    let params = new HttpParams()
      .set('idUsuario', filtro.idUsuario)
      .set('cpf', filtro.cpf)
      .set('nome', filtro.nome)
      .set('email', filtro.email);
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.GET,
      PATH + '/countUsuario',
      params
    );
  }
}
