import { AuthorizedComponent } from './../componentes/seguranca/authorized/authorized.component';

import { PaginaServicoIndisponivelComponent } from './../erros/pagina-servico-indisponivel.component';
import { TimeOutSessaoUsuarioComponent } from './../erros/pagina-timeout-sessao-usuario.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PaginaAcessoInvalidoComponent } from '../erros/pagina-acesso-invalido.component';
import { PaginaConfirmacaoCadastroComponent } from '../erros/pagina-confirmacao-cadastro.component';
import { PaginaConfirmacaoEmailComponent } from '../erros/pagina-confirmacao-email.component';
import { PaginaErroSistemaComponent } from '../erros/pagina-erro-sistema.component';
import { PaginaNaoEncontradaComponent } from '../erros/pagina-nao-encontrada.component';
import { ManterPerfilUsuarioComponent } from '../manter-perfil-usuario/manter-perfil-usuario.component';
import { ValidarContaComponent } from '../manter-usuario/validar-conta.component';
import { HomeComponent } from './../home/home.component';
import { LoginComponent } from './../login/login.component';
import { securityRoles } from './../util/security-roles';
import { AuthGuardService } from './auth-guard-service';
import { PsegIndexComponent } from '../pseg-index/pseg-index.component';
import { FaleConoscoComponent } from './../fale-conosco/fale-conosco.component';
import { PublicFaqComponent } from '../public-faq/public-faq.component';
import { AuthHomeServiceGuard } from './auth-guard-home-service';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },

  // {
  //   path: 'login',
  //   component: AuthorizedComponent
  // },

  {
    path: 'authorized',
    component: AuthorizedComponent
  },

  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuardService, AuthHomeServiceGuard]
  },
  {
    path: 'pseg-index',
    component: PsegIndexComponent,
    canActivate: [AuthGuardService, AuthHomeServiceGuard]
  },
  {
    path: 'modulo',
    loadChildren: () => import('./../manter-modulo/manter-modulo.module').then(m => m.ManterModuloModule),
    canActivate: [AuthGuardService, AuthHomeServiceGuard],
    data: { roles: [securityRoles.MODULO_PESQUISAR] }
  },
  {
    path: 'funcionalidade',
    loadChildren:
      () => import('./../manter-funcionalidade/manter-funcionalidade.module').then(m => m.ManterFuncionalidadeModule),
    canActivate: [AuthGuardService, AuthHomeServiceGuard],
    data: { roles: [securityRoles.FUNCIONALIDADE_PESQUISAR] }
  },

  {
    path: 'client',
    loadChildren:
      () => import('./../manter-client/manter-client.module').then(m => m.ManterClientModule),
    canActivate: [AuthGuardService, AuthHomeServiceGuard],
    // data: { roles: [securityRoles.FUNCIONALIDADE_PESQUISAR] }
  },

  {
    path: 'acao',
    loadChildren: () => import('./../manter-acao/manter-acao.module').then(m => m.ManterAcaoModule),
    canActivate: [AuthGuardService, AuthHomeServiceGuard],
    data: { roles: [securityRoles.ACAO_PESQUISAR] }
  },
  {
    path: 'perfil',
    loadChildren: () => import('./../manter-perfil/manter-perfil.module').then(m => m.ManterPerfilModule),
    canActivate: [AuthGuardService, AuthHomeServiceGuard],
    data: { roles: [securityRoles.PERFIL_PESQUISAR] }
  },
  {
    path: 'faq',
    loadChildren: () => import('./../manter-faq/manter-faq.module').then(m => m.ManterFaqModule),
    canActivate: [AuthGuardService, AuthHomeServiceGuard],
    data: { roles: [securityRoles.FAQ_PESQUISAR] }
  },
  {
    path: 'perfil-acao',
    loadChildren:
      () => import('./../manter-perfil-acao/manter-perfil-acao.module').then(m => m.ManterPerfilAcaoModule),
    canActivate: [AuthGuardService, AuthHomeServiceGuard],
    data: { roles: [securityRoles.PERFIL_ACAO_PESQUISAR] }
  },
  {
    path: 'perfil-usuario',
    component: ManterPerfilUsuarioComponent,
    canActivate: [AuthGuardService, AuthHomeServiceGuard],
    data: { roles: [securityRoles.USUARIO_PERFIL_PESQUISAR] }
  },
  {
    path: 'usuario',
    loadChildren: () => import('./../manter-usuario/manter-usuario.module').then(m => m.ManterUsuarioModule)
  },
  {
    path: 'trocar-senha',
    loadChildren: () => import('./../trocar-senha/trocar-senha.module').then(m => m.TrocarSenhaModule)
  },
  {
    path: 'esqueci-minha-senha',
    loadChildren:
      () => import('./../esqueci-minha-senha/esqueci-minha-senha.module').then(m => m.EsqueciMinhaSenhaModule)
  },
  {
    path: 'fale-conosco',
    component: FaleConoscoComponent
  },
  {
    path: 'public-faq',
    component: PublicFaqComponent
  },
  {
    path: 'validar-conta/:id/:hash',
    component: ValidarContaComponent
  },
  {
    path: 'confirmacao',
    component: PaginaConfirmacaoCadastroComponent
  },
  {
    path: 'confirmacao-email',
    component: PaginaConfirmacaoEmailComponent
  },
  {
    path: 'acesso-invalido',
    component: PaginaAcessoInvalidoComponent
  },
  {
    path: 'erro-sistema',
    component: PaginaErroSistemaComponent
  },
  {
    path: 'timeout-usuario',
    component: TimeOutSessaoUsuarioComponent
  },
  {
    path: 'servico-indisponivel',
    component: PaginaServicoIndisponivelComponent
  },
  {
    path: '**',
    component: PaginaNaoEncontradaComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
