import { Component, OnInit } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Modulo } from '../models/modulo';
import { Usuario } from '../models/usuario';
import { AlertService } from './../componentes/alert/alert.service';
import { ModalService } from './../componentes/modal/modal.service';
import { PerfilUsuarioService } from './../services/manter-perfil-usuario/perfil-usuario.service';
import { MaskConstants } from './../util/mask-constants';
import {
  FormatadorDeMensagens,
  mensagensCdu,
  mensagensPadrao
} from './../util/mensagens-padrao';
import { securityRoles } from './../util/security-roles';
import { AuthService } from '../services/auth-service';

@Component({
  selector: 'app-manter-perfil-usuario',
  templateUrl:
    '../paginas/privado/manter-perfil-usuario/vincular/vincular-perfil-usuario.component.html',
  styleUrls: [
    '../paginas/privado/manter-perfil-usuario/css/manter-perfil-usuario.component.css'
  ]
})
export class ManterPerfilUsuarioComponent implements OnInit, OnDestroy {
  public roles = securityRoles;
  private selectedModulo: Modulo;
  public modulos: Modulo[];
  public usuario: Usuario;
  public usuarioClone: Usuario;
  public dropdownList = [];
  public selectedItems = [];
  public dropdownSettings = {};

  public tituloModal: String;
  public msgDialog: String;

  private subscription: Subscription;

  public txtTitulo: string = 'Clonar perfis';

  accordionState: boolean[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private service: PerfilUsuarioService,
    private alertService: AlertService,
    public router: Router,
    private modalService: ModalService,
    public formatador: FormatadorDeMensagens,
    public maskConstants: MaskConstants,
    private authService: AuthService,
  ) {
    this.modulos = [];
    this.usuario = new Usuario();
    this.usuarioClone = new Usuario();
  }

    // Alterna o estado do item do accordion
    toggleAccordion(index: number): void {
      this.accordionState[index] = !this.accordionState[index];
    }

  public ngOnInit(): void {
     // Inicializa o estado do accordion com falso para todos os módulos
     this.accordionState = this.obterModulos().map(() => false);
    let id = this.activatedRoute.snapshot.queryParams.id;
    this.service
      .buscarUsuarioPerfis(id)
      .subscribe(
        usuario => (
          (this.usuario = usuario),
          this.service
            .buscarListaModulosPerfis()
            .subscribe(modulos => this.extractModulosPerfis(modulos))
        )
      );
      this.service
      .buscarListaModulosPerfis()
      .subscribe(data => {
        this.dropdownList=this.incluirLabelKeyModulo(data);
      });

}
  extractModulosPerfis(data) {
    this.modulos = <Modulo[]>data;
    this.marcarCheckboxDoUsuario();
  }

  usuarioVisualizaModulo(modulo:Modulo ):boolean{
    return  this.service.usuarioVisualizaModulo(modulo);
  }

  obterModulos(): Modulo[] {
    this.service.checkTodos(this.modulos);
    if (this.selectedModulo == null) {
      return this.modulos;
    } else {
      return this.modulos.filter(modulo => modulo == this.selectedModulo);
    }
  }

  filtrarModulo(value) {
    this.alertService.clear();
    let id: Number = Number.parseInt((<HTMLSelectElement>value).value);
    if (id != 0) {
      this.modulos.forEach(modulo => {
        if (modulo.idModulo == id) {
          this.selectedModulo = modulo;
        }
      });
    } else {
      this.selectedModulo = null;
    }
  }

  stopPropagation(evento: MouseEvent) {
    return evento.stopPropagation();
  }

  checkPerfisPorModulo(modulo: Modulo, checked: Boolean) {
    if (modulo.tbPerfis != null) {
      modulo.tbPerfis.forEach(perfil => {
        perfil.isCheck = checked;
      });
    }
  }


  incluirLabelKeyModulo(modulo){
    modulo.forEach(item => {
      item.labelKey=item.sigla+" - "+item.nome
    });
    return modulo;
  }

  gravarUsuarioPerfil() {
    this.alertService.clear();
    this.usuario.perfis = [];
    this.modulos.forEach(modulo => {
      modulo.tbPerfis.forEach(perfil => {
        if (perfil.isCheck) {
          let mod = new Modulo();
          mod.idModulo = modulo.idModulo;
          perfil.tbModulo = mod;
          this.usuario.perfis.push(perfil);
        }
      });
    });
    this.service.gravarUsuarioPerfil(this.usuario);
  }

  voltar() {
    this.router.navigate(['/usuario/listar'], {
      queryParamsHandling: 'preserve'
    });
  }

  ngOnDestroy(): void {
    this.modalService.fecharDialogModal();
  }

  openModal() {
    this.modalService.openModal();
  }

  openDialogModal(form: FormGroup) {
    this.subscription = this.modalService.bsModalService.onHidden.subscribe(
      (reason: string) => {
        if (reason != null && reason == 'sucess') {
          this.clonarPerfis();
          this.modalService.scrollToTop();
          form.reset();
        }
        this.subscription.unsubscribe();
      }
    );
    this.msgDialog =
      'Tem certeza que deseja clonar TODOS os perfis do usuário: ' +
      this.usuarioClone.nome +
      ' para o usuário: ' +
      this.usuario.nome +
      '?';
    this.modalService.openDialogModal('modal-warning', 'modal-md');
  }

  public cancelar() {
    this.alertService.clear();
    this.modalService.fecharModal();
  }

  public buscarUsuarioClone() {
    this.usuarioClone.nome = '';

    if (this.usuarioClone.cpf != null) {
      this.usuarioClone.cpf = this.usuarioClone.cpf.replace(/[^\d]+/g, '');

      if (this.usuario.cpf == this.usuarioClone.cpf) {
        this.alertService.clear();
        this.alertService.errorModal(mensagensCdu.PSEG_CDU_11_M01);
      } else {
        if (this.usuarioClone != null && this.usuarioClone.cpf.length > 0) {
          this.alertService.clear();
          this.service
            .buscarPorCpfFiltraPerfilModulo(this.usuarioClone.cpf)
            .subscribe(data => {
              let user: Usuario = JSON.parse(JSON.stringify(data));
              this.usuarioClone = user;
            });
        }
      }
    }
  }

  public onEnter(event: any) {
    if (event.key == 'Enter') {
      this.buscarUsuarioClone();
    }
  }

  private limparCheckbox() {
    if (this.modulos != undefined) {
      this.modulos.forEach(modulo => {
        if (modulo.tbPerfis != undefined) {
          modulo.tbPerfis.forEach(perfil => {
            perfil.isCheck = false;
          });
        }
      });
    }
  }

  private marcarCheckboxDoUsuario() {
    if (this.modulos != undefined) {
      this.modulos.forEach(modulo => {
        if (modulo.tbPerfis != undefined) {
          modulo.tbPerfis.forEach(perfil => {
            if (this.usuario.perfis != undefined) {
              this.usuario.perfis.forEach(usuPerfil => {
                if (usuPerfil.idPerfil == perfil.idPerfil) {
                  perfil.isCheck = true;
                }
              });
            }
          });
        }
      });
    }
  }

  private clonarPerfis() {
    this.service
      .clonarUsuarioPerfil(this.usuario.idUsuario, this.usuarioClone.idUsuario)
      .subscribe(data => {
        if (data != null) {
          this.usuario = data;
          this.alertService.clear();
          this.modalService.fecharModal();
          this.limparCheckbox();
          this.marcarCheckboxDoUsuario();
          this.alertService.success(mensagensPadrao['MSG03']);
        }
      });
  }
}
