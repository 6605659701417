import { Component, OnInit } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { StatusAcaoEnum } from '../models/status-acao.enum';
import { Modulo } from '../models/modulo'
import { FiltroFaqVO } from '../models/vo/filtro-faq-vo';
import { FaqService } from '../services/manter-faq/faq.service';
import { ModuloService } from '../services/manter-modulo/modulo.service';
import { HomeService } from '../services/home/home.service';
import { Faq } from '../models/faq';
import { mensagensPadrao } from '../util/mensagens-padrao';
import { ViewChild, ElementRef } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
// import { SanitizeHtmlPipe } from './SanitizeHtmlPipe';
// import { SanitizeHtmlPipe } from './sanitize-html.pipe';

@Component({
  selector: 'app-public-faq',
  templateUrl:
    '../paginas/privado/public-faq/listar/public-faq.component.html',
  styleUrls: ['../paginas/privado/public-faq/css/public-faq.css']
})
export class PublicFaqComponent implements OnInit {

  @ViewChild('dataContainer') dataContainer: ElementRef;

  loadData(data) {
      this.dataContainer.nativeElement.innerHTML = data;
  }
    public totalRegistro: Number;

  public faqs: Faq[];
  public selectedFaq: Faq;
  public listaModulos: Modulo[];

  public filtroFaqVO: FiltroFaqVO;
  public modulo : Modulo;

  public mostraPaginador: Boolean;

  constructor(
    private sr: DomSanitizer,
    private faqService: FaqService,
    private homeService: HomeService,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    public router: Router,
  ) {
    this.filtroFaqVO = new FiltroFaqVO();
    this.filtroFaqVO.size = 1000;
  }

  ngOnInit() {
    this.homeService.buscarModulosAtivosFAQ().subscribe(listaModulos => {
      this.listaModulos= listaModulos;
    });
    this.buscarListaPorFiltro();
  }

  public gethtmlProperty(item) : SafeHtml {

    return this.sr.bypassSecurityTrustHtml(item);
  }

  public voltar(){
    this.router.navigate(['/']);
  }
  onChangeModulo(modulo: any) {
    console.log("tste");
    if (modulo!=null && modulo!=undefined&&modulo.idModulo != undefined) {
      this.filtroFaqVO.idModulo=modulo.idModulo
        this.buscarListaPorFiltro();
    } else {
      modulo= new Modulo();
      this.modulo= new Modulo();
      this.filtroFaqVO.idModulo=0
      this.buscarListaPorFiltro();
    }
  }
  onChangeFaq(faq: any) {
    if (faq!=null && faq.idFaq != undefined) {
      this.filtroFaqVO.idFaq=faq.idFaq
      this.filtroFaqVO.idModulo=this.modulo.idModulo==null?0:this.modulo.idModulo;
        this.buscarListaPorFiltro();
    }else{
      this.filtroFaqVO.idFaq=0
        this.buscarListaPorFiltro();
    }
  }


   private buscarListaPorFiltro() {
    this.homeService
      .buscarListaFaqPorFiltro(this.filtroFaqVO)
      .subscribe((facs: Faq[]) => {
        if (facs.length != 0) {
          this.faqs = facs
        } else {
          this.faqs = undefined;
        }
      });
  }

  pesquisar() {
    this.filtroFaqVO.page = 0;
    this.buscarListaPorFiltro();
  }
  limpar() {
    this.filtroFaqVO= new FiltroFaqVO();
    this.filtroFaqVO.idModulo=0;
    this.filtroFaqVO.size = 1000;
    this.modulo = new Modulo();
    this.selectedFaq=null;
    this.pesquisar();
  }


}
