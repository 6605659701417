import { ErroService } from './../erro/erro.service';
import { AuthService } from './../auth-service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';


export class NotAuthenticatedError {}

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  constructor(
    public auth: AuthService,
    private erroService: ErroService
  ) { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes('/oauth2/token') && this.auth.isAccessTokenInvalido && localStorage.getItem('refreshToken') !== null) {
      return from(this.auth.gerarAccessTokenComRefresToken())
        .pipe(
          mergeMap(() => {
            if (this.auth.isAccessTokenInvalido()) {
              throw new NotAuthenticatedError();
            }
            req = req.clone({
              setHeaders: {
                Authorization: `Bearer ${localStorage.getItem('Authorization')}`
              }
            });

            return next.handle(req);
          })
        );
    }

    return next.handle(req);
  }
}
