<div class="form-group col-md-12">
  <div class="row">
    <div class="accordion" id="accordionUnidadePrincipal">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingUnidadePrincipal">
          <button class="accordion-button" type="button" data-bs-toggle="collapse"
            data-bs-target="#collapseUnidadePrincipal" aria-expanded="true" aria-controls="collapseUnidadePrincipal">
            Unidade Principal: {{ setorExtenso || 'Não selecionado' }}
          </button>
        </h2>
        <div id="collapseUnidadePrincipal" class="accordion-collapse collapse show"
          aria-labelledby="headingUnidadePrincipal" data-bs-parent="#accordionUnidadePrincipal">
          <div class="accordion-body">
            <ul class="list-group">
              <ng-container *ngFor="let node of nodes">
                <li class="list-group-item">
                  <span *ngIf="node.children?.length > 0" (click)="toggleExpand(node)" style="cursor: pointer;"
                    class="me-4 toggleExpand">
                    <i [ngClass]="{'bi bi-arrow-right': !node.isExpanded, 'bi bi-arrow-down': node.isExpanded}"></i>
                  </span>
                  <span [ngClass]="{'selected-node': isSelected(node)}" (click)="selectNode(node)"
                    style="cursor: pointer;">{{ node.descricao
                    }}</span>
                  <ul *ngIf="node.children?.length > 0" [@expandCollapse]="node.isExpanded ? 'expanded' : 'collapsed'"
                    class="list-group ms-3">
                    <ng-container *ngFor="let child of node.children">
                      <li class="list-group-item">
                        <span *ngIf="child.children?.length > 0" (click)="toggleExpand(child)" style="cursor: pointer;">
                          <i
                            [ngClass]="{'bi bi-arrow-right': !child.isExpanded, 'bi bi-arrow-down': child.isExpanded}"></i>
                        </span>
                        <span [ngClass]="{'selected-node': isSelected(child)}" (click)="selectNode(child)"
                          style="cursor: pointer;">{{
                          child.descricao }}</span>
                        <ul *ngIf="child.children?.length > 0"
                          [@expandCollapse]="child.isExpanded ? 'expanded' : 'collapsed'" class="list-group ms-3">
                          <ng-container *ngFor="let grandchild of child.children">
                            <li class="list-group-item" (click)="selectNode(grandchild); $event.stopPropagation()">
                              <span *ngIf="grandchild.children?.length > 0" (click)="toggleExpand(grandchild)"
                                style="cursor: pointer;">
                                <i
                                  [ngClass]="{'bi bi-arrow-right': !grandchild.isExpanded, 'bi bi-arrow-down': grandchild.isExpanded}"></i>
                              </span>
                              <span [ngClass]="{'selected-node': isSelected(grandchild)}"
                                (click)="selectNode(grandchild); $event.stopPropagation()" style="cursor: pointer;">{{ grandchild.descricao
                                }}</span>
                              <ul *ngIf="grandchild.children?.length > 0"
                                [@expandCollapse]="grandchild.isExpanded ? 'expanded' : 'collapsed'"
                                class="list-group ms-3">
                                <ng-container *ngFor="let greatGrandchild of grandchild.children">
                                  <li class="list-group-item">
                                    <span *ngIf="greatGrandchild.children?.length > 0"
                                      (click)="toggleExpand(greatGrandchild)" style="cursor: pointer;">
                                      <i
                                        [ngClass]="{'bi bi-arrow-right': !greatGrandchild.isExpanded, 'bi bi-arrow-down': greatGrandchild.isExpanded}"></i>
                                    </span>
                                    <span [ngClass]="{'selected-node': isSelected(greatGrandchild)}"
                                      (click)="selectNode(greatGrandchild); $event.stopPropagation()" style="cursor: pointer;">
                                      {{ greatGrandchild.descricao }}
                                    </span>
                                    <ul *ngIf="greatGrandchild.children?.length > 0"
                                      [@expandCollapse]="greatGrandchild.isExpanded ? 'expanded' : 'collapsed'"
                                      class="list-group ms-3">
                                      <ng-container *ngFor="let greatGreatGrandchild of greatGrandchild.children">
                                        <li class="list-group-item">
                                          <span *ngIf="greatGreatGrandchild.children?.length > 0"
                                            (click)="toggleExpand(greatGreatGrandchild)" style="cursor: pointer;">
                                            <i
                                              [ngClass]="{'bi bi-arrow-right': !greatGreatGrandchild.isExpanded, 'bi bi-arrow-down': greatGreatGrandchild.isExpanded}"></i>
                                          </span>
                                          <span [ngClass]="{'selected-node': isSelected(greatGreatGrandchild)}"
                                            (click)="selectNode(greatGreatGrandchild); $event.stopPropagation()" style="cursor: pointer;">
                                            {{ greatGreatGrandchild.descricao }}
                                          </span>
                                          <ul *ngIf="greatGreatGrandchild.children?.length > 0"
                                            [@expandCollapse]="greatGreatGrandchild.isExpanded ? 'expanded' : 'collapsed'"
                                            class="list-group ms-3">
                                            <ng-container
                                              *ngFor="let greatGreatGreatGrandchild of greatGreatGrandchild.children">
                                              <li class="list-group-item">
                                                <span *ngIf="greatGreatGreatGrandchild.children?.length > 0"
                                                  (click)="toggleExpand(greatGreatGreatGrandchild); $event.stopPropagation()" style="cursor: pointer;">
                                                  <i [ngClass]="{'bi bi-arrow-right': !greatGreatGreatGrandchild.isExpanded, 'bi bi-arrow-down': greatGreatGreatGrandchild.isExpanded}"></i>
                                                </span>
                                                <span [ngClass]="{'selected-node': isSelected(greatGreatGreatGrandchild)}" (click)="selectNode(greatGreatGreatGrandchild); $event.stopPropagation()"
                                                  style="cursor: pointer;">
                                                  {{ greatGreatGreatGrandchild.descricao }}
                                                </span>
                                              </li>
                                            </ng-container>
                                          </ul>
                                        </li>
                                      </ng-container>
                                    </ul>
                                  </li>
                                </ng-container>
                              </ul>
                            </li>
                          </ng-container>
                        </ul>
                      </li>
                    </ng-container>
                  </ul>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
