import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';









import { Observable } from 'rxjs';
import { TipoRequisicaoRestEnum } from './../models/tipo-requisicao-rest.enum';
import { AbstractService } from './abstract.service';
import { EnvironmentService } from './environment/environment.service';

@Injectable()
export class RestService extends AbstractService {
  url: string = this.environmentService.env.PORTAL_URL;

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService
  ) {
    super();
  }

  /**
   * Gera uma solicitação de consulta de acordo com o tipo da requisição.
   *
   * @param tipoRequisicaoRestEnum
   * @param path
   * @param params
   * @param body
   */
  gerarSolicitacao(
    tipoRequisicaoRestEnum: TipoRequisicaoRestEnum,
    path: string,
    params?: HttpParams | null,
    body?: any | null
  ): Observable<any> {
    switch (tipoRequisicaoRestEnum.valueOf()) {
      case TipoRequisicaoRestEnum.GET:
        return this.get(path, params);
      case TipoRequisicaoRestEnum.POST:
        return this.post(path, body);
      case TipoRequisicaoRestEnum.PUT:
        return this.put(path, body);
      case TipoRequisicaoRestEnum.DELETE:
        return this.delete(path, body);
    }
  }

  private get(path: string, params?: HttpParams): Observable<any> {
    let dataObserver;
    let data = new Observable(observer => (dataObserver = observer));

    this.http
      .get(this.url + path, {
        headers: this.headers,
        params: params,
        reportProgress: true
      })
      .subscribe(dados => {
        if (dataObserver != null) {
          dataObserver.next(dados);
        }
      });

    return data;
  }

  private post(path: string, body?: any): Observable<any> {
    let dataObserver;
    let data = new Observable(observer => (dataObserver = observer));
    let bodyJson = JSON.stringify(body);
    this.http
      .post(this.url + path, bodyJson, { headers: this.headers })
      .subscribe(dados => {
        if (dataObserver != null) {
          dataObserver.next(dados);
        }
      });

    return data;
  }

  private put(path: string, body?: any): Observable<any> {
    let dataObserver;
    let data = new Observable(observer => (dataObserver = observer));

    let bodyJson = JSON.stringify(body);
    this.http
      .put(this.url + path, bodyJson, { headers: this.headers })
      .subscribe(dados => {
        if (dataObserver != null) {
          dataObserver.next(dados);
        }
      });

    return data;
  }

  private delete(path: string, body: any): Observable<any> {
    let dataObserver;
    let data = new Observable(observer => (dataObserver = observer));
    this.http.delete(this.url + path + '/' + body).subscribe(dados => {
      if (dataObserver != null) {
        dataObserver.next(dados);
      }
    });

    return data;
  }
}
