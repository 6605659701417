import { AuthService } from './../auth-service';
import { Injectable } from '@angular/core';
import { AbstractService } from '../abstract.service';
import { LoginService } from '../login/login-service';
import { EnvironmentService } from './../environment/environment.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Usuario } from './../../models/usuario';
import { environment } from '../../../environments/environment';
const PATH = '/autenticar-gov-br';

@Injectable()
export class LoginGovBrService extends AbstractService {

  private _ssoUri: string;
  private _clientId: string;
  readonly apiURL : string;

  constructor(private http: HttpClient,
    private environmentService: EnvironmentService,
    private authService: AuthService) {
    super();
    this.apiURL ==  environment.URL_PORTAL_SEGURANCA;
  }

  /**
   * Método responsável por chamar o serviço de back-end que retorna usuário Portal Gov.br.
   *
   * @param code
   *
   */
  public getUsuarioGovBr(code: string) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json; charset=utf-8'
    });

    this.environmentService.carregarEnvironments().then(() => {

      this._ssoUri = this.environmentService.env.PORTAL_URL;

      this.http.get(environment.URL_OAUTH+PATH +`/usuario?code=${code}`,
        {
          headers: headers
        }
      ).subscribe(
        (usuario: Usuario) => {
          localStorage.setItem("sub",usuario.cpf);
          this.authService.autenticar(this._ssoUri, usuario, true);
        },
        err => {
          console.error('Autenticação Gov.br: ', err);
        },
        () => {}
      );
    });
  }

}


