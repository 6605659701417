import { Component, Input, OnInit } from '@angular/core';
import { Usuario } from '../../models/usuario';
import { EstruturaOrganizacional } from '../../models/estrutura-organizacional';
import { UsuarioInternoService } from '../../services/manter-usuario/usuario-interno.service';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'estrutura-organizacional-checkbox',
  templateUrl: './estrutura-organizacional-checkbox.component.html',
  styleUrls: ['./estrutura-organizacional-checkbox.component.css'],
    animations: [
    trigger('expandCollapse', [
      state('collapsed', style({ height: '0px', overflow: 'hidden', opacity: 0 })),
      state('expanded', style({ height: '*', overflow: 'hidden', opacity: 1 })),
      transition('collapsed <=> expanded', [
        animate('300ms ease-in-out')
      ])
    ])
  ]
})
export class EstruturaOrganizacionalCheckboxComponent implements OnInit {
  @Input() usuarioForm: Usuario;

  nodes: EstruturaOrganizacional[] = [];
  listaEstruturaOrganizacional: EstruturaOrganizacional[] = [];
  setorExtenso: String = '';

  constructor(private usuarioService: UsuarioInternoService) {}

  ngOnInit() {
    this.carregarListas();
  }

  carregarListas() {
    this.usuarioService.buscarEstruturaOrganizacional().subscribe(listaEstruturaOrganizacional => {
      this.listaEstruturaOrganizacional = listaEstruturaOrganizacional;
      this.preencherDadosIniciais();
      this.macarSelecionadosEdicao();
      this.labelSetorExtrenso();
    });
  }

  preencherDadosIniciais() {
    this.nodes = this.listaEstruturaOrganizacional
      .filter(node => node.idEstruturaOrganizacionalPai === null);

    this.nodes.forEach(node => {
      this.addChildren(node);
      // Inicialmente, todos os nós estarão recolhidos
      node.isExpanded = false;
    });
  }

  addChildren(node: EstruturaOrganizacional) {
    node.children = this.listaEstruturaOrganizacional
      .filter(child => child.idEstruturaOrganizacionalPai === node.idEstruturaOrganizacional);

    node.children.forEach(child => {
      this.addChildren(child);
      // Inicialmente, todos os nós estarão recolhidos
      child.isExpanded = false;
    });
  }

  macarSelecionadosEdicao() {
    // Implementar lógica para marcar nós selecionados, se necessário
  }

  labelSetorExtrenso() {
    this.setorExtenso = this.usuarioForm.listaSetorSecundarioSelecionado
      .map(id => this.listaEstruturaOrganizacional.find(setor => setor.idEstruturaOrganizacional === id)?.descricao)
      .filter(desc => desc)
      .join(', ');
  }

  toggleSelection(node: EstruturaOrganizacional) {
    const index = this.usuarioForm.listaSetorSecundarioSelecionado.indexOf(node.idEstruturaOrganizacional);
    if (index === -1) {
      this.usuarioForm.listaSetorSecundarioSelecionado.push(node.idEstruturaOrganizacional);
    } else {
      this.usuarioForm.listaSetorSecundarioSelecionado.splice(index, 1);
    }
    this.labelSetorExtrenso();
  }

  toggleExpand(node: EstruturaOrganizacional) {
    node.isExpanded = !node.isExpanded;
  }
}
