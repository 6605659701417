import { Funcionalidade } from './funcionalidade';
import { OrgaoEnum } from './orgao.enum';
import { Perfil } from './perfil';
import { StatusModuloEnum } from './status-modulo.enum';

export class Modulo {
  constructor() {
    this.idModulo = null;
    this.sigla = null;
    this.nome = null;
    this.labelKey= null;
    this.descricao = null;
    this.textoAplicativo = null;
    this.link = null;
    this.orgao = null;
    this.status = null;
    this.loginUltAtual = null;
    this.dataHoraUltAtual = null;
    this.dataHoraCriacao = null;
    this.tbPerfis = null;
    this.isCheck = null;
    this.tbFuncionalidades = null;
    this.icon = null;
    this.colorIcon = null;
    this.senhaIntegracao = null;
    this.textoExplicativo = null;
    this.contexto = null;
  }

  public dataHoraCriacao: Date;

  // Telas Incluir e Alterar Modulo
  public idModulo: any;
  public sigla: String;
  public nome: String;
  public labelKey: String;
  public descricao: String;
  public link: string;
  public textoAplicativo: String;
  public orgao: OrgaoEnum;
  public status: StatusModuloEnum;
  public senhaIntegracao: String;
  public textoExplicativo: string;
  public contexto: string;

  // Tela Listar Modulo
  public loginUltAtual: String;
  public dataHoraUltAtual: Date;

  // Tela Vincular Perfil ao Usuário
  public tbPerfis: Perfil[];
  public isCheck: Boolean;

  // Tela Vincular Perfil a Ação
  public tbFuncionalidades: Funcionalidade[];

  // Tela Acessar Aplicações
  public icon;
  public colorIcon;
}
