import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Parametro } from '../../models/parametro';
import { AbstractService } from './../../services/abstract.service';
import { EnvironmentService } from './../../services/environment/environment.service';

@Injectable()
export class StrengthBarService extends AbstractService {
  static parametros = new EventEmitter<Parametro[]>();
  ROLE_CADU: string = 'ROLE_CADU_LISTA_PARAMETRO';
  path: string = this.environmentService.env.PORTAL_URL + '/usuarioExterno/listarParametros';

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService
  ) {
    super();
    this.headers = new HttpHeaders()
      .set('AUTHORITIES', this.ROLE_CADU)
      .append('AUTH_MODULO', 'STRENGTHBAR')
      .append('AUTH_MODULO_ID', '1');
  }

  public carregarParametros(parametros: string) {
    let params = new HttpParams().set('parametros', parametros);
    this.get(this.path, params).subscribe(data =>
      StrengthBarService.parametros.emit(JSON.parse(JSON.stringify(data)))
    );
  }

  private get(path: string, params?: HttpParams): Observable<any> {
    let dataObserver;
    let data = new Observable(observer => (dataObserver = observer));

    this.http
      .get(path, {
        headers: this.headers,
        params: params,
        reportProgress: true
      })
      .subscribe(dados => {
        if (dataObserver != null) {
          dataObserver.next(dados);
        }
      });

    return data;
  }
}
