<a (click)="showDialog()" role="button"
style="margin-right: 15px; text-decoration: none; font-size: 13px; font-weight: bold; color: #474747; ">
Alterar meus dados
</a>

<ng-container #vc></ng-container>
  <ng-template #modal >
    <div class="modal fade" id="usuarios" tabindex="-1"  role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog modal-lg modal-custom" role="document">
        <div class="modal-content" >
          <alert></alert>
          <div class="modal-header">
                    <div  style="margin-bottom: 20px;">
                      <div style="display: flex; align-items: center;">
                        <span style="border-left: 3px solid gray; margin-right: 5px; height: 20px; display: inline-block;"></span>
                        <span style="color: gray; margin-right: 5px; font-weight: bold;">Alterar Usuário</span>

                        <hr style="flex-grow: 1; border-width: 2px;">
                      </div>
                    </div>

                  </div>
                  <div class="modal-body" style="margin-top: -20px;" >
                      <form  [formGroup]="formUsuario" style="margin-left: 10px; background-color: #DCDDDF; border-radius: 10px 10px 10px 10px; border: 1px; border-color: #707070;">
                        <div class="box-body">
                          <div class="form-group">

                            <div class="row" style="margin-top: 30px;margin-left: 18px;margin-right: 18px;">
                              <div class="form-group col-md-4">
                                <label for="cpf">CPF</label>

                                <div class="input-group">
                                  <span class="input-group-text" id="basic-addon1">
                                      <i class="bi bi-credit-card"></i>
                                  </span>

                                  <input type="text" class="form-control" maxlength="20" placeholder="cpf" style="font-size: 14px; background-color: #828492; border-top-right-radius: 5px; border-bottom-right-radius: 5px;"
                                  mask="000.000.000-00" formControlName="cpf" [readonly]="true">

                                </div>

                              </div>


                              <div class="form-group col-md-8">
                                <label for="nome">Nome: </label>

                                <div class="input-group">
                                  <span class="input-group-text" id="basic-addon2">
                                    <i class="bi bi-person-square"></i>
                                  </span>
                                  <input type="text" class="form-control" placeholder="nome" maxlength="100" style="font-size: 14px; background-color: #828492; border-top-right-radius: 5px; border-bottom-right-radius: 5px;"
                                   formControlName="nome" name="nome" id="nome" [ngClass]="aplicaCssErro('nome')">
                                </div>



                                <div class="alert-input alert-danger" *ngIf="formUsuario.get('nome').hasError('required') && (formUsuario.get('nome').dirty || formUsuario.get('nome').touched)">{{ formatador.formataMensagem('MSG22', ['nome']) }}</div>

                              </div>

                            </div>




                            <div class="row" style="margin-top: 50px; margin-left: 18px;margin-right: 18px;">
                              <div class="form-group col-md-6">
                                <label for="cpf">E-mail *</label>

                                <div class="input-group">
                                  <span class="input-group-text" id="basic-addon2">
                                    <i class="bi bi-envelope"></i>
                                  </span>
                                  <input type="text" class="form-control" maxlength="100"  placeholder="Email" style="font-size: 14px; background-color: #E9EAEB; border-top-right-radius: 5px; border-bottom-right-radius: 5px;"
                                  formControlName="emailPrincipal" name="emailPrincipal" id="emailPrincipal" [ngClass]="aplicaCssErro('emailPrincipal')">
                                </div>
                              </div>


                              <div class="form-group col-md-6">
                                <label for="nome">E-mail secundário</label>


                                <div class="input-group">
                                  <span class="input-group-text" id="basic-addon2">
                                    <i class="bi bi-envelope"></i>
                                  </span>
                                  <input type="text" class="form-control" placeholder="Email Secundario" maxlength="100" style="font-size: 14px; background-color: #E9EAEB; border-top-right-radius: 5px; border-bottom-right-radius: 5px;"
                                  formControlName="emailSecundario" >
                                </div>

                              </div>

                            </div>



                          </div>


                        </div>

                        <div class="row" style="margin-top: 50px; margin-left: 18px;margin-right: 18px; margin-bottom: 20px;">

                          <div class="form-group col-md-4">
                          <div class="input-group">
                            <button class="btn" style="margin-left: 8px;background-color: #3F3F40; width: 80%;" (click)="closeDialog()">
                              <span style="color: white; font-weight: bold;"> <i class="bi bi-arrow-left-circle-fill"></i> Voltar</span>
                            </button>
                          </div>
                          </div>

                          <div class="form-group col-md-8">
                          <div class="input-group">
                            <button type="button" class="btn" [disabled]="(!formUsuario.valid)" style="background-color: #54585E; width: 98%;" (click)="gravar()" >
                              <span style="color: white; font-weight: bold;"> <i class="bi bi-floppy"></i> Gravar alteração</span>
                            </button>
                          </div>
                          </div>

                        </div>

      </form>


                  </div>


              </div>
          </div>
      </div>
  </ng-template>
