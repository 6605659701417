import { environment } from './../../../environments/environment';
import { TipoRequisicaoRestEnum } from './../../models/tipo-requisicao-rest.enum';
import { RestService } from './../rest-service';
import { NotificacoesResponse } from './../../models/vo/notificacoes-response-vo';


import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ErroService } from '../erro/erro.service';
const PATH = '/notificacao';


@Injectable({
  providedIn: 'root'
})
export class NotificacaoService {

  notificacoesResponse: NotificacoesResponse;

  constructor(
    private rest: RestService, private erroService: ErroService, private http: HttpClient,
  ) {
    this.notificacoesResponse = new NotificacoesResponse();
  }

  buscarTodasNotificacoes(): Observable<NotificacoesResponse> {
    return this.http.get<NotificacoesResponse>(environment.URL_PORTAL_SEGURANCA + PATH + '/buscarTodasNotificacoes')
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocorreu um erro desconhecido';

          if (error.error instanceof ErrorEvent) {
            // Erro de cliente (ex.: rede)
            errorMessage = `Erro: ${error.error.message}`;
          } else {
            // Erro de servidor
            errorMessage = `Erro ${error.status}: ${error.message}`;
          }

          // Exibir mensagem de erro ao usuário
          this.erroService.exibirMensagemErro("Ocorreu um erro ao tentar buscar as notificações do usuário. Por favor, entre em contato com o administrador do sistema.");

          // Lançar o erro para ser tratado pelo chamador, se necessário
          return throwError(() => new Error(errorMessage));
        })
      );
  }



  marcarComoLido(idNotificacao: number): Observable<NotificacoesResponse> {
    return this.rest.gerarSolicitacao(
      TipoRequisicaoRestEnum.POST, PATH+
      '/marcarComoLido/'+idNotificacao
    );
  }
}
