import { ModalMarcarVisualizadosComponent } from './home/modal-marcar-visualizados/modal-marcar-visualizados.component';
import { ModalNotificacaoComponent } from './home/modal-notificacao/modal-notificacao.component';
import { TrocarSenhaService } from './services/trocar-senha/trocar-senha.service';

import { EstruturaOrganizacionalService } from './componentes/estrutura-organizacional/estrutura-organizacional.service';
import { EstruturaOrganizacionalComponent } from './componentes/estrutura-organizacional/estrutura-organizacional.component';
import { EstruturaOrganizacionalCheckboxComponent } from './componentes/estrutura-organizacional-checkbox/estrutura-organizacional-checkbox.component'
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import {  PopoverModule } from 'ngx-bootstrap/popover';
import { ModalModule } from 'ngx-bootstrap/modal';

import { AlertComponent } from './componentes/alert/alert.component';
import { ModalComponent } from './componentes/modal/modal.component';
import { ModalService } from './componentes/modal/modal.service';
import { PaginadorComponent } from './componentes/paginador/paginador.component';
import { SegurancaItemComponent } from './componentes/seguranca-item/seguranca-item.component';
import { StrengthBarComponent } from './componentes/strength-bar/strength-bar.component';
import { TemplateMenuComponent } from './componentes/template-menu/template-menu.component';
import { TemplateSimplesComponent } from './componentes/template-simples/template-simples.component';
import { MapMaterialModule } from './util/map-material.module';
import { ModuloService } from './services/manter-modulo/modulo.service';
import { RecaptchaModule } from 'ng-recaptcha';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ModalAlterarSenhaComponent } from './manter-usuario/modal-alterar-senha/modal-alterar-senha.component';
import { ModalAlterarUsuarioComponent } from "./componentes/modal-alterar-usuario/modal-alterar-usuario.component";

const COMPONENTS = [
  ModalComponent,
  PaginadorComponent,
  StrengthBarComponent,
  TemplateMenuComponent,
  TemplateSimplesComponent,
  EstruturaOrganizacionalComponent,
  EstruturaOrganizacionalCheckboxComponent,
  SegurancaItemComponent,
  AlertComponent,
  ModalAlterarUsuarioComponent,
  ModalAlterarSenhaComponent,
  ModalNotificacaoComponent,
  ModalMarcarVisualizadosComponent
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MapMaterialModule,
    AccordionModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    RecaptchaModule
],
  declarations: [COMPONENTS],
  exports: [
    CommonModule,
    FormsModule,
    MatTreeModule,   // Importe o MatTreeModule aqui
    MatIconModule,   // Importar ícones
    MatButtonModule,  // Botões para expandir e recolher
    MapMaterialModule,
    AccordionModule,
    ModalModule,
    PopoverModule,
    RecaptchaModule,
    COMPONENTS
  ],
  providers: [StrengthBarComponent, ModalService, ModuloService, EstruturaOrganizacionalService, TrocarSenhaService]
})
export class AbstractModule {}
