import { Notificacao } from "./notificacao-vo";

export class Notificacoes {
  Lida: Notificacao[];
  Pendente: Notificacao[];

  constructor() {
    this.Lida = [];
    this.Pendente = [];
  }
}
